import {
  DataOptions,
  Device,
  DeviceInfo,
  DeviceSuggestNames,
  DevicesStatusesByFunctionSummit,
  PaginatedResponse,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import {
  DeviceEditFormAdmin,
  DeviceEditFormRoid,
} from '@/components/device/interfaces';
import { Query } from '../query';

const resources = {
  default: 'infrastructure/devices',
  dfs: 'infrastructure/device-functions-summit',
  di: 'infrastructure/device-infos',
};

interface DeviceApiInterface {
  getDevices(
    options: {
      pagination?: DataOptions | null;
      campusSlug?: string;
      roles?: string[];
      functions?: string[];
      hasZone?: boolean;
      managedByNetworkId?: number;
      _status?: string; // 'status' is reserved word
    },
    search?: string,
  ): AxiosPromise<PaginatedResponse<Device>>;
  getDevice(name: string): AxiosPromise<Device>;
  getDeviceInfo(deviceName: string): AxiosPromise<DeviceInfo>;
  searchSiteDevices(options: {
    siteSlug: string;
    roles?: string[];
    functions?: string | string[] | null;
    searchText?: string | null;
  }): AxiosPromise<PaginatedResponse<Device>>;
  getBBRouterOrSwitchOnSiteDevices(
    siteSlug: string,
  ): AxiosPromise<PaginatedResponse<Device>>;
  getDevicesStatusesByFunctionSummit(): AxiosPromise<
    PaginatedResponse<DevicesStatusesByFunctionSummit>
  >;
  createDevice(data: any): AxiosPromise<any>;
  editDevice(
    deviceName: string,
    data: DeviceEditFormAdmin | DeviceEditFormRoid,
  ): AxiosPromise<Device>;
  deleteDevice(deviceName: string, assetStatus: string | null): AxiosPromise<any>;
  changeDevicesStatus(
    devices: string[], status: string, netboxNewAssetStatus: string | null
  ): AxiosPromise<any>;
  activateDevice(deviceName: string): AxiosPromise<any>;
  activateDevices(deviceNames: string[]): AxiosPromise<any>;
  getPossibleTaskActionsForDevice(
    deviceName: string,
  ): AxiosPromise<{ actions: string[] }>;
  getSuggestedNames(campusSlug: string): AxiosPromise<DeviceSuggestNames>;
}

const deviceApi: DeviceApiInterface = {
  getDevices(
    {
      pagination = null,
      campusSlug = '',
      roles = [],
      functions = [],
      hasZone = null,
      managedByNetworkId = null,
      _status = null,
    },
    search = '',
  ) {
    const query = new Query({
      zones__campus__slug: campusSlug,
      function: functions,
      roles: roles,
      management_vlan__network__id: managedByNetworkId,
      search,
    });
    if (_status != null) {
      query.addParam('status', _status);
    }
    if (hasZone != null) {
      query.addParam('has_zone', !!hasZone);
    }
    if (pagination) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    return axios.get(`${resources.default}/${query}`);
  },

  getDevice(deviceName) {
    return axios.get(`${resources.default}/${deviceName}/`);
  },

  searchSiteDevices({
    siteSlug,
    roles = [],
    functions = null,
    searchText = null,
  }) {
    const query = new Query({
      sites__slug: siteSlug,
      name__icontains: searchText,
      function: functions,
      roles: roles,
    });
    return axios.get(`${resources.default}/${query}`);
  },

  getDeviceInfo(deviceName) {
    return axios.get(`${resources.di}/${deviceName}/`);
  },

  getBBRouterOrSwitchOnSiteDevices(siteSlug) {
    const query = new Query({
      function: ['router', 'l3_switch'],
      sites__slug: siteSlug,
      roles: 'backbone',
    });
    return axios.get(`${resources.default}/${query}`);
  },

  getDevicesStatusesByFunctionSummit() {
    return axios.get(`${resources.dfs}/`);
  },

  createDevice(data: any) {
    return axios.post(`${resources.default}/`, data);
  },

  editDevice(deviceName, data) {
    return axios.patch(`${resources.default}/${deviceName}/`, data);
  },

  changeDevicesStatus(devices, status, netboxNewAssetStatus) {
    return axios.post(`${resources.default}/change-status/`, {
      devices,
      status,
      netbox_new_asset_status: netboxNewAssetStatus,
    });
  },

  deleteDevice(deviceName, assetStatus) {
    return axios.delete(`${resources.default}/${deviceName}/`, {
      data: { asset_status: assetStatus },
    });
  },

  activateDevice(deviceName: string) {
    return axios.post(`${resources.default}/${deviceName}/activate/`);
  },

  activateDevices(deviceNames) {
    return axios.post(`${resources.default}/activate/`, {
      devices: deviceNames,
    });
  },

  getPossibleTaskActionsForDevice(deviceName: string) {
    return axios.get(
      `${resources.default}/${deviceName}/possible-task-actions/`,
    );
  },

  getSuggestedNames(campusSlug) {
    const query = new Query({
      campus_slug: campusSlug,
    });
    return axios.get(`${resources.default}/suggest-names/${query}`);
  },
};

export default deviceApi;
export { DeviceApiInterface };
