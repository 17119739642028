
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  FETCH_CIRCUITS,
  DELETE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { LOADING, CIRCUITS } from '@/store/states.type';
import { IS_ADMIN } from '@/store/getters.type';
import { CircuitExtended } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import translateWord from '@/components/shared/translations';
import _get from 'lodash.get';

const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class CampusCircuits extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @circuitsModule.State(LOADING) private loadingCircuits!: boolean;
  @circuitsModule.State(CIRCUITS) private circuits!: CircuitExtended[];
  @circuitsModule.Action(FETCH_CIRCUITS) private fetchCircuitsAction!: (
    campusSlug: string,
  ) => Promise<any>;
  @circuitsModule.Action(DELETE_CIRCUIT) private deleteCircuitAction!: (
    circuit: CircuitExtended,
  ) => Promise<any>;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private translateWord = translateWord;

  private headers = [
    { text: 'Tip', value: 'type', sortable: true },
    {
      text: 'Vozlišče',
      align: 'left',
      value: 'site',
      sortable: true,
    },
    {
      text: 'Vozliščna naprava',
      value: 'upstream',
      sortable: false,
    },
    { text: 'Kampus naprava', value: 'campus', sortable: false },
    { text: 'Status', value: 'status', sortable: true },
    { text: 'Kontrola', value: 'control', sortable: false },
  ];

  private created() {
    this.fetchCircuitsAction(this.campusSlug).catch((error) => {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavah' },
        ).toString(),
      );
    });
  }

  private openCreateCircuit() {
    this.$router.push({
      name: 'createCircuit',
      params: { campusSlug: this.campusSlug },
    });
  }

  private openEditCircuit(circuit: CircuitExtended) {
    this.$router.push({
      name: 'editCircuit',
      params: { campusSlug: this.campusSlug, circuitId: circuit.id + '' },
    });
  }

  private openViewCircuit(circuit: CircuitExtended) {
    this.$router.push({
      name: 'viewCircuit',
      params: { circuitId: circuit.id + '' },
    });
  }

  private openDeleteCircuitModal(circuit: CircuitExtended) {
    let upstreamInterfaceRepr = '[neznan upstream]';
    if (circuit?.termination_a?.interface) {
      upstreamInterfaceRepr = circuit.termination_a.interface.name;
    }
    let downstreamInterfaceRepr = '[neznan downstream]';
    if (circuit?.termination_z?.interface) {
      downstreamInterfaceRepr = circuit.termination_z.interface.name;
    }
    let warningMessage = '';
    if (circuit.document) {
      warningMessage = 'Pozor, ta povezava ima nase vezan dokument';
    }

    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `povezavo med ${upstreamInterfaceRepr} in ${downstreamInterfaceRepr}`,
            item: circuit,
          },
          warningMessage: warningMessage,
          deleteFn: this.deleteCircuit,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteCircuit(modal: any, circuit: CircuitExtended) {
    this.deleteCircuitAction(circuit)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem povezave je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }
}
