import { render, staticRenderFns } from "./SiteDetail.vue?vue&type=template&id=0f33ed36&scoped=true&"
import script from "./SiteDetail.vue?vue&type=script&lang=ts&"
export * from "./SiteDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f33ed36",
  null
  
)

export default component.exports