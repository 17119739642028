
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import {
  Network,
  NetworkSimple,
  DHCPServer,
  DNSResolver,
  DataOptions,
  Vlan,
} from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';
import { getColorForVlanType } from '@/components/shared/helpers';

import CampusUserNetworkSubnets from './CampusUserNetworkSubnets.vue';
import CampusUserNetworkZones from './CampusUserNetworkZones.vue';
import CampusUserNetworkExpansionHeader from './CampusUserNetworkExpansionHeader.vue';
import WifiNetworkList from '../network/WifiNetworkList.vue';
import DHCPServerList from '@/components/dhcp/DHCPServerList.vue';
import DNSResolverList from '@/components/dns/DNSResolverList.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    CampusUserNetworkSubnets,
    CampusUserNetworkZones,
    CampusUserNetworkExpansionHeader,
    WifiNetworkList,
    'dhcp-server-list': DHCPServerList,
    'dns-resolver-list': DNSResolverList,
  },
})
export default class CampusUserNetworks extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  private getColorForVlanType = getColorForVlanType;
  private items: Network[] = [];
  private loading = { initial: false, networks: false };
  private search = '';
  private keys = [
    {
      text: 'Ime',
      value: ['name'],
    },
    {
      text: 'Vlan',
      value: ['vlan.vid'],
      // value: ['vid'], if serverside sorting
    },
    {
      text: 'Tip',
      value: ['type'],
    },
    {
      text: 'Ustvarjeno',
      value: ['created'],
    },
  ];
  private options: DataOptions | null = {
    page: 1,
    itemsPerPage: 100,
    sortBy: ['name'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false,
  };

  private fetchingNetworks: { [key: number]: boolean } = {};
  // networkDataKeys is used to correctly update dns and dhcp component when
  // you move from campus-<item>-list to <item>-list page, otherwise it doesn't
  // re-render the component
  private networkDataKeys: { [key: number]: number } = {};
  private panel: any[] = [];

  private async fetchNetworks(
    campusSlug: string,
    options?: DataOptions | null,
    search?: string,
  ) {
    this.loading.networks = true;
    try {
      const { data } = await repositories.lan.network.getNetworks(
        new Query({ campus_slug: campusSlug }),
        options,
        search,
        ['name', 'slug'],
      );
      for (const network of data.results) {
        this.networkDataKeys[network.id!] = 0;
      }
      this.items = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'omrežju' },
        ).toString(),
      );
    } finally {
      this.loading.networks = false;
    }
  }

  private async fetchNetwork(networkId: number) {
    this.fetchingNetworks[networkId] = true;
    try {
      const { data } = await repositories.lan.network.getNetwork(networkId);
      const idx = this.items!.findIndex((network) => network.id === networkId);
      // remove old network data one and reinsert at the same position the new one
      this.items!.splice(idx, 1);
      this.items!.splice(idx, 0, data);
      this.networkDataKeys[networkId] += 1;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'omrežju' },
        ).toString(),
      );
    }
    this.fetchingNetworks[networkId] = false;
    this.emitNetworksChanged();
  }

  private removeNetwork(networkId: number) {
    this.items = this.items!.filter((el) => el.id !== networkId);
    this.emitNetworksChanged();
  }

  private created() {
    this.loading.initial = true;
    if (this.campusSlug) {
      this.fetchNetworks(this.campusSlug);
    }
    this.loading.initial = false;
  }

  private showNetworkCreateOrEditModal() {
    const promise: Promise<Network> = this.$modals.open('app-network-modal', {
      component: {
        props: {
          campusSlug: this.campusSlug,
          networks: this.items,
        },
      },
      dialog: {
        props: {
          'max-width': '1500px',
        },
      },
    }) as Promise<Network>;
    promise.then((network: Network) => {
      this.items!.push(network);
    });
  }

  private showEditGatewaysModal() {
    const promise: Promise<any> = this.$modals.open('app-edit-gateways-modal', {
      component: {
        props: {
          campusSlug: this.campusSlug,
          networks: this.items.filter((network) => {
            return !(network.vlan as Required<Vlan>)?.zones?.some((zone) => {
              return zone.lanonly;
            });
          }) as Network[],
        },
      },
      dialog: {
        props: {
          'max-width': '1500px',
        },
      },
    }) as Promise<any>;
    promise.then(() => {
      this.items = [];
      this.fetchNetworks(this.campusSlug);
      this.emitNetworksChanged();
    });
  }

  private emitNetworksChanged() {
    this.$emit('networksChanged');
  }

  private handleDHCPUpdate(modifiedDHCPServer: DHCPServer) {
    this.handleDHCPOrDNSUpdate(modifiedDHCPServer, 'dhcp_servers');
  }
  private handleDNSUpdate(modifiedDNSResolver: DNSResolver) {
    this.handleDHCPOrDNSUpdate(modifiedDNSResolver, 'dns_resolvers');
  }

  private handleDHCPOrDNSUpdate(
    modifiedElement: DHCPServer | DNSResolver,
    type: 'dhcp_servers' | 'dns_resolvers',
  ) {
    if (!['dns_resolvers', 'dhcp_servers'].includes(type)) {
      throw Error('Invalid handler type');
    }
    const elementNetworkIds =
      (modifiedElement.networks as NetworkSimple[])!.map((el) => el.id);
    // remove dhcp/dns from networks which are not related to it anymore
    const oldNetworks = this.items!.filter((network) => {
      const networkElementIds = (
        network[type] as DHCPServer[] | DNSResolver[]
      ).map((el) => el.id);
      return (
        networkElementIds.includes(modifiedElement.id) &&
        !elementNetworkIds.includes(network.id!)
      );
    });
    for (const network of oldNetworks) {
      const modifiedElementIdx: number = (
        network[type] as DHCPServer[] | DNSResolver[]
      ).findIndex((el) => el.id === modifiedElement.id);
      network[type].splice(modifiedElementIdx, 1);
      this.networkDataKeys[network.id!] += 1;
    }
    // add dhcp to networks which are now related to it
    const newNetworks = this.items!.filter((network) => {
      const networkElementIds = (
        network[type] as DHCPServer[] | DNSResolver[]
      ).map((el) => el.id);
      return (
        !networkElementIds.includes(modifiedElement.id) &&
        elementNetworkIds.includes(network.id!)
      );
    });
    for (const network of newNetworks) {
      (network[type] as DHCPServer[] | DNSResolver[]).push(modifiedElement);
      this.networkDataKeys[network.id!] += 1;
    }
  }
  private all() {
    if (this.items) {
      this.panel = [...this.items.keys()].map((k, i) => i);
    }
  }
  // Reset the panel
  private none() {
    this.panel = [];
  }
  private redirectToCampusDHCPServers() {
    this.$router.push({
      name: 'campusDhcpServers',
      params: { campusSlug: this.campusSlug },
    });
  }

  private redirectToCampusDNSResolvers() {
    this.$router.push({
      name: 'campusDnsResolvers',
      params: { campusSlug: this.campusSlug },
    });
  }
}
