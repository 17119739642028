
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import { repositories } from '@/api/ApiFactory';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import ErrorHandler from '@/components/shared/errorHandler';
import Breadcrumbs from '@/views/Breadcrumbs.vue';
import NetboxStatusChip from '@/components/device/NetboxStatusChip.vue';
import { CircuitExtended } from '@/api/interfaces';
import CircuitAddStep1 from '@/components/connectivity/circuit/CircuitAddOrEditStep1.vue';
import CircuitAddStep2 from '@/components/connectivity/circuit/CircuitAddOrEditStep2.vue';
import CircuitAddStep3 from '@/components/connectivity/circuit/CircuitAddOrEditStep3.vue';
import {
  CircuitStep1Data,
  CircuitStep2Data,
  CircuitStep3Data,
  CircuitFormData,
} from '@/components/connectivity/circuit/interfaces';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    'breadcrumbs': Breadcrumbs,
    NetboxStatusChip,
    Autocomplete,
    'circuit-add-step-1': CircuitAddStep1,
    'circuit-add-step-2': CircuitAddStep2,
    'circuit-add-step-3': CircuitAddStep3,
  },
})
export default class CircuitAddOrEdit extends Vue {
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @Prop({ default: null }) private campusSlug!: string;

  private loading = { submit: false, initial: false };
  private circuit: CircuitExtended | null = null;
  private e1 = 1;
  private formData: CircuitFormData = {
    step1: null,
    step2: null,
    step3: null,
  };

  private async created() {
    const circuitId = Number(this.$route.params.circuitId);
    if (circuitId) {
      await this.fetchCircuit(circuitId);
    }
  }

  private async fetchCircuit(id: number) {
    this.loading.initial = true;
    try {
      const { data } =
        await repositories.connectivity.circuit.getCircuitExtended(id);
      this.circuit = data;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavi' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  private getTransformedFormDataForApi() {
    const step1Data = this.formData.step1!;
    const step2Data = this.formData.step2!;
    const step3Data = this.formData.step3!;
    return {
      campus_slug: this.campusSlug,
      // step1
      type: step1Data.connectionType,
      status: step1Data.status,
      service_id: step1Data.serviceId,
      campus_interface_id: step1Data.campusInterface?.id || null,
      campus_interface_description: step1Data.campusInterfaceDescription,
      site_slug: step1Data.site?.slug || null,
      node_interface_id: step1Data.nodeInterface?.id || null,
      node_interface_description: step1Data.nodeInterfaceDescription,
      // step2
      campus_ct_front_ports: step2Data.campus.frontPorts.map((fp) => fp.id),
      campus_ct_patch_panel_repr: step2Data.campus.patchPanelRepr,
      campus_ct_speed_up: step2Data.campus.speedup || null,
      campus_ct_speed_down: step2Data.campus.speeddown || null,
      node_ct_front_ports: step2Data.node.frontPorts.map((fp) => fp.id),
      node_ct_patch_panel_repr: step2Data.node.patchPanelRepr,
      node_ct_speed_up: step2Data.node.speedup || null,
      node_ct_speed_down: step2Data.node.speeddown || null,
      // step3
      organization: step3Data.organization?.id || null,
      provider: step3Data.provider?.slug || null,
      provider_identifier: step3Data.provider_identifier,
      technology: step3Data.technology?.slug || null,
      commercial: step3Data.commercial,
      install_date: step3Data.install_date,
      termination_date: step3Data.termination_date,
      connection: {
        protocol: step3Data.connection.protocol as string,
        username: step3Data.connection.username,
        password: step3Data.connection.password,
        address: step3Data.connection.address,
        gateway: step3Data.connection.gateway,
      },
      tunnel: {
        id: step3Data.tunnel?.id || null,
        ipv4: step3Data.tunnel.ipv4,
        ipv6: step3Data.tunnel.ipv6,
      },
    };
  }

  private async submit() {
    this.loading.submit = true;
    try {
      const transformedFormData = this.getTransformedFormDataForApi();
      let resp;
      if (this.circuit) {
        resp = await repositories.connectivity.circuit.updateCircuit(
          this.circuit.id,
          transformedFormData,
        );
      } else {
        resp = await repositories.connectivity.circuit.createCircuit(
          transformedFormData,
        );
      }
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      if (this.campusSlug) {
        this.$router.push({
          name: 'viewCircuit',
          params: { campusSlug: this.campusSlug, circuitId: resp.data.id + '' },
        });
      } else {
        this.$router.push({
          name: 'view-global-circuit',
          params: { circuitId: resp.data.id + '' },
        });
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler({ error, status: true }).toString(),
        {
          icon: 'mdi-error',
        },
      );
    }
    this.loading.submit = false;
  }

  private goToPreviousStep() {
    this.e1 -= 1;
    this.$vuetify.goTo(0);
  }

  private goToStep2(data: CircuitStep1Data) {
    this.formData.step1 = data;
    this.e1 = 2;
    this.$vuetify.goTo(0);
  }
  private goToStep3(data: CircuitStep2Data) {
    this.formData.step2 = data;
    this.e1 = 3;
    this.$vuetify.goTo(0);
  }

  private goToSubmit(data: CircuitStep3Data) {
    this.formData.step3 = data;
    this.submit();
  }
}
