import { render, staticRenderFns } from "./CircuitDetail.vue?vue&type=template&id=35dffd45&scoped=true&"
import script from "./CircuitDetail.vue?vue&type=script&lang=ts&"
export * from "./CircuitDetail.vue?vue&type=script&lang=ts&"
import style0 from "./CircuitDetail.vue?vue&type=style&index=0&id=35dffd45&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35dffd45",
  null
  
)

export default component.exports