import {
  Asset,
  DataOptions,
  DeviceModule,
  PaginatedResponse,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'infrastructure/modules',
};

type ModuleForm = {
  asset: number | Asset;
  device: number;
  position: number | null;
};

type EditModuleForm = {
  id: number;
  position: number | null;
};

type ModuleAPIOptions = {
  pagination?: DataOptions | null;
  assetId?: null | number;
  deviceId?: null | number;
  deviceName?: null | string;
  assetProductName?: null | string;
  assetProductTypeName?: null | string;
  assetProductManufacturerName?: null | string;
};

interface ModuleApiInterface {
  getModules(
    options: ModuleAPIOptions,
    search?: string,
  ): AxiosPromise<PaginatedResponse<DeviceModule>>;
  getModule(id: number): AxiosPromise<DeviceModule>;
  createModule(data: ModuleForm): AxiosPromise<DeviceModule>;
  editModule(data: EditModuleForm): AxiosPromise<DeviceModule>;
  deleteModule(id: number, assetStatus: string | null): AxiosPromise<any>;
}

class ModuleApi implements ModuleApiInterface {
  public getModules(
    {
      pagination = null,
      deviceId = null,
      assetId = null,
      deviceName = null,
      assetProductName = null,
      assetProductTypeName = null,
      assetProductManufacturerName = null,
    }: ModuleAPIOptions,
    search = '',
  ) {
    const query = new Query({
      device: deviceId,
      asset: assetId,
      device__name: deviceName,
      asset__product__name: assetProductName,
      asset__product__type__name: assetProductTypeName,
      asset__product__manufacturer__name: assetProductManufacturerName,
      search,
    });
    if (pagination != null) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    return axios.get(`${resources.default}/${query}`);
  }

  getModule(id: number) {
    return axios.get(`${resources.default}/${id}/`);
  }

  createModule(data: ModuleForm) {
    return axios.post(`${resources.default}/`, data);
  }

  editModule(data: EditModuleForm) {
    return axios.patch(`${resources.default}/${data.id}/`, data);
  }

  deleteModule(id: number, assetStatus: string | null) {
    return axios.delete(`${resources.default}/${id}/`, {
      data: { asset_status: assetStatus },
    });
  }
}

export default ModuleApi;
export { ModuleApiInterface, ModuleForm, EditModuleForm };
