
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Product, DeviceModule } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import translateWord from '@/components/shared/translations';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class EmbededModules extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private repr = new Representations();
  @Prop() private modules!: DeviceModule[];

  private translateWord = translateWord;

  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Tip', value: 'asset.product.type.name' },
    { text: 'Reža', value: 'position' },
    { text: 'Ime', value: 'asset.product.name' },
    {
      text: 'Proizvajalec',
      align: 'left',
      value: 'asset.product.manufacturer.name',
    },
    { text: 'Oznaka proizvajalca', value: 'asset.product.part_number' },
    { text: 'Serijska številka', value: 'asset.serial_number' },
    { text: 'Opis', value: 'asset.product.description', sortable: false },
  ];
  private search = '';

  private created() {
    if (this.isAdmin) {
      this.headers.push({ text: 'Akcije', value: 'actions', sortable: false });
    }
  }

  private products: Product[] = [];
  private totalProducts = 0;

  private get items() {
    return this.modules;
  }

  private routeToProduct(item: Product) {
    this.$router.push({
      name: 'productDetail',
      params: { product: String(item.id) },
    });
  }
  private routeToProductAdd() {
    this.$router.push({ name: 'productAdd' });
  }

  private showModuleDeleteModal(module: DeviceModule): void {
    this.$modals.open('app-confirm-module-delete-modal', {
      component: {
        props: {
          data: {
            repr: `${module.asset.product?.name}`,
            item: module,
          },
          deleteFn: this.deleteModule,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteModule(
    modal: any,
    data: { item: DeviceModule; form: { assetStatus: string | null } },
  ): void {
    const module = data.item;
    repositories.infrastructure.deviceModule
      .deleteModule(module.id, data.form.assetStatus)
      .then((resp) => {
        // fetch tasks state so that it immediatelly updates the badges
        // this.fetchTasksStateAction();
        this.$modals.close();
        this.$emit('moduleDeleted', module);
      })
      .catch((error) => {
        modal.deleting = false;
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      });
  }
}
