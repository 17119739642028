import { render, staticRenderFns } from "./CampusMoveStuff.vue?vue&type=template&id=7d7d65d8&scoped=true&"
import script from "./CampusMoveStuff.vue?vue&type=script&lang=ts&"
export * from "./CampusMoveStuff.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d7d65d8",
  null
  
)

export default component.exports