
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Contact as ContactInterface } from '@/api/interfaces';
import translateWord from '@/components/shared/translations';

@Component
export default class Contact extends Vue {
  @Prop() private contact!: ContactInterface;

  private translateWord = translateWord;
  private iconData: { [key: string]: { icon: string; tooltip: string } } = {
    phone: { icon: 'mdi-deskphone', tooltip: 'Stacionarni telefon' },
    mobi: { icon: 'mdi-cellphone-basic', tooltip: 'Mobilni telefon' },
    gsm: { icon: 'mdi-cellphone-basic', tooltip: 'Mobilni telefon' },
    fax: { icon: 'mdi-fax', tooltip: 'Fax' },
    email: { icon: 'mdi-at', tooltip: 'E-pošta' },
    skype: { icon: 'mdi-skype', tooltip: 'Skype' },
    url: { icon: 'mdi-link', tooltip: 'URL povezava' },
  };

  private getIcon(_type: string) {
    if (!Object.keys(this.iconData).includes(_type)) {
      return 'mdi-card-account-details-outline';
    }
    return this.iconData[_type].icon;
  }

  private getIconTooltip(_type: string) {
    if (!Object.keys(this.iconData).includes(_type)) {
      return 'Drugo';
    }
    return this.iconData[_type].tooltip;
  }
}
