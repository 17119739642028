import { Location, PaginatedResponse } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import L from 'leaflet';
import { Query } from '../query';

const resources = {
  default: 'tenants/locations',
  lws: 'tenants/locations-with-rooms',
};

interface LocationApiInterface {
  getLocation(id: string | number): AxiosPromise<Location>;
  getLocations(): AxiosPromise<PaginatedResponse<Location>>;
  getCampusLocations(
    campusSlug: string,
  ): AxiosPromise<PaginatedResponse<Location>>;
  getLocationsWithRooms({
    campusSlug,
    siteSlug,
  }: {
    campusSlug?: string[] | string | null;
    siteSlug?: string[] | string | null;
  }): AxiosPromise<PaginatedResponse<Location>>;
  getLocationWithRooms(id: string | number): AxiosPromise<Location>;
  searchLocations(
    search: string,
    typeName: string | null,
  ): AxiosPromise<PaginatedResponse<Location>>;
  createLocation(
    _type: string,
    name: string,
    parent?: number | null,
    mid?: number | null,
    campusSlug?: string | null,
    coordinates?: L.LatLng | null,
  ): AxiosPromise<Location>;
  editRoom(id: string | number, name: string): AxiosPromise<Location>;
  editBuilding(
    id: string | number,
    name: string,
    mid: number | null,
    coordinates: L.LatLng | null,
  ): AxiosPromise<Location>;
  deleteLocation(id: number): AxiosPromise<any>;
}

const LocationApi: LocationApiInterface = {
  getLocation(id) {
    return axios.get(`${resources.default}/${id}/`);
  },

  getLocations() {
    return axios.get(`${resources.default}/`);
  },

  getCampusLocations(campusSlug) {
    const query = new Query({ campuses__slug: campusSlug });
    return axios.get(`${resources.default}/${query}`);
  },

  getLocationsWithRooms({ campusSlug, siteSlug }) {
    const query = new Query({
      campuses__slug: campusSlug,
      sites__slug: siteSlug,
    });
    return axios.get(`${resources.lws}/${query}`);
  },

  getLocationWithRooms(id) {
    return axios.get(`${resources.lws}/${id}/`);
  },

  searchLocations(search, typeName = null) {
    const query = new Query({ search, type__name: typeName });
    return axios.get(`${resources.lws}/${query}`);
  },

  createLocation(
    _type,
    name,
    parent = null,
    mid = null,
    campusSlug = null,
    coordinates = null,
  ) {
    return axios.post(`${resources.default}/`, {
      type: _type,
      name,
      parent,
      mid,
      campus_slug: campusSlug,
      coordinates,
    });
  },

  editRoom(id, name) {
    return axios.patch(`${resources.default}/${id}/`, { name });
  },

  editBuilding(id, name, mid = null, coordinates = null) {
    return axios.patch(`${resources.default}/${id}/`, {
      name,
      mid,
      coordinates,
    });
  },

  deleteLocation(id) {
    return axios.delete(`${resources.default}/${id}/`);
  },
};

export default LocationApi;
export { LocationApiInterface };
