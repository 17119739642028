
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Provider, DataOptions } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import { openInNewTab } from '@/helpers';
import { Query } from '@/api/query';
import { addDataOptionsToQuery } from '@/api/helpers';
import { prettyPrintFullDate } from '@/utils/filters';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class ProviderList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Ime', value: 'name' },
    { text: 'Oznaka', value: 'slug' },
    { text: 'Št. povezav', value: 'nr_circuits' },
    { text: 'Dokumentacija', value: 'doc_url' },
    { text: 'Posodobljeno', value: 'modified' },
    { text: 'Akcije', value: 'actions', sortable: false },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private providers: Provider[] = [];
  private totalProviders = 0;
  private openInNewTab = openInNewTab;
  private prettyPrintFullDate = prettyPrintFullDate;

  private loading = true;

  private options: DataOptions | null = null;

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchProviders(this.options);
  }

  private async fetchProviders(pagination: DataOptions | null) {
    this.loading = true;
    try {
      const query = new Query({
        search: this.search,
      });
      if (pagination != null)
        addDataOptionsToQuery(query, pagination, 'sort_by');
      const { data } = await repositories.connectivity.provider.getProviders(
        query,
      );
      this.providers = data.results;
      this.totalProviders = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchProviders(this.options!);
  }

  private openAddModal() {
    const promise: Promise<Provider> = this.$modals.open(
      'app-add-edit-provider-modal',
      {
        component: {
          props: {
            editMode: false,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Provider>;

    promise.then((provider: Provider) => {
      this.fetchProviders(this.options);
    });
  }
  private openEditModal(provider: Provider) {
    const promise: Promise<Provider> = this.$modals.open(
      'app-add-edit-provider-modal',
      {
        component: {
          props: {
            editMode: true,
            data: provider,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Provider>;

    promise.then((provider: Provider) => {
      this.fetchProviders(this.options);
    });
  }

  private deleteProvider(modal: any, provider: Provider) {
    repositories.connectivity.provider
      .delete(provider.slug)
      .then(() => {
        this.fetchProviders(this.options);
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Pri izbrisu ponudnika ${provider.name} je prišlo do napake.`,
            },
          ).toString(),
        );
      });
  }

  private openDeleteModal(provider: Provider) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `ponudnika ${provider.name}(${provider.slug})`,
            item: provider,
          },
          deleteFn: this.deleteProvider,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }
}
