
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Autocomplete from '../shared/Autocomplete.vue';
import { Representations } from '../shared/representation';
import { Asset, DeviceInfo, NetboxAsset, Product } from '@/api/interfaces';
import { ModuleAddForm } from './interfaces';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';
import DrawProduct from '@/components/shared/DrawProduct.vue';
import { ValidationProvider } from 'vee-validate';
import { deepClone } from '@/helpers';

@Component({
  components: {
    Autocomplete,
    DrawProduct,
  },
})
export default class AddModule extends Vue {
  public $refs!: {
    position: InstanceType<typeof ValidationProvider>;
  };

  @Prop({ default: undefined }) private device!: DeviceInfo;
  @Prop({ default: null }) private asset!: NetboxAsset | Asset | null;

  // This is needed for drawing the product.
  // If the product is drawn before this component is active it will not be drawn correctly.
  @Prop({ default: false }) private isActive!: boolean;
  private repr = new Representations();
  private search = '';
  private isLoading = false;
  private product: Product | null = null;

  private module: ModuleAddForm = {
    position: null,
  };

  // parent component can get form data through this function
  public getModuleData() {
    return this.module;
  }

  public resetForm() {
    this.module = {
      position: null,
    };
  }

  private get modulesForDrawing() {
    if (this.product == null) return [];
    return [
      {
        position: this.module.position,
        startingPoint:
          this.possiblePositions.find(
            (pos) => pos.value === this.module.position,
          )?.starting_point ?? null,
        product: deepClone(this.product),
      },
    ];
  }

  private get isPositionDisabled() {
    return this.product?.type.name === 'power supply module';
  }

  private get possiblePositions(): {
    value: number;
    starting_point: any;
    text: string;
    disabled: boolean;
  }[] {
    if (this.device != null) {
      const usedSlots =
        this.device?.modules?.map((module) => module.position) ?? [];

      const module_slots = this.device?.asset?.product?.info?.module_slots?.map(
        (slot) => {
          return {
            value: slot.slot,
            text: `${slot.slot}${
              usedSlots.includes(slot.slot) ? ' (zasedeno)' : ''
            }`,
            starting_point: slot.starting_point,
            disabled: usedSlots.includes(slot.slot),
          };
        },
      );
      return module_slots ?? [];
    } else {
      return [];
    }
  }

  private async fetchProduct(): Promise<Product> {
    if ('inventory_sys_id' in this.asset!) {
      return Promise.resolve((this.asset as Asset).product!);
    }
    return repositories.infrastructure.product
      .getProducts(
        null,
        undefined,
        new Query({ name: (this.asset as NetboxAsset).module_type.model }),
      )
      .then((data) => data.data.results[0]);
  }

  @Watch('asset', { deep: true })
  private onAssetChange(): void {
    this.isLoading = true;
    this.resetForm();
    if (!this.asset) {
      // reset managed
      this.device.managed = true;
    }
    if (
      'inventory_sys_id' in this.asset! &&
      this.asset!.inventory_sys_id === null &&
      this.asset!.product === null
    ) {
      // deleted input in model search (not netbox search but custom device model search)
      this.isLoading = false;
      return;
    }
    const promise = this.fetchProduct();
    promise
      .then((product: Product) => {
        // store product so that we know its managed value
        this.product = product;
        // set managed based on product's managed
        this.device.managed = product.managed;
        const productType = product.type.name;
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { itemMessageText: 'strojni opremi' },
          ).toString(),
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
