var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-subheader',[_vm._v("Podatki o napravi")]),_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[(_vm.device)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","disable-sort":"","headers":_vm.headers,"items":[_vm.device],"item-key":"name"},scopedSlots:_vm._u([{key:`item.primary_address`,fn:function({item}){return [(item.primary_address)?[_vm._v(" "+_vm._s(item.primary_address.address)+" ")]:_vm._e(),(item.primary_address6)?[(item.primary_address)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(item.primary_address6.address)+" ")]:_vm._e()]}},{key:`item.os_version`,fn:function({}){return [(
              _vm.deviceFacts?.data?.data?.custom_stats[_vm.device.name]?.system
                ?.os_version
            )?[(
                _vm.deviceFacts.data.data.custom_stats[_vm.device.name].system
                  .os_version ===
                _vm.deviceFacts.data.data.custom_stats[_vm.device.name].system
                  .os_version_valid
              )?[_vm._v(" "+_vm._s(_vm.deviceFacts.data.data.custom_stats[_vm.device.name].system .os_version)+" ")]:[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.deviceFacts.data.data.custom_stats[_vm.device.name].system .os_version)+" ")]),(
                  _vm.deviceFacts.data.data.custom_stats[_vm.device.name].system
                    .os_version_valid != null
                )?[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s('(preverjena ' + _vm.deviceFacts.data.data.custom_stats[_vm.device.name].system .os_version_valid)+" "),_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-information-outline")]),_vm._v(") ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]:[_c('v-tooltip',{attrs:{"bottom":"","max-width":"600px","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v(" (ni preverjene verzije"),_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-information-outline")]),_vm._v(") ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]]]:[_vm._v("[pridobivanje podatkov]")]]}}],null,true)})]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }