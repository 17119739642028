import { render, staticRenderFns } from "./DrawProduct.vue?vue&type=template&id=39b14a7c&scoped=true&"
import script from "./DrawProduct.vue?vue&type=script&lang=ts&"
export * from "./DrawProduct.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b14a7c",
  null
  
)

export default component.exports