const sloveneTaskNames: { [key: string]: string } = {
  campus_prepare: 'Kampus - priprava',
  campus_rename: 'Kampus - preimenovanje',
  device_prepare: 'Naprava - priprava',
  device_configure: 'Naprava - nastavitev',
  device_change: 'Naprava - preimenovanje',
  device_change_ip: 'Naprava - menjava naslova',
  device_remove: 'Naprava - upokojitev',
  device_external_remove: 'Naprava - odstranitev',
  device_get_facts: 'Naprava - pridobitev stanja',
};

const sloveneStatusNames: { [key: string]: string } = {
  planned: 'Planirano',
  active: 'Aktivno',
  discovered: 'Odkrito',
  maintenance: 'V vzdrževanju',
  retired: 'Upokojeno',
};

export { sloveneTaskNames, sloveneStatusNames };
