
import { Component, Prop } from 'vue-property-decorator';
import { Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Network, StaticRoute } from '@/api/interfaces';
import { deepClone } from '@/helpers';
import { repositories } from '@/api/ApiFactory';
import {
  StaticRouteCreateForm,
  UpdateStaticRouteForm,
} from '@/api/lan/static-route';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';

type NetworkShrinked = Pick<Network, 'id' | 'name' | 'slug'>;

interface StaticRouteForm {
  id: number | null;
  prefix: string;
  networkId: number | null;
  nextHopIP: string;
}

function getInitialStaticRoute(): StaticRouteForm {
  return {
    id: null,
    prefix: '',
    nextHopIP: '',
    networkId: null,
  };
}

@Component
export default class StaticRouteModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ default: null }) private campusSlug!: string;
  @Prop({ default: null }) private staticRoute!: StaticRoute | null;

  private staticRouteClone: StaticRouteForm = getInitialStaticRoute();

  private loading = {
    initial: false,
    submit: false,
    networks: false,
  };

  private campusNetworks: NetworkShrinked[] = [];

  private async getNetworksForCampus(campus_slug: string) {
    this.loading.networks = true;
    try {
      const { data } = await repositories.lan.network.getNetworks(
        new Query({ campus_slug: campus_slug }),
      );
      this.campusNetworks = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'omrežjih' },
        ).toString(),
      );
    }
    this.loading.networks = false;
  }

  private get createMode(): boolean {
    return this.staticRouteClone.id == null;
  }

  private formatFormDataForSubmit(
    form: StaticRouteForm,
  ): StaticRouteCreateForm | UpdateStaticRouteForm {
    // Split the prefix into ip address and mask
    const [ip, mask] = form.prefix.split('/');
    const data: StaticRouteCreateForm | UpdateStaticRouteForm = {
      address: ip,
      prefixlen: +mask,
      network: form.networkId,
      next_hop_ip: form.nextHopIP,
    };
    if (form.id != null) {
      (data as UpdateStaticRouteForm).id = form.id;
    }
    return data;
  }

  private submit(): void {
    this.loading.submit = true;

    const api = this.createMode
      ? repositories.lan.staticRoute.createStaticRoute(
          this.formatFormDataForSubmit(
            this.staticRouteClone,
          ) as StaticRouteCreateForm,
        )
      : repositories.lan.staticRoute.updateStaticRoute(
          this.formatFormDataForSubmit(
            this.staticRouteClone,
          ) as UpdateStaticRouteForm,
        );

    api
      .then((res: any) => {
        this.ok(res.data);
      })
      .catch((error: any) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }, {}).toString(),
        );
        this.loading.submit = false;
      });
  }

  private async created() {
    this.loading.initial = true;
    await this.getNetworksForCampus(this.campusSlug);

    if (this.staticRoute != null) {
      const clonedData: StaticRoute = deepClone(this.staticRoute);
      this.staticRouteClone = {
        id: clonedData.id,
        prefix: clonedData.address + '/' + clonedData.prefixlen,
        nextHopIP: clonedData.next_hop_ip,
        networkId: clonedData.network?.id ?? null,
      };
    } else {
      this.staticRouteClone = getInitialStaticRoute();
    }
    this.loading.initial = false;
  }
}
