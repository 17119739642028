
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { CircuitExtended, Provider, RichTextDoc, Technology } from '@/api/interfaces';
import {
  CircuitFormData,
  CircuitStep3Data,
} from '@/components/connectivity/circuit/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import { getProtocolText } from '@/components/shared/helpers';
import translateWord from '@/components/shared/translations';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '@/components/shared/Autocomplete.vue';

@Component({
  components: {
    Autocomplete,
  },
})
export default class CircuitAddOrEditStep3 extends Vue {
  // need to do this so that TS gets correct types
  public $refs!: {
    organization: InstanceType<typeof ValidationProvider>;
  };
  @Prop() private formData!: CircuitFormData;
  @Prop() private campusSlug!: string;
  @Prop({ default: null }) private circuit!: CircuitExtended | null;
  @Prop() private submitting!: boolean;

  private installDateMenu = false;
  private terminationDateMenu = false;
  private searchOrganizationsApi =
    repositories.tenants.organization.searchOrganizations;

  private repr = new Representations();
  private form: CircuitStep3Data = {
    organization: null,
    provider: null,
    provider_identifier: '',
    technology: null,
    commercial: false,
    install_date: null,
    termination_date: null,
    connection: {
      protocol: null,
      username: '',
      password: '',
      address: '',
      gateway: '',
    },
    tunnel: {
      id: '',
      ipv4: '',
      ipv6: '',
    },
  };
  private providers: Provider[] = [];
  private technologies: Technology[] = [];
  private protocols = [
    { text: translateWord('pppoe_chap'), value: 'pppoe_chap' },
    { text: translateWord('pppoe_pap'), value: 'pppoe_pap' },
    { text: translateWord('static'), value: 'static' },
    { text: translateWord('dhcp'), value: 'dhcp' },
  ];

  private loading = {
    providers: true,
    technologies: true,
  };

  get loadingInitial() {
    return this.loading.providers || this.loading.technologies;
  }

  private async created() {
    await this.fetchProviders();
    await this.fetchTechnologies();
    if (this.circuit) {
      const connectionInfo = this.circuit.connection_info;
      this.form = {
        organization: this.circuit.organization,
        provider: this.circuit.provider,
        provider_identifier: this.circuit.provider_identifier as string,
        technology: this.circuit.technology,
        install_date: this.circuit.install_date,
        termination_date: this.circuit.termination_date,
        commercial: connectionInfo.commercial,
        connection: {
          protocol: connectionInfo.connection.protocol,
          username: connectionInfo.connection.username,
          password: connectionInfo.connection.password,
          address: connectionInfo.connection.address,
          gateway: connectionInfo.connection.gateway,
        },
        tunnel: {
          id: connectionInfo.tunnel.id,
          ipv4: connectionInfo.tunnel.ipv4,
          ipv6: connectionInfo.tunnel.ipv6,
        },
      };
    }
  }

  private async fetchProviders() {
    this.loading.providers = true;
    try {
      const { data } = await repositories.connectivity.provider.getProviders();
      this.providers = data.results;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'ponudnikih' },
        ).toString(),
      );
    }
    this.loading.providers = false;
  }

  private async fetchTechnologies() {
    this.loading.technologies = true;
    try {
      const { data } =
        await repositories.connectivity.technology.getTechnologies();
      this.technologies = data.results;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'tehnologijah' },
        ).toString(),
      );
    }
    this.loading.technologies = false;
  }

  private onProtocolChange() {
    if (
      !this.circuit ||
      !['pppoe_chap', 'pppoe_pap'].includes(this.form.connection.protocol!)
    ) {
      this.form.connection.username = '';
      this.form.connection.password = '';
    }
  }

  private sendData() {
    this.$emit('next', this.form);
  }

  private async openCreateOrganizationModal() {
    this.form.organization = await this.$modals.open(
      'app-add-organization-modal',
      {
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    );
    // delay so that input is inserted before the validation takes place
    setTimeout(() => {
      this.$refs.organization.validate();
    }, 0);
  }
}
