import {
  Address,
  Asset,
  Campus,
  CampusSimple,
  DHCPServer,
  DNSResolver,
  Device,
  GatewayInterface,
  House,
  Location,
  Manufacturer,
  NetboxAsset,
  NetboxPatchPanel,
  Network,
  Organization,
  Product,
  ProductType,
  Site,
  SiteSimple,
  Street,
  User,
  Zone,
  ZoneGroup,
} from '@/api/interfaces';

import { CeleryTask } from '../../api/orchestrator/tasks';
import { Vue } from 'vue-property-decorator';

export interface ReprValue<T> {
  text: string;
  extra: string;
  value: T;
}

export class Representations {
  public assetRepr(element: Asset): ReprValue<Asset> {
    const text = element
      ? element.serial_number +
      ' (' +
      (element.base_mac ? element.base_mac : '[ni MAC podatka]') +
      ')'
      : '';
    return Object.assign(
      {},
      {
        text,
        extra: `Serijska: ${element.serial_number}`,
        value: element,
      },
    );
  }

  public netboxAssetRepr(element: NetboxAsset): ReprValue<NetboxAsset> {
    const text = element
      ? element.serial +
      ' (inv: ' +
      (element.asset_tag ? element.asset_tag : '/') +
      ')'
      : '';
    return Object.assign(
      {},
      {
        text,
        extra: `MAC: ${element.custom_fields?.asset_mac
            ? element.custom_fields!.asset_mac
            : '/'
          }`,
        value: element,
      },
    );
  }

  public netboxPatchPanelRepr(
    element: NetboxPatchPanel,
  ): ReprValue<NetboxPatchPanel> {
    let text = element.name;
    if (element.site != null) {
      text += ` - ${element.site.name}`;
    }
    return Object.assign(
      {},
      {
        text,
        extra: '/',
        value: element,
      },
    );
  }

  public campusRepr(element: CampusSimple): ReprValue<CampusSimple> {
    const text = element ? element.name : '';
    return Object.assign({}, { text, extra: element.slug, value: element });
  }

  public deviceRepr(element: Device): ReprValue<Device> {
    const text = element ? element.name : '';
    const extra = `IP: ${element.primary_address ? element.primary_address : '/'
      }, F: ${element.function ? element.function.name : '/'}`;
    return Object.assign({}, { text, extra, value: element });
  }

  public organizationRepr(element: Organization): ReprValue<Organization> {
    const text = element ? element.name : '/';
    const shortName = element.short_name ? element.short_name : '/';
    return Object.assign(
      {},
      {
        text,
        extra: `${element.portal_id} - ${shortName}`,
        value: element,
      },
    );
  }

  // this is an arrow function because i need to recursively call it
  public locationRepr: (element: Location) => ReprValue<Location> = (
    element,
  ) => {
    let text = element ? element.name : '/';
    if (element.parent != null) {
      const parentText = this.locationRepr(element.parent as Location).text;
      text = `${parentText} - ${text}`;
    }
    const addr = element.address as Address;
    let addressRepr = '[ni podatka o naslovu]';
    if (addr != null) {
      if (addr.street_name != null) {
        addressRepr = `${addr.street_name}`;
        addressRepr += addr.house_number
          ? addr.house_number_addon
            ? ` ${addr.house_number}${addr.house_number_addon}`
            : ` ${addr.house_number}`
          : '';
        if (addr!.postal_code != null) {
          addressRepr += `, ${addr.postal_code}`;
          if (addr!.post_name) {
            addressRepr += ` ${addr.post_name}`;
          }
        }
      }
    }
    return Object.assign({}, { text, extra: addressRepr, value: element });
  };

  public locationWithCampusesAndSitesRepr: (
    element: Location,
  ) => ReprValue<Location> = (element) => {
    let text = element ? element.name : '/';
    let extra = '';

    let campusText = '';
    const campuses = element?.campuses;
    if (campuses != null && campuses.length > 0) {
      campusText = `Kampus: ${campuses.join(', ')}`;
    }

    let sitesText = '';
    const sites = element?.sites;
    if (sites != null && sites.length > 0) {
      sitesText = `Vozlišče: ${sites.join(', ')}`;
    }

    if (campusText != '' || sitesText != '') {
      extra = `${campusText} ${
        sitesText != '' && campusText != '' ? '|' : ''
      } ${sitesText}`;
    }

    if (element.parent != null) {
      const parent = this.locationWithCampusesAndSitesRepr(
        element.parent as Location,
      );
      text = `${parent.text} - ${text}`;
      extra = parent.extra;
    } else {
      text = `${text}`;
    }

    return Object.assign({}, { text, extra: extra, value: element });
  };

  public addressRepr(element: Address): ReprValue<Address> {
    let text = '/';
    let extra = '';
    if (element) {
      text = element.street_name as string;
      if (element.house_number) {
        text += ` ${element.house_number}`;
      }
      text += `${element.house_number_addon}`;
      extra = `${element.post_name} ${element.postal_code}`;
    }
    return Object.assign({}, { text, extra, value: element });
  }

  public streetRepr(element: Street): ReprValue<Street> {
    let text = '/';
    let extra = '';
    if (element) {
      text = element.name;
      if (
        element.settlement.replaceAll(' ', '') !==
        element.post.replaceAll(' ', '')
      ) {
        text += ` - ${element.settlement}`;
      }
      extra = `${element.postCode} ${element.post}`;
    }
    return Object.assign({}, { text, extra, value: element });
  }

  public houseRepr(element: House): ReprValue<House> {
    let text = '/';
    const extra = '';
    if (element) {
      const house = element.ehisHouse;
      text = `${house.houseNumber}${house.houseNumberAddon}`;
    }
    return Object.assign({}, { text, extra, value: element });
  }

  public productsRepr(element: Product): ReprValue<Product> {
    return Object.assign(
      {},
      {
        text: element.name,
        extra: `${element.manufacturer.name}
                ${element.part_number ? element.part_number : '/'}
                (${element.platform})`,
        value: element,
      },
    );
  }

  public siteRepr(element: SiteSimple): ReprValue<SiteSimple> {
    const text = element ? element.name + ' (' + element.slug + ')' : '';
    return Object.assign(
      {},
      { text: element.name, extra: element.slug, value: element },
    );
  }

  public tspRepr(element: any): ReprValue<any> {
    const text = element ? element.name : '';
    return Object.assign({}, { text, extra: element.modified, value: element });
  }

  public dhcpServerRepr(element: any): ReprValue<DHCPServer> {
    const text = element ? `${element.name} (${element.address})` : '';
    return Object.assign(
      {},
      {
        text,
        extra: element.campus ? element.campus.slug : null,
        value: element,
      },
    );
  }

  public dnsResolverRepr(element: any): ReprValue<DNSResolver> {
    const text = element ? `${element.name} (${element.address})` : '';
    return Object.assign(
      {},
      {
        text,
        extra: element.campus ? element.campus.slug : null,
        value: element,
      },
    );
  }

  public vlanRepr(element: any): ReprValue<any> {
    const name = element ? element.name : '/';
    let subnets = '';
    const network = element.network;
    if (network !== null) {
      subnets += network.name + ':';
      network.subnets.forEach((subnet: any) => {
        subnets += ' ' + subnet.address + '/' + subnet.prefixlen;
      });
    }
    return Object.assign({}, { text: name, extra: subnets, value: element });
  }

  public zoneRepr(element: Zone): ReprValue<Zone> {
    const text = element ? element.name : '';
    let extra = 'Skupina zone: ';
    if (element.zone_groups!.length > 0) {
      extra += element.zone_groups!.join(', ');
    } else {
      extra += '/';
    }
    return Object.assign(
      {},
      {
        text,
        extra,
        value: element,
      },
    );
  }

  public zoneGroupRepr(element: ZoneGroup): ReprValue<ZoneGroup> {
    const text = element ? element.name : '';
    return Object.assign(
      {},
      {
        text,
        extra: element.slug as string,
        value: element,
      },
    );
  }

  public managementNetworkRepr(element: Network): ReprValue<Network> {
    const text =
      element?.gateway_interfaces
        ?.map((gi: GatewayInterface) => gi.device.name)
        .join(', ') || `/ -  ${element.name}`;
    const vlan = element?.vlan;

    let vid = 'VLAN ID: ';
    if (vlan && typeof vlan !== 'number') {
      vid += `${vlan.vid}`;
    }

    return Object.assign(
      {},
      {
        text,
        extra: `${element.name} (${vid})`,
        value: element,
      },
    );
  }

  public celeryTaskDurationRepr(
    celery_task: CeleryTask,
    type: 'waiting' | 'running',
  ): string {
    let taskStarted: string | number = '';
    let taskUpdated: string | number = '';

    function isFinished(task: CeleryTask): boolean {
      return ['SUCCESS', 'FAILURE', 'REVOKED'].includes(task.status);
    }

    if (type === 'running') {
      if (celery_task.started_at === null) {
        return 'N/A';
      }
      taskStarted = celery_task.started_at;

      if (isFinished(celery_task) && celery_task.completed_at) {
        taskUpdated = celery_task.completed_at;
      } else if (isFinished(celery_task) && celery_task.last_update) {
        taskUpdated = celery_task.last_update;
      } else {
        taskUpdated = new Date().getTime();
      }
    } else if (type === 'waiting') {
      taskStarted = celery_task.created;
      taskUpdated = celery_task.started_at
        ? celery_task.started_at
        : new Date().getTime();
    }
    let seconds = Vue.moment(new Date(taskUpdated)).diff(
      new Date(taskStarted),
      'seconds',
    );
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    return hours !== 0
      ? `${hours}h ${minutes}min ${seconds}sec`
      : minutes
        ? `${minutes}min ${seconds}sec`
        : `${seconds}sec`;
  }

  public userRepr(element: User): ReprValue<User> {
    const text = element
      ? `${element.first_name || '[manjka ime]'} ${
          element.last_name || '[manjka priimek]'
        }`
      : '';
    return Object.assign(
      {},
      {
        text,
        extra: element.username as string,
        value: element,
      },
    );
  }
}
