export const enum PORT_TYPE {
  RJ45 = 'RJ45',
  SFP = 'SFP',
  QSFP = 'QSFP',
  LTE = 'LTE',
  MISC = 'MISC',
}

export const enum PORT_ICON {
  RJ45 = 'rj45.svg',
  SFP = 'sfp.svg',
  SFP_PLUS = 'sfp.svg',
  QSFP = 'qsfp.svg',
  QSFP_PLUS = 'qsfp.svg',
  LTE = 'lte.svg',
  DEFAULT = 'rj45.svg',
}

export const INTERFACE_FORM_FACTORS: {
  [key: number]: { name: string; type: PORT_TYPE; icon: PORT_ICON };
} = {
  800: {
    name: 'IFACE_FF_100ME_FIXED',
    type: PORT_TYPE.RJ45,
    icon: PORT_ICON.RJ45,
  },
  1000: {
    name: 'IFACE_FF_1GE_FIXED',
    type: PORT_TYPE.RJ45,
    icon: PORT_ICON.RJ45,
  },
  1050: {
    name: 'IFACE_FF_1GE_GBIC',
    type: PORT_TYPE.RJ45,
    icon: PORT_ICON.RJ45,
  },
  1100: { name: 'IFACE_FF_1GE_SFP', type: PORT_TYPE.SFP, icon: PORT_ICON.SFP },
  1150: {
    name: 'IFACE_FF_10GE_FIXED',
    type: PORT_TYPE.RJ45,
    icon: PORT_ICON.RJ45,
  },
  1170: {
    name: 'IFACE_FF_10GE_CX4',
    type: PORT_TYPE.RJ45,
    icon: PORT_ICON.RJ45,
  },
  1200: {
    name: 'IFACE_FF_10GE_SFP_PLUS',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP_PLUS,
  },
  1300: { name: 'IFACE_FF_10GE_XFP', type: PORT_TYPE.SFP, icon: PORT_ICON.SFP },
  1310: {
    name: 'IFACE_FF_10GE_XENPAK',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1320: { name: 'IFACE_FF_10GE_X2', type: PORT_TYPE.SFP, icon: PORT_ICON.SFP },
  1350: {
    name: 'IFACE_FF_25GE_SFP28',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1375: {
    name: 'IFACE_FF_50GE_SFP56',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1400: {
    name: 'IFACE_FF_40GE_QSFP_PLUS',
    type: PORT_TYPE.QSFP,
    icon: PORT_ICON.QSFP_PLUS,
  },
  1500: {
    name: 'IFACE_FF_100GE_CFP',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1510: {
    name: 'IFACE_FF_100GE_CFP2',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1520: {
    name: 'IFACE_FF_100GE_CFP4',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1550: {
    name: 'IFACE_FF_100GE_CPAK',
    type: PORT_TYPE.SFP,
    icon: PORT_ICON.SFP,
  },
  1600: {
    name: 'IFACE_FF_100GE_QSFP28',
    type: PORT_TYPE.QSFP,
    icon: PORT_ICON.QSFP,
  },
  1610: {
    name: 'IFACE_FF_400GE_QSFP28_DD',
    type: PORT_TYPE.QSFP,
    icon: PORT_ICON.QSFP,
  },
  1700: {
    name: 'IFACE_FF_400GE_QSFP56_DD',
    type: PORT_TYPE.QSFP,
    icon: PORT_ICON.QSFP,
  },
  2810: {
    name: 'IFACE_FF_LTE', // GSM, not currently used
    type: PORT_TYPE.LTE,
    icon: PORT_ICON.LTE,
  },
  2820: {
    name: 'IFACE_FF_LTE', // CDMA not currently used
    type: PORT_TYPE.LTE,
    icon: PORT_ICON.LTE,
  },
  2830: {
    name: 'IFACE_FF_LTE', // LTE
    type: PORT_TYPE.LTE,
    icon: PORT_ICON.LTE,
  },
};

export const AVAILABLE_NETBOX_ASSET_STATUSES = [
  { text: 'Na razpolago', value: 'stored' },
  { text: 'Rezervirano', value: 'reserved' },
  { text: 'Za donacijo', value: 'donation' },
  { text: 'Na popravilu', value: 'repair' },
  { text: 'Za odpis', value: 'retired' },
  { text: 'Odpisano', value: 'writtenoff' },
];
