import AddModuleToDevice from './views/campus/AddModuleToDevice.vue';
import AdminView from './views/provision/AdminView.vue';
import CampusAdd from './views/campus/CampusAdd.vue';
import CampusCircuits from './components/campus/CampusCircuits.vue';
import CampusDevices from './views/campus/CampusDevices.vue';
import CampusDocs from './views/campus/CampusDocs.vue';
import CampusItem from './views/campus/CampusItem.vue';
import CampusList from './views/campus/CampusList.vue';
import CampusMoveStuff from './views/campus/CampusMoveStuff.vue';
import CampusNetworks from './views/campus/Networks.vue';
import CampusOrganization from './views/campus/Organization.vue';
import CampusParams from './components/campus/CampusParams.vue';
import CampusRunTaskAction from './views/campus/CampusRunTaskAction.vue';
import CircuitAddOrEdit from './views/connectivity/CircuitAddOrEdit.vue';
import CircuitDetail from './views/connectivity/CircuitDetail.vue';
import Circuits from './views/connectivity/Circuits.vue';
import CircuitDocs from './views/connectivity/CircuitDocs.vue';
import Contacts from './views/Contacts.vue';
import CreateDevice from './views/campus/CreateDevice.vue';
import DHCPServerDetail from './views/dhcp/DHCPServerDetail.vue';
import DHCPServers from './views/dhcp/DHCPServers.vue';
import DNSResolverDetail from './views/dns/DNSResolverDetail.vue';
import DNSResolvers from './views/dns/DNSResolvers.vue';
import EditInterface from './components/interfaces/EditInterface.vue';
import GeneralCampusPortalIdRedirector from './views/campus/GeneralCampusPortalIdRedirector.vue';
import GeneralInterfacesEditorRedirector from './views/infrastructure/GeneralInterfacesEditorRedirector.vue';
import Home from './views/Home.vue';
import Inventory from './views/inventory/Inventory.vue';
import LatestDevices from './views/infrastructure/LatestDevices.vue';
import Login from './views/Login.vue';
import ManagementNetworkDetail from './views/managementNetworks/ManagementNetworkDetail.vue';
import ManagementNetworksList from './views/managementNetworks/ManagementNetworksList.vue';
import ModifyInterfaces from './views/infrastructure/ModifyInterfaces.vue';
import { ModifyInterfacesComponentMode } from '@/components/shared/helpers';
import PageNotFound from './views/PageNotFound.vue';
import ProductAdd from './views/infrastructure/ProductAdd.vue';
import ProductDetail from './views/infrastructure/ProductDetail.vue';
import ProductList from './views/infrastructure/ProductList.vue';
import ProvidersList from './views/connectivity/provider/ProviderList.vue';
import Router from 'vue-router';
import RouterViewHolder from './views/RouterViewHolder.vue';
import RouterViewWithBreadcrumbsHolder from './views/RouterViewWithBreadcrumbsHolder.vue';
import Settings from './views/Settings.vue';
import SiteDetail from './views/connectivity/SiteDetail.vue';
import SitesList from './views/connectivity/SitesList.vue';
import TaskDetail from './views/tasks/TaskDetail.vue';
import Tasks from './views/tasks/Tasks.vue';
import TechnologiesList from './views/connectivity/technology/TechnologyList.vue';
import Tools from './views/Tools.vue';
import VisualizeNetworkTopology from './views/campus/VisualizeNetworkTopology.vue';
import Vue from 'vue';
import ZoneGroupList from './views/zonegroup/ZoneGroupList.vue';
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        breadCrumb: 'prijava',
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
      meta: {
        breadCrumb: 'kontakti',
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        requiresAuth: true,
        breadCrumb: 'nastavitve',
      },
    },
    {
      path: '/products',
      component: RouterViewWithBreadcrumbsHolder,
      meta: {
        requiresAuth: true,
        requiresGroup: ['admin', 'support'],
        breadCrumb: 'modeli',
        includeInTitle: true,
      },
      children: [
        {
          path: '',
          name: 'products',
          component: ProductList,
          props: true,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
          },
        },
        {
          path: 'add',
          name: 'productAdd',
          component: ProductAdd,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin'],
            breadCrumb: 'dodajanje',
          },
        },
        {
          path: ':product',
          name: 'productDetail',
          component: ProductDetail,
          props: true,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
          },
        },
      ],
    },
    // Universal route which is used to open and redirect to the correct interface editor
    // based on the device ownership / usage
    {
      path: '/device-interfaces-edit',
      component: RouterViewWithBreadcrumbsHolder,
      meta: {
        requiresAuth: true,
        breadCrumb: 'urejanje vmesnikov',
        breadCrumbDisabled: true,
      },
      children: [
        {
          path: ':deviceName',
          name: 'generalInterfacesEditor',
          component: GeneralInterfacesEditorRedirector,
          props: (route) => ({
            deviceName: route.params.deviceName,
          }),
        },
      ],
    },
    {
      path: '/campuses',
      component: RouterViewHolder,
      meta: {
        requiresAuth: true,
        breadCrumb: 'kampusi',
      },
      children: [
        {
          path: '',
          name: 'campuses',
          component: CampusList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'arnes-id',
          component: RouterViewWithBreadcrumbsHolder,
          meta: {
            requiresAuth: true,
            breadCrumb: 'arnes ID',
            breadCrumbDisabled: true,
          },
          children: [
            {
              path: ':portalId',
              name: 'generalPortalIdRedirector',
              component: GeneralCampusPortalIdRedirector,
              props: (route) => ({
                portalId: route.params.portalId,
              }),
            },
          ],
        },
        {
          path: 'add',
          name: 'campusesAdd',
          component: CampusAdd,
          meta: {
            requiresAuth: true,
            breadCrumb: 'dodajanje',
          },
        },
        {
          path: ':campusSlug',
          component: CampusItem,
          props: true,
          meta: {
            requiresAuth: true,
            includeInTitle: true,
          },
          redirect: { name: 'campusGeneral' },
          children: [
            {
              path: 'general',
              component: RouterViewHolder,
              props: true,
              meta: {
                requiresAuth: true,
                breadCrumb: 'splošno',
              },
              name: 'campusGeneral',
              redirect: { name: 'organization' },
              children: [
                {
                  path: '',
                  name: 'organization',
                  component: CampusOrganization,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: 'move-stuff',
                  name: 'moveStuff',
                  component: CampusMoveStuff,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                    breadCrumb: 'premikanje stvari',
                  },
                },
                {
                  path: 'run-task-action',
                  name: 'runTaskAction',
                  component: CampusRunTaskAction,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                    breadCrumb: 'poganjanje akcij',
                  },
                },
                {
                  path: 'params',
                  name: 'campusParams',
                  component: CampusParams,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    breadCrumb: 'parametri',
                  },
                },
              ],
            },
            {
              path: 'devices',
              component: RouterViewHolder,
              props: true,
              meta: {
                requiresAuth: true,
                breadCrumb: 'naprave',
              },
              children: [
                {
                  path: '',
                  name: 'devices',
                  component: CampusDevices,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },

                {
                  path: 'add',
                  name: 'campusCreateDevice',
                  component: CreateDevice,
                  props: (route) => ({
                    campusSlug: route.params.campusSlug,
                  }),
                  meta: {
                    requiresAuth: true,
                    breadCrumb: 'dodajanje',
                  },
                },
                {
                  path: 'add-modules',
                  component: RouterViewHolder,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                    includeInTitle: true,
                    breadCrumbDisabled: true,

                    breadCrumb: 'dodajanje modulov',
                  },
                  children: [
                    {
                      path: ':deviceName',
                      name: 'campusAddModuleToDevice',
                      component: AddModuleToDevice,
                      props: (route) => ({
                        campusSlug: route.params.campusSlug,
                        deviceName: route.params.deviceName,
                      }),
                      meta: {
                        requiresGroup: ['admin'],
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'interfaces-edit',
                  name: 'interfaces',
                  component: ModifyInterfaces,
                  props: (route) => ({
                    campusSlug: route.params.campusSlug,
                    deviceName: route.params.deviceName,
                    mode: ModifyInterfacesComponentMode.CAMPUS,
                  }),
                  meta: {
                    requiresAuth: true,
                    breadCrumb: 'urejanje vmesnikov',
                    breadCrumbDisabled: true,
                  },
                  children: [
                    {
                      path: ':deviceName',
                      name: 'interfacesOnDevice',
                      component: EditInterface,
                      props: (route) => ({
                        deviceName: route.params.deviceName,
                        campusSlug: route.params.campusSlug,
                      }),
                      meta: {
                        requiresAuth: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: 'networks',
              component: RouterViewHolder,
              props: true,
              meta: {
                requiresAuth: true,
                breadCrumb: 'omrežja',
              },
              children: [
                {
                  component: CampusNetworks,
                  path: '',
                  name: 'networks',
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'topology',
              component: RouterViewHolder,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support', 'roid'],
                breadCrumb: 'topologija omrežja',
              },
              children: [
                {
                  path: '',
                  name: 'visualizeNetworkTopology',
                  component: VisualizeNetworkTopology,
                  props: true,
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'circuits',
              component: RouterViewHolder,
              meta: {
                requiresAuth: true,
                breadCrumb: 'povezave',
              },
              children: [
                {
                  path: '',
                  name: 'circuits',
                  props: true,
                  component: CampusCircuits,
                  meta: {
                    requiresAuth: true,
                  },
                },
                {
                  path: 'add',
                  name: 'createCircuit',
                  component: CircuitAddOrEdit,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                    breadCrumb: 'dodajanje',
                  },
                },
                {
                  path: ':circuitId',
                  component: RouterViewHolder,
                  meta: {
                    requiresAuth: true,
                  },
                  children: [
                    {
                      path: 'edit',
                      name: 'editCircuit',
                      component: CircuitAddOrEdit,
                      props: true,
                      meta: {
                        requiresAuth: true,
                        requiresGroup: ['admin'],
                        breadCrumb: 'urejanje',
                      },
                    },
                    {
                      path: '',
                      name: 'viewCircuit',
                      component: CircuitDetail,
                      props: true,
                      meta: {
                        requiresAuth: true,
                        requiresGroup: ['admin', 'support', 'roid'],
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: 'docs/:docSlug?',
              name: 'docs',
              component: CampusDocs,
              props: true,
              meta: {
                requiresAuth: true,
                breadCrumb: 'dokumentacija',
                breadCrumbDisabled: true,
              },
            },
            {
              path: 'dhcp-servers',
              name: 'campusDhcpServers',
              component: DHCPServers,
              props: (route) => ({
                campusSlug: route.params.campusSlug,
                fixedNetworkCampus: true,
              }),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'dhcp-servers/:dhcpServerId',
              name: 'campusDhcpServer',
              component: DHCPServerDetail,
              props: (route) => ({
                dhcpServerId: route.params.dhcpServerId,
                campusSlug: route.params.campusSlug,
                fixedNetworkCampusSlug: route.params.campusSlug,
              }),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'dns-resolvers',
              name: 'campusDnsResolvers',
              component: DNSResolvers,
              props: (route) => ({
                campusSlug: route.params.campusSlug,
                fixedNetworkCampus: true,
              }),
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: 'dns-resolvers/:dnsResolverId',
              name: 'campusDnsResolver',
              component: DNSResolverDetail,
              props: (route) => ({
                dnsResolverId: route.params.dnsResolverId,
                campusSlug: route.params.campusSlug,
                fixedNetworkCampusSlug: route.params.campusSlug,
              }),
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: '/sites',
      component: RouterViewWithBreadcrumbsHolder,
      meta: {
        requiresAuth: true,
        requiresGroup: ['admin', 'support'],
        breadCrumb: 'vozlišča',
      },
      children: [
        {
          path: '',
          name: 'sites',
          component: SitesList,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
          },
        },
        {
          path: ':siteSlug',
          component: RouterViewHolder,
          props: true,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            includeInTitle: true,
          },
          children: [
            {
              path: ':docSlug?',
              name: 'site',
              component: SiteDetail,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: 'interfaces-edit',
              name: 'interfacesEditorSite',
              component: ModifyInterfaces,
              props: (route) => ({
                siteSlug: route.params.siteSlug,
                deviceName: route.params.deviceName,
                mode: ModifyInterfacesComponentMode.SITE,
              }),
              meta: {
                requiresAuth: true,
                breadCrumb: 'urejanje vmesnikov',
                breadCrumbDisabled: true,
              },
              children: [
                {
                  path: ':deviceName',
                  name: 'interfacesOnSiteDevice',
                  component: EditInterface,
                  props: (route) => ({
                    deviceName: route.params.deviceName,
                  }),
                  meta: {
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'devices/add',
              name: 'siteCreateDevice',
              component: CreateDevice,
              props: (route) => ({
                siteSlug: route.params.siteSlug,
              }),
              meta: {
                requiresAuth: true,
                breadCrumb: 'dodajanje',
                breadCrumbDisabled: true,
              },
            },
            {
              path: 'add-modules',
              component: RouterViewHolder,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin'],
                includeInTitle: true,
                breadCrumbDisabled: true,

                breadCrumb: 'dodajanje modulov',
              },
              children: [
                {
                  path: ':deviceName',
                  name: 'siteAddModuleToDevice',
                  component: AddModuleToDevice,
                  props: (route) => ({
                    campusSlug: null,
                    deviceName: route.params.deviceName,
                  }),
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/tools',
      component: RouterViewWithBreadcrumbsHolder,
      meta: {
        requiresAuth: true,
        requiresGroup: ['admin', 'support', 'roid'],
        breadCrumb: 'orodja',
      },
      children: [
        {
          path: '',
          component: Tools,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'dhcp-servers',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'DHCP strežniki',
            includeInTitle: true,
          },
          children: [
            {
              path: '',
              name: 'dhcpServers',
              component: DHCPServers,
              props: (route) => ({ canChangeCampus: true }),
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: ':dhcpServerId',
              name: 'dhcpServer',
              component: DHCPServerDetail,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
          ],
        },
        {
          path: 'dns-resolvers',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'DNS strežniki',
            includeInTitle: true,
          },
          children: [
            {
              path: '',
              name: 'dnsResolvers',
              component: DNSResolvers,
              props: (route) => ({ canChangeCampus: true }),
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: ':dnsResolverId',
              name: 'dnsResolver',
              component: DNSResolverDetail,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
          ],
        },
        {
          path: 'zone-groups',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'skupine con',
          },
          children: [
            {
              path: '',
              name: 'zone-groups',
              component: ZoneGroupList,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
          ],
        },
        {
          path: 'management-networks',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'management omrežja',
            includeInTitle: true,
          },
          children: [
            {
              path: '',
              name: 'managementNetworks',
              component: ManagementNetworksList,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: ':managementNetworkId',
              name: 'managementNetworkDetail',
              component: ManagementNetworkDetail,
              props: true,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
          ],
        },
        {
          path: 'admin',
          component: RouterViewHolder,
          props: true,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
          },
          children: [
            {
              path: '',
              name: 'adminPage',
              component: AdminView,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: 'devices-add',
              name: 'createDevice',
              component: CreateDevice,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin'],
              },
            },
            {
              path: 'providers',
              component: RouterViewHolder,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
                breadCrumb: 'ponudniki',
              },
              children: [
                {
                  path: '',
                  name: 'providers',
                  component: ProvidersList,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin', 'support'],
                  },
                },
              ],
            },
            {
              path: 'technologies',
              component: RouterViewHolder,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
                breadCrumb: 'tehnologije',
              },
              children: [
                {
                  path: '',
                  name: 'technologies',
                  component: TechnologiesList,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin', 'support'],
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'inventory',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support', 'roid'],
            breadCrumb: 'strojna oprema',
          },
          children: [
            {
              path: '',
              name: 'inventory',
              component: Inventory,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support', 'roid'],
              },
            },
          ],
        },
        {
          path: 'circuits-list',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'povezave',
          },
          children: [
            {
              path: '',
              name: 'circuits-list',
              component: Circuits,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: 'add',
              name: 'create-global-circuit',
              component: CircuitAddOrEdit,
              props: (route) => ({
                campusSlug: null,
              }),
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin'],
                breadCrumb: 'dodajanje',
              },
            },
            {
              path: ':circuitId',
              component: RouterViewHolder,
              meta: {
                requiresAuth: true,
              },
              children: [
                {
                  path: 'edit',
                  name: 'edit-global-circuit',
                  component: CircuitAddOrEdit,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin'],
                    breadCrumb: 'urejanje',
                  },
                },
                {
                  path: '',
                  name: 'view-global-circuit',
                  component: CircuitDetail,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    requiresGroup: ['admin', 'support'],
                  },
                },
                {
                  path: 'docs/:docSlug?',
                  name: 'global-circuit-docs',
                  component: CircuitDocs,
                  props: true,
                  meta: {
                    requiresAuth: true,
                    breadCrumb: 'dokumentacija',
                    breadCrumbDisabled: true,
                    requiresGroup: ['admin', 'support'],
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'latest-devices',
          component: RouterViewHolder,
          meta: {
            requiresAuth: true,
            requiresGroup: ['admin', 'support'],
            breadCrumb: 'nova omrežna oprema',
          },
          children: [
            {
              path: '',
              name: 'latest-devices',
              component: LatestDevices,
              meta: {
                requiresAuth: true,
                requiresGroup: ['admin', 'support'],
              },
            },
            {
              path: 'add',
              name: 'create-cpe-device',
              component: CreateDevice,
              props: (route) => ({
                cpeOnly: true,
              }),
              meta: {
                requiresAuth: true,
                breadCrumb: 'dodajanje',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        breadCrumb: 'o automatorju',
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => {
        return import(/* webpackChunkName: "about" */ './views/About.vue');
      },
    },
    {
      path: '/tasks',
      component: RouterViewWithBreadcrumbsHolder,
      meta: {
        requiresAuth: true,
        breadCrumb: 'opravila',
      },
      children: [
        {
          path: '',
          name: 'tasks',
          component: Tasks,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: ':taskId',
          name: 'taskDetail',
          component: TaskDetail,
          props: true,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    { path: '*', name: 'pageNotFound', component: PageNotFound },
  ],
});

// Get the environment
const appEnv = process.env?.VUE_APP_SENTRY_ENVIRONMENT;
let titleEmoji = '';
if (appEnv === 'local') {
  titleEmoji = '💻 ';
} else if (appEnv === 'testing') {
  titleEmoji = '🧪 ';
}

/*
 * Handle require_auth meta property on routes and redirect to login if not logged in
 * Also set the page title based on the route meta data/path or default to 'Automator'
 */
router.beforeEach((to, from, next) => {
  // Parse the path to get the last part of the path
  const pathArray = to.path.split('/');
  pathArray.shift();
  const breadcrumbs = pathArray.reduce(
    (pathItems: { text: string; mustBeIncluded: boolean }[], path, idx) => {
      if (path !== '') {
        if (idx < to.matched.length) {
          pathItems.push({
            mustBeIncluded: to.matched[idx].meta.includeInTitle,
            text: to.matched[idx].meta.breadCrumb || path,
          });
        }
      }
      return pathItems;
    },
    [],
  );
  const currentPage = breadcrumbs.pop();
  const rootPages = breadcrumbs
    .filter((item) => item.mustBeIncluded)
    .map((item) => item.text)
    .join(' / ');

  let title = '';
  if (rootPages != null && rootPages !== '') {
    title += `${rootPages} · `;
  }
  if (currentPage?.text != null && currentPage.text !== '') {
    title += `${currentPage.text} · `;
  }

  // Set the page title
  document.title = `${titleEmoji}${title}Automator`;

  // trying to change view while the page is in maintenance should redirect to maintenance page
  if (
    to.path !== from.path &&
    (Vue.toasted as any).toasts.some((toast: any) =>
      toast.el.innerText.includes('vzdrževalna dela'),
    )
  ) {
    // reloading the page will give you maintenance page
    location.reload();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      // NOTE: requiresGroup is an array specifying allowed groups
      const userRoles = store.getters['user/roles'];
      const passesGroupFilter = to.matched.every((record) => {
        return (
          !record.meta.requiresGroup ||
          userRoles.filter((role: string) =>
            record.meta.requiresGroup.includes(role),
          ).length > 0
        );
      });
      if (passesGroupFilter) {
        next();
        return;
      }
      next({ name: 'pageNotFound', query: { redirect: to.fullPath } });
      return;
    }
    next({ name: 'login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
