
import { Component, Prop } from 'vue-property-decorator';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { DeviceModule } from '@/api/interfaces';

@Component
export default class ConfirmModuleDeleteModal extends PersistentModal {
  @Prop() private data!: {
    item: DeviceModule;
    repr: string;
    form?: { assetStatus: string | null };
  };
  @Prop() private deleteFn!: (modal: any, item: any) => void;
  private deleting = false;
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;
  private form: { assetStatus: string | null } = {
    assetStatus: null,
  };
  private confirmText = '';

  private get product() {
    if (this.data.item.asset.inventory_sys_id !== null) {
      return {
        product: this.data.item.asset.product?.name,
        product_type: this.data.item.asset.product?.type.name,
        serial_number: this.data.item.asset.serial_number,
        type: 'Modul',
      };
    }
    return null;
  }

  private get showSelectAssetStatus() {
    return this.product != null;
  }

  private deleteItem() {
    this.deleting = true;
    this.data.form = this.form;
    this.deleteFn(this, this.data);
  }
}
