var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"rounded":"","color":"subsecondary"}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_c('v-toolbar-title',[_vm._v(" Dodajanje modula ")]),_c('v-spacer')],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',[_c('v-stepper',{model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 1,"step":"1"}},[_vm._v("Izbira modula")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.currentStep > 2,"step":"2"}},[_vm._v("Vnos podatkov")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v("Konec")])],1),_c('v-stepper-items',[_c('v-stepper-content',{class:{
                'pa-1': _vm.$vuetify.breakpoint.smAndDown,
              },attrs:{"step":"1"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(obsStep1Props){return [_c('add-asset',{key:_vm.addAssetKey,ref:"step1Component",attrs:{"campusSlug":_vm.campusSlug,"title":"Izbira strojne opreme","form":_vm.deepCloneFn(_vm.deviceModuleForm),"allowAssetsInUse":!_vm.campusSlug,"productTypes":['line card module', 'power supply module'],"kind":"module"}}),_c('v-btn',{attrs:{"color":"primary","disabled":obsStep1Props.invalid},on:{"click":function($event){return obsStep1Props.passes(_vm.handleStep1Submit)}}},[_c('v-icon',[_vm._v("mdi-arrow-right")]),_vm._v(" Naprej")],1)]}}])})],1),_c('v-stepper-content',{class:{
                'pa-1': _vm.$vuetify.breakpoint.smAndDown,
              },attrs:{"step":"2"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(obsStep2Props){return [_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('add-module',{ref:"step2Component",attrs:{"campusSlug":_vm.campusSlug,"siteSlug":_vm.siteSlug,"device":_vm.deviceData,"asset":_vm.deviceModuleForm.asset,"isActive":_vm.currentStep === 2}})],1),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":obsStep2Props.invalid},on:{"click":function($event){return obsStep2Props.passes(_vm.handleStep2Submit)}}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" Kreiraj")],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentStep = 1}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Nazaj")],1)]}}])})],1),_c('v-stepper-content',{class:{
                'pa-1': _vm.$vuetify.breakpoint.smAndDown,
              },attrs:{"step":"3"}},[_c('v-alert',{attrs:{"type":"success","outlined":""}},[_c('span',[_vm._v("Modul na napravi "+_vm._s(_vm.deviceData?.name)+" je bil uspešno dodan.")])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetForm()}}},[_c('v-icon',[_vm._v("mdi-restart")]),_vm._v(" Dodaj naslednjega")],1),_c('v-btn',{attrs:{"color":"default","to":{
                  name: 'generalInterfacesEditor',
                  params: { deviceName: _vm.deviceData?.name },
                }}},[_c('v-icon',[_vm._v(" mdi-open-in-app")]),_vm._v(" Pojdi na napravo ")],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }