
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CampusInfoExpansionPanelHeader extends Vue {
  @Prop() private panelIndex!: number;
  @Prop() openPanels!: Array<number>;

  private get isOpen() {
    return this.openPanels.includes(this.panelIndex);
  }
  private togglePanel() {
    const panels = [...this.openPanels];
    if (this.isOpen) {
      // Close the panel
      this.$emit(
        'update:openPanels',
        panels.filter((i) => i !== this.panelIndex),
      );
    } else {
      // Open the panel
      this.$emit('update:openPanels', [...panels, this.panelIndex]);
    }
  }
}
