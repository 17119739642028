
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Site, Device, Interface, CircuitExtended } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { AxiosResponse } from 'axios';
import { PaginatedResponse } from '@/api/interfaces';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '@/components/shared/Autocomplete.vue';

@Component({
  components: {
    Autocomplete,
  },
})
export default class CircuitAddOrEditStep1 extends Vue {
  @Prop({ default: null }) private campusSlug!: string | null;
  @Prop({ type: Boolean, default: false }) private editMode!: boolean | null;
  @Prop({ default: null }) private circuit!: CircuitExtended | null;

  private repr = new Representations();
  private sites: Site[] = [];
  private form: {
    status: string;
    serviceId: string;
    connectionType: string;
    campusInterface: Interface | null;
    campusInterfaceDescription: string | null;
    site: Site | null;
    nodeInterface: Interface | null;
    nodeInterfaceDescription: string | null;
  } = {
    status: '',
    serviceId: '',
    connectionType: 'l2',
    campusInterface: null,
    campusInterfaceDescription: null,
    site: null,
    nodeInterface: null,
    nodeInterfaceDescription: null,
  };
  private nodeDevices: Device[] = [];
  private nodeDevice: Device | null = null;
  private nodeInterfaces: Interface[] = [];
  private campusDevices: Device[] = [];
  private campusDevice: Device | null = null;
  private campusInterfaces: Interface[] = [];

  private statuses = [
    { text: 'Planirana', value: 'planned' },
    { text: 'Aktivna', value: 'active' },
  ];
  private types = [
    {
      text: 'L2',
      value: 'l2',
      help:
        "Gre za povezave, kjer je na vozliščni strani TSP naprava, na kampus strani pa stikalo. Vmesnika bosta dobila uporabo 'infrastruktura'. Vsi VLANi kampusa (ki niso lanonly) so dovoljeni na vmesnikih te povezave.\n\n" +
        "Pri povezavi s statusom 'planirana', bodo izbrani vmesniki označeni kot 'v uporabi', vendar se še ne bodo konfigurirali. Če je povezava planirana, je lahko vmesnik na kampus strani prazen.\n\n" +
        "Preden je možno povezavo postavit v 'aktivno' stanje, mora biti TSP naprava umeščena v globalne omrežne cone kampusa in določen vmesnik na kampus strani.",
    },
    {
      text: 'Tunel',
      value: 'tunnel',
      help:
        'Gre za povezave, kjer je CPE naprava usmerjevalnik, kateri je običajno povezan v IP omrežje zunanjega operaterja. CPE dodatno vzpostavi tunel do Arnes koncentratorja. Promet se usmerja skozi tunel.\n\n' +
        'Na kampus strani se izbere fizični vmesnik, ki je povezan v omrežje operaterja, ki ponuja povezljivost. Vozliščna stran ostane prazna.',
    },
    {
      text: 'L3',
      value: 'l3',
      help:
        'Gre za povezave, kjer je CPE naprava usmerjevalnik in je povezana na hrbtenični usmerjevalnik ali TSP.\n\n' +
        "Vmesnike, ki so del te povezave, NOC ročno konfigurira direktno na napravah. Ti vmesniki so v automatorju nastavljeni kot 'Ni v uporabi' in imajo izključeno avtomatsko konfiguriranje.",
    },
  ];

  private loading = {
    sites: true,
    nodeDevices: false,
    nodeInterfaces: false,
    campusDevices: true,
    campusInterfaces: false,
    edit: false,
  };

  private get typeHelpText() {
    return (
      this.types.find((t) => t.value === this.form.connectionType)?.help ||
      'Izberite tip povezave.'
    );
  }

  private get campusInterfacesWithDisabled() {
    return this.campusInterfaces.map((intf: Interface) => {
      const not_active = intf.port !== null && !intf.port_active;
      const already_used =
        intf.circuit !== null &&
        (!this.editMode ||
          (this.editMode && this.circuit?.id !== intf.circuit?.id));

      const disabled = not_active || already_used;
      return { ...intf, ...{ disabled, already_used } };
    });
  }

  private get nodeInterfacesWithDisabled() {
    return this.nodeInterfaces.map((intf: Interface) => {
      const not_active = intf.port !== null && !intf.port_active;
      const already_used =
        intf.circuit !== null &&
        (!this.editMode ||
          (this.editMode && this.circuit?.id !== intf.circuit?.id));

      const disabled = not_active || already_used;
      return { ...intf, ...{ disabled, already_used } };
    });
  }

  @Watch('form.nodeInterface')
  private setNodeInterfaceDescription() {
    if (this.form.nodeInterface) {
      this.form.nodeInterfaceDescription = this.form.nodeInterface.description;
    } else {
      this.form.nodeInterfaceDescription = null;
    }
  }

  @Watch('form.campusInterface')
  private setCampusInterfaceDescription() {
    if (this.form.campusInterface) {
      this.form.campusInterfaceDescription =
        this.form.campusInterface.description;
    } else {
      this.form.campusInterfaceDescription = null;
    }
  }

  private async created() {
    if (this.editMode) {
      this.loading.edit = true;
    }

    try {
      const { data } = await repositories.connectivity.site.getSites();
      this.sites = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju vozlišč.' },
        ).toString(),
      );
    } finally {
      this.loading.sites = false;
    }

    if (this.editMode && this.circuit) {
      this.form.status = this.circuit.status;
      this.form.serviceId = this.circuit.service_id;
      this.form.connectionType = this.circuit.type;

      this.form.site =
        this.sites.find(
          (site: Site) =>
            site.slug ===
            this.circuit?.termination_a.site?.slug,
        ) || null;
      await Promise.all([
        this.populateNodeDevices(),
        this.populateCampusDevices(),
      ]);

      const interfacePopulateFunctions = [];

      this.nodeDevice = this.circuit.termination_a.interface?.device.id
        ? this.nodeDevices.find(
            (device: Device) =>
              device.id === this.circuit?.termination_a.interface?.device.id,
          ) || null
        : null;
      if (this.nodeDevice) {
        interfacePopulateFunctions.push(
          this.populateInterfaces(this.nodeDevice, 'node'),
        );
      }

      this.campusDevice = this.circuit.termination_z.interface?.device.id
        ? this.campusDevices.find(
            (device: Device) =>
              device.id === this.circuit?.termination_z.interface?.device.id,
          ) || null
        : null;
      if (this.campusDevice) {
        interfacePopulateFunctions.push(
          this.populateInterfaces(this.campusDevice, 'campus'),
        );
      }

      await Promise.all(interfacePopulateFunctions);

      if (this.nodeDevice) {
        this.form.nodeInterface = this.circuit.termination_a.interface?.id
          ? this.nodeInterfaces.find(
              (intf: Interface) =>
                intf.id === this.circuit?.termination_a.interface?.id,
            ) || null
          : null;
      }
      if (this.campusDevice) {
        this.form.campusInterface = this.circuit.termination_z.interface?.id
          ? this.campusInterfaces.find(
              (intf: Interface) =>
                intf.id === this.circuit?.termination_z.interface?.id,
            ) || null
          : null;
      }

      this.loading.edit = false;
    } else {
      await this.populateCampusDevices();
    }
  }

  private async populateCampusDevices() {
    // tsp supports L2, L3 and routers, backbone doesn't support L2
    const nodeDeviceType = 'tsp';
    const functions = ['router', 'l3_switch'];
    if (
      this.nodeDevice == null ||
      !this.nodeDevice!.roles!.some((role) => role.name === 'Backbone')
    ) {
      // node device isn't a backbone device, so L2 switch is also ok
      functions.push('l2_switch');
    }
    this.loading.campusDevices = true;
    try {
      const apiFilter: any = {
        campusSlug: this.campusSlug as string,
        roles: ['cpe'],
        functions,
      };
      if (!this.campusSlug) {
        apiFilter.hasZone = false;
      }
      const { data } = await repositories.infrastructure.device.getDevices(apiFilter);
      this.campusDevices = data.results;
      // reset selected campus device if it's not in the new list
      if (
        this.campusDevice != null &&
        !this.campusDevices.some(
          (device) => device.id === this.campusDevice!.id,
        )
      ) {
        this.campusDevice = null;
        this.form.campusInterface = null;
        this.campusInterfaces = [];
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju naprav kampusa.' },
        ).toString(),
      );
    } finally {
      this.loading.campusDevices = false;
    }
  }

  private async populateNodeDevices() {
    // if connection type is tunnel then node device must be empty
    if (this.form.site == null || this.form.connectionType === 'tunnel') {
      if (this.form.connectionType === 'tunnel') {
        this.form.site = null;
      }
      this.nodeDevices = [];
      this.nodeDevice = null;
      this.nodeInterfaces = [];
      this.form.nodeInterface = null;
      return;
    }
    let roles = [];
    if (
      this.form.connectionType === 'l2' ||
      this.form.connectionType === 'l3'
    ) {
      roles.push('tsp');
    }
    if (this.form.connectionType === 'l3') {
      roles.push('backbone');
    }
    this.loading.nodeDevices = true;
    try {
      const { data } =
        await repositories.infrastructure.device.searchSiteDevices({
          siteSlug: this.form.site!.slug,
          roles,
        });
      this.nodeDevices = data.results;
      if (
        this.nodeDevice != null &&
        !data.results.some(
          (device: Device) => device.id === this.nodeDevice!.id,
        )
      ) {
        this.nodeDevice = null;
      }
      if (this.nodeDevice === null) {
        this.nodeInterfaces = [];
        this.form.nodeInterface = null;
      }
      this.loading.nodeDevices = false;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { message: 'Napaka pri pridobivanju naprav v vozlišču.' },
        ).toString(),
      );
      this.loading.nodeDevices = false;
    }
  }

  private async populateInterfaces(device: Device | null, type: string) {
    if (device === null) {
      if (type === 'node') {
        this.nodeInterfaces = [];
        this.form.nodeInterface = null;
      } else {
        this.campusInterfaces = [];
        this.form.campusInterface = null;
      }
      return;
    }
    if (type === 'campus') {
      this.loading.campusInterfaces = true;
    } else {
      this.loading.nodeInterfaces = true;
    }
    await repositories.infrastructure.interface
      .getInterfacesByDeviceId(device.id, { physicalOnly: true })
      .then((resp: AxiosResponse<PaginatedResponse<Interface>>) => {
        const data = resp.data.results;
        if (type === 'node') {
          this.nodeInterfaces = data;
          this.form.nodeInterface = null;
          this.loading.nodeInterfaces = false;
        } else {
          this.campusInterfaces = data;
          this.form.campusInterface = null;
          this.loading.campusInterfaces = false;
        }
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Napaka pri pridobivanju vmesnikov.' },
          ).toString(),
        );
      });
  }

  private sendData() {
    this.$emit('next', this.form);
  }
}
