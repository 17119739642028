
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { Provider } from '@/api/interfaces';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { providerCreateData } from '@/api/connectivity/provider';
import { ValidationProvider } from 'vee-validate';
@Component({
  components: {
    Autocomplete,
  },
})
export default class AddOrEditProviderModal extends Mixins<
  PromiserType,
  PersistentModal
>(PromiserMixin, PersistentModal) {
  @Prop({ default: null }) private data!: Provider;
  @Prop() private editMode!: boolean;

  public $refs!: {
    obs: InstanceType<typeof ValidationProvider>;
  };

  private loading = {
    submit: false,
  };

  private provider: providerCreateData = {
    name: '',
    slug: '',
    doc_url: null,
  };

  created() {
    if (this.editMode) {
      this.provider = {
        name: this.data.name,
        slug: this.data.slug,
        doc_url: this.data.doc_url,
      };
    }
  }

  mounted() {
    if (this.editMode) {
      this.$nextTick(() => {
        this.$refs.obs.validate();
      });
    }
  }

  private async submitUpdate() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.connectivity.provider.update(
        this.data.slug,
        this.provider,
      );
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }

  private async submitCreate() {
    this.loading.submit = true;
    try {
      const { data } = await repositories.connectivity.provider.create(
        this.provider,
      );
      this.ok(data);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.submit = false;
  }

  private submit() {
    return this.editMode ? this.submitUpdate() : this.submitCreate();
  }
}
