import {
  ADD_CIRCUIT,
  REMOVE_CIRCUIT,
  RESET_STATE,
  SET_CIRCUITS,
  SET_LOADING,
  SET_MODAL,
} from '../mutations.type';
import {
  CLEAR_CIRCUITS_STATE,
  CLOSE_MODAL,
  DELETE_CIRCUIT,
  FETCH_CIRCUITS,
  OPEN_MODAL,
} from '../actions.type';

import { CircuitExtended } from '@/api/interfaces';
import { CircuitsState } from '../types';
import { GET_SHOW_MODAL } from '../getters.type';
import { repositories } from '@/api/ApiFactory';

const circuitsState: CircuitsState = {
  circuits: [],
  showModal: false,
  loading: false,
};

const getters = {
  /**
   * Returns state.showModal. Useful because, unlike with state, this is a
   * computed property.
   *
   * @param {CircuitsState} state
   * @returns
   */
  [GET_SHOW_MODAL](state: CircuitsState) {
    return state.showModal;
  },
};

const mutations = {
  /**
   * Stores circuits in the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitExtended[]} circuits
   */
  [SET_CIRCUITS](state: CircuitsState, circuits: CircuitExtended[]) {
    state.circuits = circuits;
  },

  /**
   * Adds a circuit in the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitExtended} circuit
   */
  [ADD_CIRCUIT](state: CircuitsState, circuit: CircuitExtended) {
    state.circuits.push(circuit);
  },

  /**
   * Removes a circuit from the state.
   *
   * @param {CircuitsState} state
   * @param {CircuitExtended} circuit
   */
  [REMOVE_CIRCUIT](state: CircuitsState, circuit: CircuitExtended) {
    state.circuits = state.circuits.filter((el) => el.id !== circuit.id);
  },

  /**
   * Resets circuits the state.
   *
   * @param {CircuitsState} state
   */
  [RESET_STATE](state: CircuitsState) {
    state.circuits = [];
    state.showModal = false;
    state.loading = false;
  },

  /**
   * Sets showModal to a given value.
   *
   * @param {CircuitsState} state
   * @param {boolean} showModal
   */
  [SET_MODAL](state: CircuitsState, showModal: boolean) {
    state.showModal = showModal;
  },

  /**
   * Sets loading to a given value.
   *
   * @param {CircuitsState} state
   * @param {boolean} value
   */
  [SET_LOADING](state: CircuitsState, value: boolean) {
    state.loading = value;
  },
};

const actions: any = {
  /**
   * Fetches circuits and stores them in the state.
   * It also controls state.loading during the fetch.
   * Throws an error if api call fails.
   *
   * @param {*} { commit }
   * @param {string} campusSlug
   */
  async [FETCH_CIRCUITS]({ commit }: any, campusSlug: string) {
    commit(SET_LOADING, true);
    try {
      const { data } = await repositories.connectivity.circuit.getCircuitsExtended(
        campusSlug,
      );
      commit(SET_CIRCUITS, data.results);
      commit(SET_LOADING, false);
    } catch (error) {
      commit(SET_CIRCUITS, []); // reset state since it could still be from the previous campus
      commit(SET_LOADING, false);
      throw error;
    }
  },

  /**
   * Deletes a circuit and removes it from the state.
   * Throws an error if api call fails.
   *
   * @param {*} { commit }
   * @param {CircuitExtended} circuit
   */
  async [DELETE_CIRCUIT]({ commit }: any, circuit: CircuitExtended) {
    await repositories.connectivity.circuit.deleteCircuit(circuit.id);
    commit(REMOVE_CIRCUIT, circuit);
  },

  /**
   * Resets circuits state.
   *
   * @param {*} { commit }
   */
  [CLEAR_CIRCUITS_STATE]({ commit }: any) {
    commit(RESET_STATE);
  },

  /**
   * Sets showModal to true.
   *
   * @param {*} { commit }
   */
  [OPEN_MODAL]({ commit }: any) {
    commit(SET_MODAL, true);
  },

  /**
   * Sets showModal to false.
   *
   * @param {*} { commit }
   */
  async [CLOSE_MODAL]({ commit }: any) {
    commit(SET_MODAL, false);
  },
};

export default {
  namespaced: true,
  state: circuitsState,
  getters,
  mutations,
  actions,
};
