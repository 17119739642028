import {
  DataOptions,
  Network,
  PaginatedResponse,
  StaticRoute,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

interface StaticRouteCreateForm {
  address: string;
  prefixlen: number;
  network: number | null;
  next_hop_ip: string;
}

interface UpdateStaticRouteForm {
  id: number;
  address?: string;
  prefixlen?: number;
  network?: number | null;
  next_hop_ip?: string;
}

const resources = { default: 'lan/static-routes' };

interface StaticRouteApiInterface {
  getStaticRoute(staticRouteId: number | string): AxiosPromise<StaticRoute>;
  getStaticRoutes(
    query?: Query | null,
    options?: DataOptions | null,
    search?: string | null,
  ): AxiosPromise<PaginatedResponse<StaticRoute>>;
  createStaticRoute(data: StaticRouteCreateForm): AxiosPromise<Network>;
  updateStaticRoute(data: UpdateStaticRouteForm): AxiosPromise<StaticRoute>;
  deleteStaticRoute(staticRouteId: number): AxiosPromise<void>;
}

const StaticRouteApi: StaticRouteApiInterface = {
  getStaticRoute(staticRouteId) {
    return axios.get(`${resources.default}/${staticRouteId}/`);
  },

  getStaticRoutes(query = null, options = null, search = null) {
    if (!query) {
      query = new Query({});
    }
    if (options) {
      if (options.itemsPerPage > 0) {
        query.addParam('size', options.itemsPerPage);
      }
      query.addParam('page', options.page);
      let sortBy = options.sortBy[0];
      if (sortBy) {
        if (options.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }

    return axios.get(`${resources.default}/${query}`);
  },

  createStaticRoute(data) {
    return axios.post(`${resources.default}/`, data);
  },

  updateStaticRoute(data) {
    return axios.patch(`${resources.default}/${data.id}/`, data);
  },

  deleteStaticRoute(staticRouteId) {
    return axios.delete(`${resources.default}/${staticRouteId}/`);
  },
};

export default StaticRouteApi;

export {
  StaticRouteApiInterface,
  StaticRouteCreateForm,
  UpdateStaticRouteForm,
};
