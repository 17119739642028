// Make sure to register hooks before importing any components
import './class-component-hooks';

import Vue from 'vue';
import './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/vue-toasted';
import './plugins/vue-cookies';
import './plugins/vue-leaflet';
import './plugins/vuex-modals';
import './plugins/vue-moment';
import './plugins/sentry';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import config from './config';
import sanitizeHTML from 'sanitize-html';

Vue.config.productionTip = false;

axios.defaults.baseURL = config.automatorApiUrl;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.prototype.$http = axios;
Vue.prototype.$config = config;
Vue.prototype.$eventBus = new Vue(); // Global event bus
Vue.prototype.$sanitize = (dirty: string) => sanitizeHTML(dirty, {
  allowedAttributes: { pre: ['class'] },  // allow this for colored code diff
});

new Vue({
  vuetify,
  router,
  store,
  render: (h: any) => h(App),
}).$mount('#app');
