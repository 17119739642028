
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import {
  Location,
  LocationType,
  Campus,
  Device,
  Address,
} from '@/api/interfaces';
import { IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import { deepClone } from '@/helpers';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

interface RoomData {
  id: number;
  name: string;
  parent: number;
  type: LocationType;
  children: Device[];
  unknown: boolean;
  customId: string;
}

interface BuildingData {
  id: number;
  name: string;
  type: LocationType;
  tooltipText: string | null;
  address: Address | null;
  children: RoomData[];
  customId: string;
}

@Component
export default class CampusDevices extends Vue {
  @userModule.Getter(IS_ADMIN_OR_ROID) public isAdminOrRoid!: boolean;
  @Prop() private campusSlug!: string;

  private campus: Campus | null = null;
  private locations: Location[] | null = null;
  private devices: Device[] | null = null;
  private locationTypeBuilding: LocationType | null = null;
  private locationTypeRoom: LocationType | null = null;
  private loading = true;
  private editMode = false;
  private open = [1, 2];
  private search: string | null = null;
  private repr = new Representations();
  private treeOpen: string[] = [];

  private typeToIconMapping = {
    building: 'office-building',
    room: 'door',
    access_point: 'access-point',
    router: 'router-wireless',
    l2_switch: 'switch',
    l3_switch: 'switch',
    empty_placeholder: 'cancel',
  };

  private async created() {
    this.loading = true;
    try {
      const respBuilding =
        await repositories.tenants.locationType.getLocationTypeByName(
          'building',
        );
      this.locationTypeBuilding = respBuilding.data;
      const respRoom =
        await repositories.tenants.locationType.getLocationTypeByName('room');
      this.locationTypeRoom = respRoom.data;
      await Promise.all([
        this.fetchCampus(this.campusSlug),
        this.fetchLocations(this.campusSlug),
        this.fetchDevices(this.campusSlug),
      ]);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
    this.loading = false;
  }

  private compareTreeElements(
    a: BuildingData | RoomData | Device,
    b: BuildingData | RoomData | Device,
  ) {
    if (a.name === 'neznan prostor') {
      return 1;
    } else if (b.name === 'neznan prostor') {
      return -1;
    }
    return a.name.localeCompare(b.name, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  get locationsWithDevices(): BuildingData[] {
    if (!this.locations || !this.devices) {
      return [];
    }
    const buildings: BuildingData[] = [];
    for (const building of this.locations.filter(
      (location) => (location.type as LocationType).name === 'building',
    )) {
      const children: RoomData[] = [];
      for (const room of building?.children || []) {
        const devices = deepClone(
          this.devices.filter((device) => device.location?.id === room.id),
        );
        for (const device of devices) {
          device.type = { name: device.function.slug };
          device.name = device.name.split('.')[0];
          device.customId = `device-${device.id}`;
        }
        const roomData: RoomData = {
          id: room.id,
          name: room.name,
          parent: building.id,
          type: this.locationTypeRoom!,
          children: devices.sort(this.compareTreeElements),
          unknown: room.name === 'neznan prostor',
          customId: `room-${room.id}`,
        };
        children.push(roomData);
      }

      // Add devices directly under building
      const buildingDevices = deepClone(
        this.devices.filter((device) => device.location?.id === building.id),
      );
      for (const device of buildingDevices) {
        device.type = { name: device.function.slug };
        device.name = device.name.split('.')[0];
        device.customId = `device-${device.id}`;
      }
      children.push(...buildingDevices);

      let tooltipText = null;
      if (building.address) {
        const addrData = this.repr.addressRepr(building.address as Address);
        tooltipText = addrData.text;
      }
      const buildingData: BuildingData = {
        id: building.id,
        name: building.name,
        type: this.locationTypeBuilding!,
        tooltipText,
        address: building.address as Address,
        children: children.sort(this.compareTreeElements),
        customId: `building-${building.id}`,
      };
      buildings.push(buildingData);
    }

    // Add devices without location
    const devicesWithoutLocation = deepClone(
      this.devices.filter((device) => device.location === null),
    );
    if (devicesWithoutLocation.length > 0) {
      const children = [];
      for (const device of devicesWithoutLocation) {
        device.type = { name: device.function.slug };
        device.name = device.name.split('.')[0];
        device.customId = `device-${device.id}`;
      }
      children.push(...devicesWithoutLocation);
      children.sort(this.compareTreeElements);

      const buildingData: BuildingData = {
        id: 0,
        name: 'Brez lokacije',
        type: { id: 0, name: 'empty_placeholder' },
        tooltipText: 'Naprave, ki niso povezane z zgradbo ali prostorom.',
        address: null,
        children,
        customId: `building-0`,
      };
      buildings.push(buildingData);
    }

    return buildings.sort(this.compareTreeElements);
  }

  private async fetchCampus(campusSlug: string): Promise<void> {
    try {
      const response = await repositories.tenants.campus.getCampusExtended(
        campusSlug,
      );
      this.campus = response.data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
  }

  private async fetchLocationWithRooms(locationId: number): Promise<Location> {
    return repositories.tenants.location
      .getLocationWithRooms(locationId)
      .then((response: any) => response.data)
      .catch((error: any) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { itemMessageText: 'lokaciji' },
          ).toString(),
        );
      });
  }

  private async fetchLocations(campusSlug: string): Promise<void> {
    try {
      const response =
        await repositories.tenants.location.getLocationsWithRooms({
          campusSlug,
        });
      this.locations = response.data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
  }

  private async fetchDevices(campusSlug: string): Promise<void> {
    try {
      const response = await repositories.infrastructure.device.getDevices({
        campusSlug,
      });
      this.devices = response.data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
  }

  private openAddRoomModal(parentId: number) {
    const parent = this.locations!.find((location) => location.id === parentId);
    const promise: Promise<Location> = this.$modals.open('app-add-room-modal', {
      component: {
        props: {
          campusSlug: this.campus!.slug,
          parent,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<Location>;
    promise.then((location: Location) => {
      if (parent) {
        parent.children!.push(location);
      } else {
        this.loading = true;
        const campusSlug = this.campus!.slug;
        this.fetchLocations(campusSlug).then(() => (this.loading = false));
      }
    });
  }

  private openAddOrEditBuildingModal(location: Location | null = null) {
    const promise: Promise<Location> = this.$modals.open(
      'app-add-or-edit-building-modal',
      {
        component: {
          props: {
            campusSlug: this.campus!.slug,
            locations: this.campus!.locations,
            location,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Location>;
    promise.then((loc: Location) => {
      if (location == null) {
        // need to fetch new building because we need to get its 'unknown' room's data
        this.fetchLocationWithRooms(loc.id).then((location: Location) => {
          this.locations!.push(location);
          this.$emit('building-added', loc);
        });
      } else {
        // find and update matching building
        for (const building of this.locations!) {
          if (building.id === location.id) {
            building.address = loc.address;
            building.coordinates = loc.coordinates;
            building.name = loc.name;
          }
        }
        this.$emit('building-changed', loc);
      }
    });
  }

  private async refetchData() {
    this.loading = true;
    const campusSlug = this.campus!.slug;
    // refresh everything, so that tree + modal content refreshes correctly
    await Promise.all([
      this.fetchCampus(campusSlug),
      this.fetchLocations(campusSlug),
      this.fetchDevices(campusSlug),
    ]);
    this.loading = false;
  }

  private openEditLocationModal(locationId: number) {
    const location: Location = this.getLocationById(locationId) as Location;
    if ((location.type as LocationType).name === 'room') {
      this.openEditRoomModal(location);
    } else {
      this.openAddOrEditBuildingModal(location);
    }
  }

  private openEditRoomModal(location: Location) {
    const promise: Promise<Location> = this.$modals.open(
      'app-room-edit-modal',
      {
        component: {
          props: {
            location: location,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Location>;
    promise.then((location: Location) => {
      // find and update matching room
      for (const building of this.locations!) {
        for (const room of building.children || []) {
          if (room.id === location.id) {
            room.name = location.name;
          }
        }
      }
    });
  }

  private openDeleteLocationModal(location: BuildingData | RoomData) {
    let repr = `zgradbo ${location.name}`;
    if ((location.type as LocationType).name === 'room') {
      const parent = this.locations!.find(
        (loc) => loc.id === (location as RoomData).parent,
      );
      repr = `prostor ${location.name} v zgradbi ${parent!.name}`;
    }
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr,
            item: location,
          },
          deleteFn: this.deleteLocation,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteLocation(modal: any, location: BuildingData | RoomData) {
    repositories.tenants.location
      .deleteLocation(location.id)
      .then(() => {
        if ((location.type as LocationType).name === 'building') {
          const matchingBuildingIdx = this.locations!.map(
            (building) => building.id,
          ).indexOf(location.id);
          this.locations!.splice(matchingBuildingIdx, 1);
        } else {
          const parent = this.locations!.find(
            (loc) => loc.id === (location as RoomData).parent,
          );
          const matchingRoomIdx = parent!
            .children!.map((room) => room.id)
            .indexOf(location.id);
          parent!.children!.splice(matchingRoomIdx, 1);
        }
        this.$modals.close();
        // this.refetchData()
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }

  private getLocationById(id: number) {
    for (const building of this.locations!) {
      if (building.id === id) {
        return building;
      }
      for (const room of building.children || []) {
        if (room.id === id) {
          return room;
        }
      }
    }
  }
}
