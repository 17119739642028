
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  CircuitExtended,
  NetboxPatchPanel,
  NetboxFrontPort,
  FrontPort,
} from '@/api/interfaces';
import {
  CircuitFormData,
  CircuitStep2Data,
} from '@/components/connectivity/circuit/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Representations } from '@/components/shared/representation';
import ErrorHandler from '@/components/shared/errorHandler';
import Autocomplete from '@/components/shared/Autocomplete.vue';

@Component({
  components: {
    Autocomplete,
  },
})
export default class CircuitAddOrEditStep2 extends Vue {
  @Prop() private formData!: CircuitFormData;
  @Prop({ default: null }) private circuit!: CircuitExtended | null;

  private repr = new Representations();
  private form: CircuitStep2Data = {
    node: {
      speedup: null,
      speeddown: null,
      patchPanelRepr: '',
      patchPanel: null,
      frontPorts: [],
    },
    campus: {
      speedup: null,
      speeddown: null,
      patchPanelRepr: '',
      patchPanel: null,
      frontPorts: [],
    },
  };
  private patchPanels: NetboxPatchPanel[] = [];
  private nodeFrontPortList: NetboxFrontPort[] = [];
  private campusFrontPortList: NetboxFrontPort[] = [];

  private predefinedSpeeds = [
    { text: '10 Mbps', value: 10000 },
    { text: '100 Mbps', value: 100000 },
    { text: '1 Gbps', value: 1000000 },
    { text: '10 Gbps', value: 10000000 },
    { text: '25 Gbps', value: 25000000 },
    { text: '40 Gbps', value: 40000000 },
    { text: '100 Gbps', value: 100000000 },
    { text: '200 Gbps', value: 200000000 },
    { text: '400 Gbps', value: 400000000 },
  ];

  private loading = {
    initial: true,
    campusFrontPorts: false,
    nodeFrontPorts: false,
  };

  private async created() {
    await this.fetchPatchPanels();
    if (this.circuit) {
      this.form = {
        node: {
          speedup: this.circuit.termination_a.speed_upstream,
          speeddown: this.circuit.termination_a.speed_downstream,
          patchPanelRepr: this.circuit.termination_a.patch_panel_repr,
          patchPanel:
            this.patchPanels.find(
              (el) =>
                el.id ===
                ((this.circuit?.termination_a.front_ports as FrontPort[])
                  .length > 0
                  ? this.circuit?.termination_a.front_ports[0]
                      .patch_panel_external_sys_id
                  : null),
            ) || null,
          frontPorts: [],
        },
        campus: {
          speedup: this.circuit.termination_z.speed_upstream,
          speeddown: this.circuit.termination_z.speed_downstream,
          patchPanelRepr: this.circuit.termination_z.patch_panel_repr,
          patchPanel:
            this.patchPanels.find(
              (el) =>
                el.id ===
                ((this.circuit?.termination_z.front_ports as FrontPort[])
                  .length > 0
                  ? this.circuit?.termination_z.front_ports[0]
                      .patch_panel_external_sys_id
                  : null),
            ) || null,
          frontPorts: [],
        },
      };
      // need to fetch front ports before setting them in the form
      await this.fetchFrontPorts('node');
      await this.fetchFrontPorts('campus');
      this.form.node.frontPorts = this.nodeFrontPortList.filter((el) =>
        this.circuit?.termination_a.front_ports
          .map((el) => el.external_sys_id)
          .includes(el.id),
      );
      this.form.campus.frontPorts = this.campusFrontPortList.filter((el) =>
        this.circuit?.termination_z.front_ports
          .map((el) => el.external_sys_id)
          .includes(el.id),
      );
    }
  }

  private async fetchPatchPanels() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.connectivity.circuit.getPatchPanels();
      this.patchPanels = data.results;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'delilnikih' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  private async fetchFrontPorts(_type: 'campus' | 'node') {
    let patchPanelId = this.form.node.patchPanel?.id;
    if (_type === 'campus') {
      patchPanelId = this.form.campus.patchPanel?.id;
      this.loading.campusFrontPorts = true;
    } else {
      this.loading.nodeFrontPorts = true;
    }
    if (patchPanelId == null) {
      // reset frontports to [] because patch panel has just been cleared
      if (_type === 'node') {
        this.form.node.frontPorts = [];
        this.loading.nodeFrontPorts = false;
      } else {
        this.form.campus.frontPorts = [];
        this.loading.campusFrontPorts = false;
      }
      return;
    }
    try {
      const { data } = await repositories.connectivity.circuit.getFrontPorts(
        patchPanelId as number,
      );
      if (_type === 'node') {
        this.nodeFrontPortList = data.results;
      } else {
        this.campusFrontPortList = data.results;
      }
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'priključkih' },
        ).toString(),
      );
    }
    if (_type === 'campus') {
      this.loading.campusFrontPorts = false;
    } else {
      this.loading.nodeFrontPorts = false;
    }
  }

  private setSpeed(
    _type: 'node' | 'campus',
    direction: 'up' | 'down',
    newSpeed: number,
  ) {
    let obj = this.form.node;
    if (_type === 'campus') {
      obj = this.form.campus;
    }
    if (direction === 'up') {
      obj.speedup = newSpeed;
    } else {
      obj.speeddown = newSpeed;
    }
  }

  private sendData() {
    this.$emit('next', this.form);
  }
}
