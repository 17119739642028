import AddAttachmentModal from '../components/shared/modals/AddAttachmentModal.vue';
import AddOrEditBuildingModal from '../components/campus/AddOrEditBuildingModal.vue';
import AddOrEditProviderModal from '../components/connectivity/provider/AddOrEditProviderModal.vue';
import AddOrEditTechnologyModal from '../components/connectivity/technology/AddOrEditTechnologyModal.vue';
import AddOrganizationModal from '../components/campus/AddOrganizationModal.vue';
import AddOrganizationToCampusModal from '../components/campus/AddOrganizationToCampusModal.vue';
import AddRichTextDocModal from '../components/shared/modals/AddRichTextDocModal.vue';
import AddRoomModal from '../components/campus/AddRoomModal.vue';
import AssignCampusManagementNetworkModal from '../components/shared/modals/AssignCampusManagementNetworkModal.vue';
import AssignWLCHelpModal from '../components/shared/modals/AssignWLCHelpModal.vue';
import AssignZoneGroupsModal from '../components/shared/modals/AssignZoneGroupsModal.vue';
import CampusEditModal from '../components/campus/CampusEditModal.vue';
import CampusManagementNetworkHelpModal from '../components/shared/modals/CampusManagementNetworkHelpModal.vue';
import ConfirmDeviceDeleteModal from '../components/device/ConfirmDeviceDeleteModal.vue';
import ConfirmDeviceDisconnectInvModal from '../components/device/ConfirmDeviceDisconnectInvModal.vue';
import ConfirmModuleDeleteModal from '../components/device/ConfirmModuleDeleteModal.vue';
import CreateTableModal from '../components/shared/modals/CreateTableModal.vue';
import DHCPServerModal from '../components/campus/DHCPServerModal.vue';
import DNSResolverModal from '../components/campus/DNSResolverModal.vue';
import DeleteConfirmModal from '../components/shared/modals/DeleteConfirmModal.vue';
import DeliveryConfirmedModal from '../components/shared/modals/DeliveryConfirmedModal.vue';
import DeviceEditModal from '../components/device/DeviceEditModal.vue';
import DeviceMoveModal from '../components/device/DeviceMoveModal.vue';
import DeviceReplaceModal from '../components/device/DeviceReplaceModal.vue';
import DevicesChangeStatusModal from '../components/device/DevicesChangeStatusModal.vue';
import DocEditorLinkModal from '../components/shared/modals/DocEditorLinkModal.vue';
import DocumentationHelpModal from '../components/shared/modals/DocumentationHelpModal.vue';
import EditGatewaysModal from '../components/network/EditGatewaysModal.vue';
import ManagementNetworkModal from '../components/managementNetwork/ManagementNetworkModal.vue';
import Modals from 'vuex-modals';
import NetworkEditRoidModal from '../components/network/NetworkEditRoidModal.vue';
import NetworkModal from '../components/network/NetworkModal.vue';
import SubnetDetailModal from '../components/network/SubnetDetailModal.vue';
import NetworkTopologyHelpModal from '../components/campus/networkTopology/HelpModal.vue';
import OrganizationHelpModal from '../components/shared/modals/OrganizationHelpModal.vue';
import PickImageModal from '../components/shared/modals/PickImageModal.vue';
import PickZonesModal from '../views/campus/PickZonesModal.vue';
import RenameRichTextDocModal from '../components/shared/modals/RenameRichTextDocModal.vue';
import RoomEditModal from '../components/campus/RoomEditModal.vue';
import StaticRouteModal from '../components/staticRoutes/StaticRouteModal.vue';
import TaskErrorDetailsModal from '../components/tasks/TaskErrorDetailsModal.vue';
import TypeConfirmModal from '../components/shared/modals/TypeConfirmModal.vue';
import TextInputModal from '../components/shared/modals/TextInputModal.vue';
import Vue from 'vue';
import WifiNetworkModal from '../components/network/WifiNetworkModal.vue';
import ZoneEditModal from '../components/campus/ZoneEditModal.vue';
import ZoneHelpModal from '../components/shared/modals/ZoneHelpModal.vue';
import store from '@/store/store';

Vue.component('app-confirm-delete', DeleteConfirmModal);
Vue.component('app-type-confirm-modal', TypeConfirmModal);
Vue.component('app-text-input-modal', TextInputModal);
Vue.component('app-delivery-confirmed-modal', DeliveryConfirmedModal);
Vue.component('app-network-modal', NetworkModal);
Vue.component('app-subnet-detail-modal', SubnetDetailModal);
Vue.component('app-network-edit-roid-modal', NetworkEditRoidModal);
Vue.component('app-wifi-network-modal', WifiNetworkModal);
Vue.component('app-edit-gateways-modal', EditGatewaysModal);
Vue.component('app-dhcp-server-modal', DHCPServerModal);
Vue.component('app-dns-resolver-modal', DNSResolverModal);
Vue.component('app-add-organization-modal', AddOrganizationModal);
Vue.component(
  'app-add-organization-to-campus-modal',
  AddOrganizationToCampusModal,
);
Vue.component('app-campus-edit-modal', CampusEditModal);
Vue.component('app-add-room-modal', AddRoomModal);
Vue.component('app-add-or-edit-building-modal', AddOrEditBuildingModal);
Vue.component('app-room-edit-modal', RoomEditModal);
Vue.component('app-static-route-modal', StaticRouteModal);
Vue.component('app-zone-edit-modal', ZoneEditModal);
Vue.component('app-pick-zones-modal', PickZonesModal);
Vue.component('app-management-network-modal', ManagementNetworkModal);
Vue.component('app-device-replace-modal', DeviceReplaceModal);
Vue.component('app-device-edit-modal', DeviceEditModal);
Vue.component('app-device-move-modal', DeviceMoveModal);
Vue.component('app-devices-change-status-modal', DevicesChangeStatusModal);
Vue.component('app-confirm-device-delete-modal', ConfirmDeviceDeleteModal);
Vue.component(
  'app-confirm-device-disconnect-inv-modal',
  ConfirmDeviceDisconnectInvModal,
);
Vue.component('app-confirm-module-delete-modal', ConfirmModuleDeleteModal);
Vue.component('app-pick-image-modal', PickImageModal);
Vue.component('app-add-attachment-modal', AddAttachmentModal);
Vue.component('app-add-richtextdoc-modal', AddRichTextDocModal);
Vue.component('app-rename-richtextdoc-modal', RenameRichTextDocModal);
Vue.component('app-create-table-modal', CreateTableModal);
Vue.component('app-doceditor-link-modal', DocEditorLinkModal);
Vue.component('task-error-details-modal', TaskErrorDetailsModal);
Vue.component('app-assignwlc-help-modal', AssignWLCHelpModal);
Vue.component('app-organization-help-modal', OrganizationHelpModal);
Vue.component('app-add-edit-provider-modal', AddOrEditProviderModal);
Vue.component('app-add-edit-technology-modal', AddOrEditTechnologyModal);
Vue.component(
  'app-assign-campus-management-network-modal',
  AssignCampusManagementNetworkModal,
);
Vue.component('app-zone-help-modal', ZoneHelpModal);
Vue.component(
  'app-campus-management-network-help-modal',
  CampusManagementNetworkHelpModal,
);
Vue.component('app-assign-zonegroups-modal', AssignZoneGroupsModal);
Vue.component('app-documentation-help-modal', DocumentationHelpModal);
Vue.component('app-network-topology-help-modal', NetworkTopologyHelpModal);

Vue.use(Modals, { store });
