
import { Component, Prop } from 'vue-property-decorator';
import { AVAILABLE_NETBOX_ASSET_STATUSES } from '../shared/constants';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { DeviceModule } from '@/api/interfaces';

@Component
export default class ConfirmDeviceDeleteModal extends PersistentModal {
  @Prop() private data!: {
    item: {
      id: number;
      value: boolean;
      name: string;
      managed: string;
      status: string;
      primary_address: string;
      function: string;
      functionSlug: string;
      roles: string;
      base_mac: string;
      product: string;
      serial_number: string;
      build: string;
      room: string;
      unknownRoom: string;
      inventory_sys_id: number;
      modules: DeviceModule[];
      sortable: boolean;
    };
    repr: string;
    form?: { assetStatus: string | null };
  };
  @Prop() private deleteFn!: (modal: any, item: any) => void;
  private deleting = false;
  private availableAssetStatuses = AVAILABLE_NETBOX_ASSET_STATUSES;
  private form: { assetStatus: string | null } = {
    assetStatus: null,
  };
  private confirmText = '';
  private requiredConfirmText = '';

  private get items() {
    let products = [];
    if (this.data.item.inventory_sys_id !== null) {
      products.push({
        product: this.data.item.product,
        serial_number: this.data.item.product,
        type: 'Naprava',
      });
    }
    for (let module of this.data.item.modules) {
      if (module.asset.inventory_sys_id !== null) {
        products.push({
          product: module.asset.product?.name,
          serial_number: module.asset.serial_number,
          type: 'Modul',
        });
      }
    }
    return products;
  }

  private get showSelectAssetStatus() {
    return this.items.length > 0;
  }

  private created() {
    this.requiredConfirmText = this.data.item.name.split('.')[0];
  }

  private deleteItem() {
    this.deleting = true;
    this.data.form = this.form;
    this.deleteFn(this, this.data);
  }
}
