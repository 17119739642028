import { PaginatedResponse, Provider } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'connectivity/providers',
};

type providerCreateData = {
  name: string;
  slug: string;
  doc_url: string | null;
};

interface ProviderApiInterface {
  getProviders: (query?: Query) => AxiosPromise<PaginatedResponse<Provider>>;
  create: (data: providerCreateData) => AxiosPromise<Provider>;
  update: (slug: string, data: providerCreateData) => AxiosPromise<Provider>;
  delete: (slug: string) => AxiosPromise<void>;
}

const providerApi: ProviderApiInterface = {
  getProviders(query = new Query()) {
    return axios.get(`${resources.default}/${query}`);
  },
  create(data: providerCreateData) {
    return axios.post(`${resources.default}/`, data);
  },
  update(slug, data: providerCreateData) {
    return axios.patch(`${resources.default}/${slug}/`, data);
  },
  delete(slug) {
    return axios.delete(`${resources.default}/${slug}/`);
  },
};

export default providerApi;

export { ProviderApiInterface, providerCreateData };
