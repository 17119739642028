
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddAsset from '../../components/device/AddAsset.vue';

import AddDevice from '../../components/device/AddDevice.vue';
import { DeviceAddForm } from '../../components/device/interfaces';

import { Asset, NetboxAsset, User, Zone } from '@/api/interfaces';
import _get from 'lodash.get';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { USER } from '@/store/states.type';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { deepClone } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);

export interface CDevice {
  asset: NetboxAsset | Asset | null;
  data: DeviceAddForm | null;
}

@Component({
  components: {
    AddAsset,
    AddDevice,
  },
})
export default class CreateDevice extends Vue {
  public $refs!: {
    step1Component: AddAsset;
    step2Component: AddDevice;
    obsStep2: any;
  };
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.State(USER) public user!: User;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @Prop({ default: undefined, type: String }) private campusSlug!:
    | string
    | undefined;
  @Prop({ default: undefined, type: String }) private siteSlug!:
    | string
    | undefined;
  @Prop({ default: false }) private cpeOnly!: boolean;

  private deepCloneFn = deepClone;
  private currentStep = 1;
  // addAssetKey is used just so that netbox-asset-list refreshes when you add
  // more than 1 device.
  private addAssetKey = 1;
  private loading = false;

  private device: CDevice = {
    asset: null,
    data: null,
  };

  private nextStep() {
    this.currentStep++;
  }

  private addAnotherDevice() {
    this.addAssetKey++;
    this.$refs.step2Component.initForm();
    this.$refs.obsStep2.reset();
    this.device = {
      asset: null,
      data: null,
    };
    this.currentStep = 1;
  }

  private getTransformedCommonFormData(form: any) {
    let assetData: any;
    if (form.asset.id) {
      // we have NetboxAsset, pass only its ID
      assetData = form.asset.id;
    } else {
      let productData: any;
      if (form.asset.product.id) {
        productData = form.asset.product.id;
      } else {
        productData = form.asset.product;
        productData.manufacturer = productData.manufacturer.name;
        productData.type = productData.type.name;
      }
      assetData = form.asset;
      assetData.product = productData;
    }
    const data: any = {
      name: form.data.name,
      managed: form.data.managed,
      roles: form.data.role.value,
      asset: assetData,
      function: form.data.function,
      status: form.data.status,
      description: form.data.description,
    };
    if (form.data.primary_address) {
      data.primary_address = form.data.primary_address;
    }
    if (form.data.primary_address6) {
      data.primary_address6 = form.data.primary_address6;
    }
    return data;
  }

  private getTransformedFormDataForApi() {
    const form: any = deepClone(this.device);
    const data: any = this.getTransformedCommonFormData(form);
    if (data.roles.includes('tsp') || data.roles.includes('backbone')) {
      // add site
      data.site = form.data.site!.slug;
    } else {
      // cpe OR lan OR cpe/lan
      data.campus_slug = this.campusSlug;
      data.zones = [];
      if (form.data.zones !== null) {
        data.zones = form.data.zones.map((zone: Zone) => zone.id);
      }
    }
    data.location = form.data.location_room
      ? form.data.location_room.id
      : form.data.location
      ? form.data.location!.id
      : null;
    if (!this.isAdmin) {
      delete data.managed;
      delete data.name;
      delete data.zones;
      delete data.status;
    }
    return data;
  }

  private async submit() {
    this.loading = true;
    try {
      const transformedFormData = this.getTransformedFormDataForApi();
      const { data } = await repositories.infrastructure.device.createDevice(
        transformedFormData,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.nextStep();
      if (!this.isAdmin) {
        // let the roid know which device name has been selected
        this.$toasted.info(`Naprava ${data.name} je bila uspešno kreirana.`);
      }
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler({ error, status: true }).toString(),
        {
          icon: 'mdi-error',
        },
      );
    }
    this.loading = false;
  }

  private handleStep1Submit() {
    this.device.asset = this.$refs.step1Component.getAssetData();
    this.nextStep();
  }

  private handleStep2Submit() {
    this.device.data = this.$refs.step2Component.getDeviceData();
    this.submit();
  }
}
