
import { Component, Prop } from 'vue-property-decorator';
import { Subnet } from '@/api/interfaces';
import PersistentModal from '@/components/shared/modals/PersistentModal';
import { IPv4CidrRange, IPv6CidrRange } from "ip-num/IPRange";
import { IPv4, IPv6 } from "ip-num/IPNumber";

@Component
export default class SubnetDetailModal extends PersistentModal {
  @Prop() private subnet!: Subnet;
  private data: {
    networkIP: string;
    firstUsefulIP: string;
    lastUsefulIP: string;
    nrUsefulIPs: string;
    mask: string | null;
    inverseMask: string | null;
  } | null = null;

  created() {
    const fnMapper = {
        4: {
            fromCidr: IPv4CidrRange.fromCidr,
            getLastIP: (obj: any) => obj.getPrefix().toString() === '31' ? obj.getLast() : obj.getLast().previousIPNumber(),
            nrUsefulIPs: (obj: any) => BigInt(obj.getSize()) - 2n + (obj.getPrefix().toString() == '31' ? 2n : 0n) - (this.subnet.gateway ? 1n : 0n),
            constructor: (ip: string) => new IPv4(ip),
        },
        6: {
            fromCidr: IPv6CidrRange.fromCidr,
            getLastIP: (obj: any) => obj.getLast(),
            nrUsefulIPs: (obj: any) => BigInt(obj.getSize()) - 1n + (obj.getPrefix().toString() == '127' ? 1n : 0n) - (this.subnet.gateway ? 1n : 0n),
            constructor: (ip: string) => new IPv6(ip),
        }
    }
    const mappedVersion = fnMapper[this.subnet.version as 4 | 6]
    const rangeObj = mappedVersion.fromCidr(this.subnet.cidr);
    const rangeObjClone = mappedVersion.fromCidr(this.subnet.cidr);
    const isLastSubnet = ['127', '31'].includes(rangeObj.getPrefix().toString())
    let firstUsefulIP = isLastSubnet ? rangeObj.getFirst() : rangeObj.getFirst().nextIPNumber();
    if (this.subnet.gateway && firstUsefulIP.isEquals(mappedVersion.constructor(this.subnet.gateway))) {
        firstUsefulIP = firstUsefulIP.nextIPNumber();
    }
    this.data = {
        networkIP: rangeObj.getFirst().toString(),
        firstUsefulIP: firstUsefulIP.toString(),
        lastUsefulIP: mappedVersion.getLastIP(rangeObjClone).toString(),
        nrUsefulIPs: mappedVersion.nrUsefulIPs(rangeObj).toLocaleString('sl-si'),
        mask: this.subnet.version === 4 ? rangeObj.cidrPrefix.toMask().toString() : null,
        inverseMask: this.subnet.version === 4 ? this.getInverseMask(rangeObj.cidrPrefix.toMask().toString()) : null,
    }
  }

  private getInverseMask(mask: string): string {
    return mask.split('.').map((el) => (255 - Number(el)) + '').join('.');
  }
}
