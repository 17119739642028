
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { DataOptions, Device } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { sloveneStatusNames } from '@/utils/constants';
import { ModifyInterfacesComponentMode } from '@/components/shared/helpers';
import { deepClone, isTSP, isBackbone } from '@/helpers';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';
import CampusHeader from '@/components/campus/CampusHeader.vue';
import translateWord from '@/components/shared/translations';
import debounce from 'lodash.debounce';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class LatestDevices extends Vue {
  public search = '';
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;

  private sloveneStatusNamesRef = sloveneStatusNames;
  private ModifyInterfacesComponentMode = ModifyInterfacesComponentMode;
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private supportedMIFunctions = ['l2_switch', 'l3_switch', 'router'];
  private itemsPerPageOptions = [50, 200, 500];
  private options: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['created'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 50,
    multiSort: false,
    mustSort: false,
  };
  private isTSP = isTSP;
  private isBackbone = isBackbone;

  private devices: Device[] = [];
  private totalDevices = 0;
  private loading = {
    initial: true,
  };

  private campusFilter: boolean | null = null;
  private rolesFilter: string[] = [];
  private functionsFilter: string[] = [];
  private statusFilter: string | null = null;

  private headers = [
    { text: 'Ime', value: 'name', align: 'left', sortable: false },
    { text: 'Del skupine', value: 'group', align: 'left', sortable: false },
    { text: 'IP naslov', value: 'primary_address.address', align: 'left', sortable: false },
    { text: 'Serijska', value: 'asset.serial_number', align: 'left', sortable: false },
    { text: 'Status', value: 'status', align: 'left', sortable: false },
    { text: 'Upravljana', value: 'managed', align: 'left', sortable: false },
    { text: 'Funkcija', value: 'function.name', align: 'left', sortable: false },
    { text: 'Vloge', value: 'roles', align: 'left', sortable: false },
    {
      text: 'Model',
      value: 'asset.product.name',
      align: 'left',
      sortable: false,
    },
    { text: 'MAC', value: 'asset.base_mac', align: 'left', sortable: false },
    { text: 'Ustvarjeno', value: 'created', align: 'left', sortable: false },
  ];

  private campusFilters = [
    { text: 'Da', value: true, },
    { text: 'Ne', value: false, },
  ];

  private rolesFilters = [
    { text: 'CPE', value: 'cpe', },
    { text: 'LAN', value: 'lan', },
    { text: 'TSP', value: 'tsp', },
    { text: 'Backbone', value: 'backbone', },
  ];

  private functionsFilters = [
    { text: 'Access point', value: 'access_point', },
    { text: 'L2 switch', value: 'l2_switch', },
    { text: 'L3 switch', value: 'l3_switch', },
    { text: 'Router', value: 'router', },
    { text: 'WLC', value: 'wlc', },
    { text: 'Neznano', value: 'unknown', },
    { text: 'Ostalo', value: 'other', },
  ];

  private statusFilters = [
    { text: translateWord('active'), value: 'active', },
    { text: translateWord('planned'), value: 'planned', },
    { text: translateWord('discovered'), value: 'discovered', },
    { text: translateWord('maintenance'), value: 'maintenance', },
    { text: translateWord('retired'), value: 'retired', },
  ];

  private created() {
    this.setVarsBasedOnQueryParams();
    this.fetchLatestDevices();
  }

  private setVarsBasedOnQueryParams(): void {
    const query = this.$route.query;
    if (query.in_campus) {
      this.campusFilter = (query.in_campus as string).toLowerCase() === 'true';
    }
    if (query.roles) {
      this.rolesFilter = (query.roles as string).split(',');
    }
    if (query.functions) {
      this.functionsFilter = (query.functions as string).split(',');
    }
    if (query.status) {
      this.statusFilter = query.status as string;
    }
    if (query.search) {
      this.search = query.search as string;
    }
  }

  private updateUrlQueryParams(): void {
    const query = new Query({});
    if (this.campusFilter !== null) {
      query.addParam('in_campus', this.campusFilter);
    }
    if (this.rolesFilter.length > 0) {
      query.addParam('roles', this.rolesFilter.join(','));
    }
    if (this.functionsFilter.length > 0) {
      query.addParam('functions', this.functionsFilter.join(','));
    }
    if (this.statusFilter !== null) {
      query.addParam('status', this.statusFilter);
    }
    if (this.search) {
      query.addParam('search', this.search);
    }
    history.replaceState({}, 'latest-device-list filter', `${this.$route.path}${query}`);
  }

  private async fetchLatestDevices(): Promise<void> {
    this.loading.initial = true;
    try {
      const params: { [key: string]: any } = {
        pagination: this.options,
        roles: this.rolesFilter,
        functions: this.functionsFilter,
      };
      if (this.campusFilter !== null) {
        params.hasZone = this.campusFilter;
      }
      if (this.statusFilter !== null) {
        params._status = this.statusFilter;
      }
      let normalizedSearch = this.search;
      const normalizedMacSearch = normalizedSearch
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll(':', '')
      .toUpperCase();
      const mac_re = /^([A-F0-9]{12})$/;
      // device names might have dots and dashes, so we only strip them if search looks like a mac
      if (normalizedSearch && normalizedMacSearch.length === 12 && mac_re.test(normalizedMacSearch)) {
        normalizedSearch = normalizedMacSearch;
      }
      // change url, so that it includes filter info
      this.updateUrlQueryParams();
      const { data } = await repositories.infrastructure.device.getDevices(
        params, normalizedSearch
      );
      this.devices = data.results;
      this.totalDevices = data.count;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchLatestDevices();
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchLatestDevices();
  }
}
