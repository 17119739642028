
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import {
  StaticRoute,
  DataOptions,
  Network,
  GatewayInterface,
} from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';
import { DataTableHeader } from 'vuetify';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {},
})
export default class StaticRoutesList extends Vue {
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  private items: StaticRoute[] = [];
  private loading = { routes: false };
  private search = '';
  private totalItems = 0;

  private rowsPerPageItems = [50, 200, 500];
  private headers: DataTableHeader[] = [
    {
      text: 'Podomrežje',
      value: 'address',
    },
    {
      text: 'IP prehoda',
      value: 'next_hop_ip',
    },
    {
      text: 'V omrežje',
      value: 'network',
    },
    {
      text: 'Vmesniki prehoda',
      value: 'interfaces',
      sortable: false,
    },
  ];
  private options: DataOptions | null = {
    page: 1,
    itemsPerPage: 50,
    sortBy: ['address'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
    mustSort: false,
    multiSort: false,
  };

  private parseGateways(network?: Network): {
    [key: string]: GatewayInterface[];
  } {
    if (!network) return {};

    let devices: { [key: string]: GatewayInterface[] } = {}; // add index signature

    network.gateway_interfaces?.forEach((gw) => {
      if (gw.device.name in devices) {
        devices[gw.device.name].push(gw);
      } else {
        devices[gw.device.name] = [gw];
      }
    });
    return devices;
  }

  private async fetchStaticRoutes(
    campusSlug: string,
    options?: DataOptions | null,
    search?: string,
  ) {
    this.loading.routes = true;
    try {
      const { data } = await repositories.lan.staticRoute.getStaticRoutes(
        new Query({ campus: campusSlug }),
        options,
        search,
      );
      this.items = data.results;
      this.totalItems = data.count;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'statičnih usmerjevalnih poti' },
        ).toString(),
      );
    } finally {
      this.loading.routes = false;
    }
  }

  public refreshData() {
    this.fetchStaticRoutes(this.campusSlug, this.options, this.search);
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchStaticRoutes(this.campusSlug, this.options);
  }

  private deleteStaticRoute(modal: any, staticRoute: StaticRoute) {
    repositories.lan.staticRoute
      .deleteStaticRoute(staticRoute.id)
      .then(() => {
        this.refreshData();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message:
                'Med brisanjem statične usmerjevalne poti je prišlo do napake',
            },
          ).toString(),
        );
        modal.deleting = false;
      });
  }

  private created() {
    if (this.isAdmin) {
      this.headers.push({
        text: 'Akcije',
        value: 'actions',
        align: 'center',
        width: '100px',
        sortable: false,
      });
    }
  }

  private showStaticRouteModal(staticRoute?: StaticRoute) {
    const promise: Promise<StaticRoute> = this.$modals.open(
      'app-static-route-modal',
      {
        component: {
          props: {
            campusSlug: this.campusSlug,
            staticRoute: staticRoute,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<StaticRoute>;
    promise.then((sRoute: StaticRoute) => {
      this.refreshData();
    });
  }

  private showDeleteModal(staticRoute: StaticRoute) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `statično usmerjevalno pot za ${staticRoute.address}/${staticRoute.prefixlen}`,
            item: staticRoute,
          },
          deleteFn: this.deleteStaticRoute,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }
}
