
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ROID } from '@/store/getters.type';
import { USER } from '@/store/states.type';
import Autocomplete from '../shared/Autocomplete.vue';
import { Representations } from '../shared/representation';
import {
  Asset,
  NetboxAsset,
  Location,
  Product,
  User,
  Zone,
} from '@/api/interfaces';
import { DeviceAddForm } from './interfaces';
import { repositories } from '@/api/ApiFactory';
import { Query } from '@/api/query';
import ErrorHandler from '@/components/shared/errorHandler';

import { ValidationProvider } from 'vee-validate';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    Autocomplete,
  },
})
export default class AddDevice extends Vue {
  public $refs!: {
    building: HTMLFormElement;
    room: HTMLFormElement;
    zone: HTMLFormElement;
    buildingVP: InstanceType<typeof ValidationProvider>;
    roomVP: InstanceType<typeof ValidationProvider>;
    zoneVP: InstanceType<typeof ValidationProvider>;
  };

  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ROID) private isRoid!: boolean;
  @userModule.State(USER) public user!: User;
  @Prop({ default: undefined }) private campusSlug!: string;
  @Prop({ default: undefined }) private siteSlug!: string;
  @Prop({ default: null }) private asset!: NetboxAsset | Asset | null;
  @Prop({ default: false }) private cpeOnly!: boolean;
  private repr = new Representations();
  private searchLocationsApi = repositories.tenants.location.searchLocations;
  private search = '';
  private searchTsp = '';
  private searchVlan = '';
  private descriptionLimit = 60;
  private entries = [];
  private isLoading = false;

  private roles: any[] = [
    { text: 'LAN', matchValue: 'lan', value: ['lan'], adminOnly: false },
    { text: 'CPE', matchValue: 'cpe', value: ['cpe'], adminOnly: true },
    {
      text: 'LAN in CPE',
      matchValue: 'lanCpe',
      value: ['lan', 'cpe'],
      adminOnly: true,
    },
    { text: 'TSP', matchValue: 'tsp', value: ['tsp'], adminOnly: true },
    {
      text: 'Backbone',
      matchValue: 'bb',
      value: ['backbone'],
      adminOnly: true,
    },
  ];
  private product: Product | null = null;
  private zones: Zone[] = [];
  private locations: Location[] = [];
  private statusChoices = [
    { text: 'Planirana', value: 'planned' },
    { text: 'Aktivna', value: 'active' },
  ];
  private typeChoices = [
    { text: 'Router', value: 'router' },
    { text: 'L3 switch', value: 'l3_switch' },
    { text: 'L2 switch', value: 'l2_switch' },
    { text: 'Dostopovna točka', value: 'access_point' },
  ];

  private device: DeviceAddForm = {
    role: null,
    function: '',
    managed: !this.cpeOnly,
    campus: null,
    site: null,
    zones: null,
    name: '',
    primary_address: null,
    primary_address6: null,
    location: null,
    location_room: null,
    status: '',
    description: '',
  };

  private siteSearch = repositories.connectivity.site.searchSitesSimple;
  // Campus and location api
  private campusSearchApi = repositories.tenants.campus.searchCampuses;

  // parent component can get form data through this function
  public getDeviceData() {
    return this.device;
  }
  public initForm() {
    this.device = {
      role: null,
      function: '',
      managed: !this.cpeOnly,
      campus: null,
      site: null,
      zones: null,
      name: '',
      primary_address: null,
      primary_address6: null,
      location: null,
      location_room: null,
      status: '',
      description: '',
    };

    if (this.campusSlug) {
      this.fetchCampus(this.campusSlug);
    }
    if (this.siteSlug) {
      this.fetchSite(this.siteSlug);
    }
    if (this.isRoid) {
      this.device.status = 'planned';
    }
  }

  private get rooms(): Location[] | null {
    if (this.device.location && this.device.location.children) {
      return this.device.location.children;
    }
    return [];
  }

  private getFilteredTypeChoices(include: string[] | null = null) {
    const allTypeChoices = [
      { text: 'Router', value: 'router' },
      { text: 'L3 switch', value: 'l3_switch' },
      { text: 'L2 switch', value: 'l2_switch' },
      { text: 'Dostopovna točka', value: 'access_point' },
    ];
    if (include === null) {
      include = allTypeChoices.map((el) => el.value);
    }
    return allTypeChoices.filter((el) => include!.includes(el.value));
  }

  private getFilteredRoleChoices(include: string[] | null = null) {
    let allRoleChoices = [
      { text: 'LAN', matchValue: 'lan', value: ['lan'], adminOnly: false },
      { text: 'CPE', matchValue: 'cpe', value: ['cpe'], adminOnly: true },
      {
        text: 'LAN in CPE',
        matchValue: 'lanCpe',
        value: ['lan', 'cpe'],
        adminOnly: true,
      },
      { text: 'TSP', matchValue: 'tsp', value: ['tsp'], adminOnly: true },
      {
        text: 'Backbone',
        matchValue: 'bb',
        value: ['backbone'],
        adminOnly: true,
      },
    ];
    allRoleChoices = allRoleChoices.filter((role) => {
      return role.adminOnly ? this.user.groups.includes('admin') : true;
    });
    if (include === null) {
      include = allRoleChoices.map((el) => el.matchValue);
    }
    return allRoleChoices.filter((el) => include!.includes(el.matchValue));
  }

  @Watch('device.campus')
  private onCampusChange(): void {
    this.zones = [];
    if (this.device.campus) {
      this.fetchZones(this.device.campus.slug);
      this.fetchLocationsWithRooms(this.device.campus.slug);
    }
    // reset data
    this.$refs.building.clearModel();
    this.$refs.room.clearModel();
    // reset validation because clearModel triggers an event which is on the event queue
    setTimeout(() => {
      this.$refs.buildingVP.reset();
      this.$refs.roomVP.reset();
      this.$refs.zoneVP.reset();
    }, 0);
  }

  private async fetchProduct(): Promise<Product> {
    if (this.asset === null) {
      return Promise.resolve({} as Product);
    }
    if ('inventory_sys_id' in this.asset) {
      return Promise.resolve(this.asset.product!);
    }
    return repositories.infrastructure.product
      .getProducts(
        null,
        undefined,
        new Query({ name: this.asset.device_type!.model }),
      )
      .then((data) => data.data.results[0]);
  }

  @Watch('asset', { deep: true })
  private onAssetChange(): void {
    if (!this.asset) {
      this.typeChoices = this.getFilteredTypeChoices();
      this.roles = this.getFilteredRoleChoices();
      // reset managed
      this.device.managed = true;
      // CPE only devices are not managed
      if (this.cpeOnly) {
        this.device.managed = false;
      }
      return;
    }
    if (
      'inventory_sys_id' in this.asset &&
      this.asset.inventory_sys_id === null &&
      this.asset.product === null
    ) {
      // deleted input in model search (not netbox search but custom device model search)
      return;
    }
    const promise = this.fetchProduct();
    promise
      .then((product: Product) => {
        // store product so that we know its managed value
        this.product = product;
        // set managed based on product's managed
        this.device.managed = product.managed;
        // CPE only devices are not managed
        if (this.cpeOnly) {
          this.device.managed = false;
        }
        const productType = product.type.name;
        if (productType === 'access point') {
          this.typeChoices = this.getFilteredTypeChoices(['access_point']);
          this.roles = this.getFilteredRoleChoices(['lan']);
        } else if (productType === 'router') {
          this.typeChoices = this.getFilteredTypeChoices(['router']);
          if (this.isAdmin) {
            if (this.campusSlug) {
              this.roles = this.getFilteredRoleChoices(['cpe', 'lanCpe']);
            } else if (this.siteSlug) {
              this.roles = this.getFilteredRoleChoices(['tsp', 'bb']);
            }
            if (this.cpeOnly) {
              this.roles = this.getFilteredRoleChoices(['cpe']);
            }
          } else {
            this.roles = this.getFilteredRoleChoices(['lan']);
          }
        } else {
          // switch
          let choices = ['l2_switch'];
          let roles = ['lan'];
          if (this.isAdmin) {
            if (this.campusSlug) {
              choices = ['l2_switch', 'l3_switch'];
              roles = ['lan', 'cpe', 'lanCpe'];
            } else if (this.siteSlug) {
              choices = ['l3_switch'];
              roles = ['tsp', 'bb'];
            } else if (this.cpeOnly) {
              choices = ['l3_switch'];
              roles = ['cpe'];
            }
          }
          this.roles = this.getFilteredRoleChoices(roles);
          this.typeChoices = this.getFilteredTypeChoices(choices);
        }
        // remove old selected values if it's not in the list anymore --> gui
        // doesn't show it as "selected" but behind the scenes it is
        if (
          this.device.function &&
          !this.typeChoices.map((el) => el.value).includes(this.device.function)
        ) {
          this.device.function = '';
        }
        if (
          this.device.role &&
          !this.roles
            .map((el) => el.matchValue)
            .includes(this.device.role.matchValue)
        ) {
          this.device.role = null;
        }
        // pre-select if only one choice
        if (this.typeChoices.length === 1) {
          this.device.function = this.typeChoices[0].value;
        }
        if (this.roles.length === 1) {
          this.device.role = this.roles[0];
        }
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { itemMessageText: 'strojni opremi' },
          ).toString(),
        );
      });
  }

  private async fetchZones(campusSlug: string) {
    try {
      const { data } = await repositories.zoning.zone.getZones(campusSlug);
      this.zones = data.results;
      this.device.zones = this.zones;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'zonah' },
        ).toString(),
      );
    }
  }

  private async fetchLocationsWithRooms(campusSlug: string) {
    try {
      const { data } =
        await repositories.tenants.location.getLocationsWithRooms({
          campusSlug,
        });
      this.locations = data.results;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
  }

  private async fetchLocationWithRoomsById(locationId: number) {
    try {
      const { data } = await repositories.tenants.location.getLocationWithRooms(
        locationId,
      );
      this.device.location = data;
      this.locations = [data];
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'lokacijah' },
        ).toString(),
      );
    }
  }

  private async fetchCampus(campusSlug: string) {
    try {
      const { data } = await repositories.tenants.campus.getCampus(campusSlug);
      this.device.campus = data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
  }

  private async fetchSite(siteSlug: string) {
    try {
      const { data } = await repositories.connectivity.site.getSite(siteSlug);
      this.device.site = data;
      await this.fetchLocationWithRoomsById(data.location);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private created() {
    this.initForm();
  }

  private getZoneReprText(zone: Zone) {
    return this.repr.zoneRepr(zone).text;
  }

  private async suggestName() {
    try {
      const { data } =
        await repositories.infrastructure.device.getSuggestedNames(
          this.campusSlug,
        );
      this.device.name =
        data[this.device.function as 'l2_switch' | 'l3_switch' | 'router'];
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private openAddOrEditBuildingModal() {
    const promise: Promise<Location> = this.$modals.open(
      'app-add-or-edit-building-modal',
      {
        component: {
          props: {
            campusSlug: this.device.campus?.slug || null,
            locations: this.device.campus?.locations || [],
            location: null,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Location>;
    promise.then((loc: Location) => {
      // need to fetch new building because we need to get its 'unknown' room's data
      this.fetchLocationWithRooms(loc.id).then((location: Location) => {
        this.locations!.push(location);
        this.device.location = location;
      });
    });
  }

  private async fetchLocationWithRooms(locationId: number): Promise<Location> {
    return repositories.tenants.location
      .getLocationWithRooms(locationId)
      .then((response: any) => response.data)
      .catch((error: any) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { itemMessageText: 'lokaciji' },
          ).toString(),
        );
      });
  }

  private openAddRoomModal() {
    const parent = this.device.location;
    const promise: Promise<Location> = this.$modals.open('app-add-room-modal', {
      component: {
        props: {
          campusSlug: this.device.campus?.slug || null,
          parent,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<Location>;
    promise.then((location: Location) => {
      // transform data to device.location.children format
      const newRoom = {
        children: [],
        id: location.id,
        name: location.name,
        type: location.type,
      };
      parent!.children!.push(newRoom);
      this.device.location_room = newRoom;
    });
  }
}
