
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN } from '@/store/getters.type';
import { Technology, DataOptions } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';
import { openInNewTab } from '@/helpers';
import { Query } from '@/api/query';
import { addDataOptionsToQuery } from '@/api/helpers';
import { prettyPrintFullDate } from '@/utils/filters';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class TechnologyList extends Vue {
  @userModule.Getter(IS_ADMIN) public isAdmin!: boolean;
  private indeterminate = true;
  private rowsPerPageItems = [50, 200, 500];
  private headers = [
    { text: 'Ime', value: 'name' },
    { text: 'Oznaka', value: 'slug' },
    { text: 'Št. povezav', value: 'nr_circuits' },
    { text: 'Dokumentacija', value: 'doc_url' },
    { text: 'Posodobljeno', value: 'modified' },
    { text: 'Akcije', value: 'actions', sortable: false },
  ];
  private search = '';
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private technologies: Technology[] = [];
  private totalTechnologies = 0;
  private openInNewTab = openInNewTab;
  private prettyPrintFullDate = prettyPrintFullDate;

  private loading = true;

  private options: DataOptions | null = null;

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchTechnologies(this.options);
  }

  private async fetchTechnologies(pagination: DataOptions | null) {
    this.loading = true;
    try {
      const query = new Query({
        search: this.search,
      });
      if (pagination != null)
        addDataOptionsToQuery(query, pagination, 'sort_by');
      const { data } =
        await repositories.connectivity.technology.getTechnologies(query);
      this.technologies = data.results;
      this.totalTechnologies = data.count;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading = false;
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchTechnologies(this.options!);
  }

  private openAddModal() {
    const promise: Promise<Technology> = this.$modals.open(
      'app-add-edit-technology-modal',
      {
        component: {
          props: {
            editMode: false,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Technology>;

    promise.then((technology: Technology) => {
      this.fetchTechnologies(this.options);
    });
  }
  private openEditModal(technology: Technology) {
    const promise: Promise<Technology> = this.$modals.open(
      'app-add-edit-technology-modal',
      {
        component: {
          props: {
            editMode: true,
            data: technology,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Technology>;

    promise.then((technology: Technology) => {
      this.fetchTechnologies(this.options);
    });
  }

  private deleteTechnology(modal: any, technology: Technology) {
    repositories.connectivity.technology
      .delete(technology.slug)
      .then(() => {
        this.fetchTechnologies(this.options);
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            {
              message: `Pri izbrisu tehnologije ${technology.name} je prišlo do napake.`,
            },
          ).toString(),
        );
      });
  }

  private openDeleteModal(technology: Technology) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `tehnologijo ${technology.name}(${technology.slug})`,
            item: technology,
          },
          deleteFn: this.deleteTechnology,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }
}
