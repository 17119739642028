
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import _get from 'lodash.get';
import { Device, Campus } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import ErrorHandler from '@/components/shared/errorHandler';
import CampusHeader from '@/components/campus/CampusHeader.vue';
import CampusDevicesSummit from '@/components/campus/CampusDevicesSummit.vue';
import { sloveneStatusNames } from '@/utils/constants';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    'app-campus-header': CampusHeader,

    'app-campus-devices-summit': CampusDevicesSummit,
  },
})
export default class CampusDevices extends Vue {
  public search = '';
  @Prop() private campusSlug!: string;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private sloveneStatusNamesRef = sloveneStatusNames;

  private campus: Campus | null = null;
  private itemsPerPageOptions = [50, 200, 500];
  private sortBy = 'name';

  private devices: Device[] = [];
  private loading = {
    initial: true,
    campus: true,
    prepare: false,
    configure: false,
    activate: false,
  };
  private supportedMIFunctions = ['l2_switch', 'l3_switch', 'router'];
  private selectedDevices: any[] = [];
  private get configUrl(): string {
    const confUrl = process.env.VUE_APP_DEVICE_CONFIG_ARCHIVE_BASE;
    if (confUrl && this.campusSlug) {
      return `${confUrl}/-/tree/master/${this.campusSlug}`;
    }
    return '';
  }
  private get parsedDevices(): any[] {
    const parsedDev = [];

    for (const device of this.devices) {
      const matchingBuildingName = _get(
        this.campus?.locations?.find(
          (loc) => loc.id === device.location?.parent,
        ),
        'name',
        '/',
      );
      const tmp = {
        id: device.id,
        value: false,
        name: device.name,
        managed: device.managed ? 'Da' : 'Ne',
        status: device.status,
        primary_address: _get(device, 'primary_address.address', '/'),
        function: _get(device, 'function.name', '/'),
        functionSlug: _get(device, 'function.slug', '/'),
        roles: '/',
        base_mac: device.asset?.base_mac ? device.asset.base_mac : '/',
        product: _get(device, 'asset.product.name', '/'),
        serial_number: _get(device, 'asset.serial_number', '/'),
        building: matchingBuildingName,
        room: _get(device, 'location.name', '/'),
        unknownRoom: _get(device, 'location.name', '/') === 'neznan prostor',
        inventory_sys_id: _get(device, 'asset.inventory_sys_id', null),
        modules: _get(device, 'modules', []),
        sortable: false,
      };
      // Device Roles
      if (device.roles) {
        tmp.roles = device.roles.map((role) => role.name).join(', ');
      }
      parsedDev.push(tmp);
    }
    return parsedDev;
  }

  private headers = [
    { text: 'Ime', value: 'name', align: 'left', sortable: true },
    { text: 'IP naslov', value: 'primary_address', align: 'left' },
    { text: 'Serijska', value: 'serial_number', align: 'left' },
    { text: 'Status', value: 'status', align: 'left' },
    { text: 'Upravljana', value: 'managed', align: 'left' },
    { text: 'Funkcija', value: 'function', align: 'left' },
    { text: 'Vloge', value: 'roles', align: 'left' },
    {
      text: 'Model',
      value: 'product',
      align: 'left',
    },
    { text: 'MAC', value: 'base_mac', align: 'left' },
    { text: 'Lokacija', value: 'location', align: 'left' },
    {
      text: 'Akcije',
      value: 'actions',
      align: 'right',
      sortable: false,
    },
  ];

  private async fetchCampusInfo(): Promise<void> {
    this.loading.campus = true;
    try {
      const response = await repositories.tenants.campus.getCampusExtended(
        this.campusSlug,
      );
      this.campus = response.data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
    this.loading.campus = false;
  }

  private async fetchCampusDevices() {
    this.loading.initial = true;
    try {
      const { data } = await repositories.infrastructure.device.getDevices({
        campusSlug: this.campusSlug,
      });
      // filter out TSP's for admin
      this.devices = data.results.filter((device: Device) => {
        return !(
          device.roles?.map((el) => el.slug).includes('tsp') &&
          ['router', 'l3_switch', 'l2_switch'].includes(device.function.slug)
        );
      });
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'napravah' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  private created() {
    this.fetchCampusInfo();
    this.fetchCampusDevices();
  }

  private showDeviceMoveModal(device: Device) {
    const promise: Promise<{
      device: Device;
      changedCampus: boolean;
    }> = this.$modals.open('app-device-move-modal', {
      component: {
        props: {
          device: this.devices.find((d: any) => d.id === device.id),
          campusSlug: this.campusSlug,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<{ device: Device; changedCampus: boolean }>;
    // update device info without fetching
    promise.then((data) => {
      const modifiedDevice = data.device;
      const deviceIdx = this.devices.findIndex(
        (el) => el.id === modifiedDevice.id,
      );
      // remove existing device data
      this.devices!.splice(deviceIdx, 1);
      // add new device data only if it's still in the same campus
      if (!data.changedCampus) {
        this.devices!.splice(deviceIdx, 0, modifiedDevice);
      }
    });
  }

  private showDeviceReplaceModal(device: Device) {
    const promise: Promise<Device> = this.$modals.open(
      'app-device-replace-modal',
      {
        component: {
          props: {
            device: this.devices.find((d: any) => d.id === device.id),
            campusSlug: this.campusSlug,
          },
        },
        dialog: {
          props: {
            'max-width': '1400px',
          },
        },
      },
    ) as Promise<Device>;
    // update device info without fetching
    promise.then((modifiedDevice: Device) => {
      const deviceIdx = this.devices.findIndex(
        (el) => el.id === modifiedDevice.id,
      );
      // replace existing device info with the new one
      this.devices!.splice(deviceIdx, 1);
      this.devices!.splice(deviceIdx, 0, modifiedDevice);
    });
  }

  private showDeviceEditModal(device: Device) {
    const promise: Promise<Device> = this.$modals.open(
      'app-device-edit-modal',
      {
        component: {
          props: {
            device: this.devices.find((d: any) => d.id === device.id),
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<Device>;
    // update device info without fetching
    promise.then((modifiedDevice: Device) => {
      const deviceIdx = this.devices.findIndex(
        (el) => el.id === modifiedDevice.id,
      );
      // replace existing device info with the new one
      this.devices!.splice(deviceIdx, 1);
      this.devices!.splice(deviceIdx, 0, modifiedDevice);
    });
  }

  private showDeviceDeleteModal(device: Device): void {
    this.$modals.open('app-confirm-device-delete-modal', {
      component: {
        props: {
          data: {
            repr: `${device.name}`,
            item: device,
          },
          deleteFn: this.deleteDevice,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteDevice(
    modal: any,
    data: { item: Device; form: { assetStatus: string | null } },
  ): void {
    const device = data.item;
    repositories.infrastructure.device
      .deleteDevice(device.name, data.form.assetStatus)
      .then((resp) => {
        const el = this.devices.find((el) => el.id === device.id) as Device;
        el.status = 'retired';
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.$modals.close();
      })
      .catch((error) => {
        modal.deleting = false;
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
      });
  }

  private showDevicesChangeStatusModal(devices: Device[]) {
    if (devices.length === 0) {
      this.$toasted.error(`Izbrati je potrebno vsaj eno napravo.`, {
        icon: 'mdi-error',
      });
      return;
    }
    const promise: Promise<string> = this.$modals.open(
      'app-devices-change-status-modal',
      {
        component: {
          props: {
            devices,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<string>;
    // update device info without fetching
    promise.then((status: string) => {
      // fix statuses here
      for (const device of devices) {
        const matchingDevice = this.devices.find(
          (el: Device) => el.id === device.id,
        ) as Device;
        matchingDevice.status = status;
      }
      // need to fix also selected devices, otherwise they are not changed and if
      // you update status then the next time modal opens the selected value will
      // be the old one
      const currentSelectedDevicesPks = this.selectedDevices.map((el) => el.id);
      this.selectedDevices = this.parsedDevices.filter((el) =>
        currentSelectedDevicesPks.includes(el.id),
      );
    });
  }

  private selectGroup(functionSlug: string) {
    this.selectedDevices = this.parsedDevices.filter(
      (device: any) => device.functionSlug === functionSlug,
    );
  }

  private async performAction(
    action: 'prepare_devices' | 'configure_devices' | 'activate_devices',
  ) {
    if (this.selectedDevices.length === 0) {
      this.$toasted.error(`Izbrati je potrebno vsaj eno napravo.`, {
        icon: 'mdi-error',
      });
      return;
    }
    type loadingAction = 'prepare' | 'configure' | 'activate';
    const actionLoadingMapper = {
      prepare_devices: 'prepare',
      configure_devices: 'configure',
      activate_devices: 'activate',
    };
    this.loading[actionLoadingMapper[action] as loadingAction] = true;
    try {
      if (action === 'activate_devices') {
        await repositories.infrastructure.device.activateDevices(
          this.selectedDevices.map((d) => d.name),
        );
      } else {
        await repositories.orchestrator.campus.runTaskAction(
          this.campusSlug,
          action,
          this.selectedDevices.map((d) => d.name),
        );
      }
      this.fetchTasksStateAction();
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading[actionLoadingMapper[action] as loadingAction] = false;
  }

  /*
   * We use custom-filter on v-data-table because filter on specific header doesn't
   * work - https://github.com/vuetifyjs/vuetify/issues/11600
   */
  private customDeviceTableFilter(
    value: any,
    search: string | null,
    item: any,
  ) {
    let normalizedSearch = search;
    if (search != null) {
      normalizedSearch = search
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll(':', '')
        .toUpperCase();
    }
    return (
      value != null &&
      search != null &&
      typeof value !== 'boolean' &&
      (value
        .toString()
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase()) !== -1 ||
        (item.base_mac && item.base_mac.indexOf(normalizedSearch) !== -1))
    );
  }
}
