
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AddAsset from '../../components/device/AddAsset.vue';
import AddModule from '@/components/device/AddModule.vue';

import { Asset, NetboxAsset, User, DeviceInfo, Device } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { ModuleForm } from '@/api/infrastructure/module';
import ErrorHandler from '@/components/shared/errorHandler';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { USER } from '@/store/states.type';
import { IS_ADMIN } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { deepClone } from '@/helpers';

const userModule = namespace(USER_NAMESPACE_PATH);

type CreateDeviceModuleData = {
  asset: NetboxAsset | Asset | null;
  device: Device | number | null;
  position: number | null;
};

@Component({
  components: {
    AddAsset,
    AddModule,
  },
})
export default class AddModuleToDevice extends Vue {
  public $refs!: {
    step1Component: AddAsset;
    step2Component: AddModule;
  };
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.State(USER) public user!: User;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @Prop({ default: undefined, type: String }) private campusSlug!:
    | string
    | undefined;
  @Prop({ default: undefined, type: String }) private deviceName!:
    | string
    | undefined;
  @Prop({ default: undefined, type: String }) private siteSlug!:
    | string
    | undefined;

  private deepCloneFn = deepClone;
  private currentStep = 1;
  // addAssetKey is used just so that netbox-asset-list refreshes when you add
  // more than 1 device.
  private addAssetKey = 1;
  private loading = false;

  private deviceData: DeviceInfo | null = null;

  private deviceModuleForm: CreateDeviceModuleData = {
    asset: null,
    device: null,
    position: null,
  };

  private nextStep() {
    this.currentStep++;
  }

  private getAssetData(asset: NetboxAsset | Asset) {
    let assetData: number | Asset;
    if (asset.id) {
      // we have NetboxAsset, pass only its ID
      assetData = asset.id;
    } else {
      let productData: any;
      if ('product' in asset && asset?.product?.id !== undefined) {
        productData = asset.product.id;
      } else if ('product' in asset) {
        productData = asset.product;
        productData.manufacturer = productData.manufacturer.name;
        productData.type = productData.type.name;
      } else {
        throw new Error('Podatki o produktu manjkajo.');
      }
      assetData = asset;
      assetData.product = productData;
    }
    return assetData;
  }

  private getDeviceData(device: DeviceInfo) {
    return device.id;
  }

  private getTransformedFormDataForApi(): ModuleForm {
    const form: CreateDeviceModuleData = deepClone(this.deviceModuleForm);
    let data: ModuleForm;
    if (form.asset != null && this.deviceData != null) {
      data = {
        asset: this.getAssetData(form.asset),
        device: this.getDeviceData(this.deviceData),
        position: form.position,
      };
    } else {
      throw new Error('Napaka pri vnosu podatkov.');
    }
    return data;
  }

  private async submit() {
    this.loading = true;
    try {
      const transformedFormData = this.getTransformedFormDataForApi();
      await repositories.infrastructure.deviceModule.createModule(
        transformedFormData,
      );
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.nextStep();
      this.$refs.step2Component.resetForm();
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler({ error, status: true }).toString(),
        {
          icon: 'mdi-error',
        },
      );
    }
    this.loading = false;
  }

  private async fetchDevice(): Promise<DeviceInfo | null> {
    if (!this.deviceName) {
      return Promise.resolve(null);
    }

    return repositories.infrastructure.device
      .getDeviceInfo(this.deviceName)
      .then((data) => data.data);
  }

  private handleStep1Submit() {
    this.deviceModuleForm.asset = this.$refs.step1Component.getAssetData();
    this.nextStep();
  }

  private handleStep2Submit() {
    this.deviceModuleForm.position =
      this.$refs.step2Component.getModuleData().position;
    this.submit();
  }

  private async resetForm() {
    this.deviceData = await this.fetchDevice();
    this.$refs.step2Component.resetForm();
    this.addAssetKey += 1;
    this.currentStep = 1;
  }

  private async created() {
    this.deviceData = await this.fetchDevice();
  }
}
