
import axios, { AxiosError } from 'axios';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { PromiserMixin, PromiserType } from 'vuex-modals';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import {
  Campus,
  CampusFull,
  Network,
  Circuit,
  Device,
  Location,
  Zone,
} from '@/api/interfaces';
import { Representations } from '@/components/shared/representation';
import { repositories } from '@/api/ApiFactory';
import Autocomplete from '@/components/shared/Autocomplete.vue';
import ErrorHandler from '@/components/shared/errorHandler';

const userModule = namespace(USER_NAMESPACE_PATH);

interface BuildingTreeItem {
  name: string;
  id: number;
  key: string;
  warning?: {
    title: string;
    description: string;
  };
  children: {
    name: string;
    children: {
      name: string;
      id: number;
      key: string;
      type: string;
    }[];
    type: string;
    key: string;
    unknown: boolean;
  }[];
  type: string;
  removable?: boolean;
}

interface NetworkItem extends Network {
  zones: Zone[];
  key: string;
  removable?: boolean;
}

@Component({
  components: {
    Autocomplete,
  },
})
export default class CampusMoveStuff extends Mixins<PromiserType>(
  PromiserMixin,
) {
  @Prop() private campusSlug!: string;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private campus: Campus | null = null;
  private repr = new Representations();
  private searchCampusesApi = repositories.tenants.campus.searchCampuses;
  private currentStep = 1;
  private destinationCampus: Campus | null = null;
  private campusFull: CampusFull | null = null;
  private destinationCampusFull: CampusFull | null = null;
  private loading = {
    campus: false,
    campusFull: false,
    destinationCampusFull: false,
    submit: false,
  };
  private mdiIconsMapper = {
    building: 'mdi-office-building',
    room: 'mdi-door',
    device: 'mdi-chip',
  };
  private circuitHeaders = [
    {
      text: 'Vozlišče',
      align: 'left',
      value: 'site',
      sortable: false,
    },
    { text: 'Upstream', value: 'upstream', sortable: false },
    { text: 'Kampus', value: 'campus', sortable: false },
  ];

  private openCampus: number[] = [];
  private openDestinationCampus: number[] = [];
  private active: string[] = [];
  private moved: {
    buildings: BuildingTreeItem[];
    networks: NetworkItem[];
  } = {
    buildings: [],
    networks: [],
  };

  private async created() {
    this.initialCampusLoad();
  }

  private async initialCampusLoad() {
    try {
      await this.fetchCampus();
      // async load campus full data, destination campus's data will be loaded when moving to step 2
      this.loading.campusFull = true;
      const res = await repositories.tenants.campus.getCampusFull(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.campus!.slug,
      );
      this.campusFull = res.data;
      this.loading.campusFull = false;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
      this.loading.campusFull = false;
    }
  }

  private async fetchCampus(): Promise<void> {
    this.loading.campus = true;
    try {
      const response = await repositories.tenants.campus.getCampusExtended(
        this.campusSlug,
      );
      this.campus = response.data;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
    }
    this.loading.campus = false;
  }

  private async moveToStep2() {
    this.fetchDestinationCampusFull();
    this.currentStep++;
  }

  private async fetchDestinationCampusFull() {
    this.loading.destinationCampusFull = true;
    try {
      const resp = await repositories.tenants.campus.getCampusFull(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.destinationCampus!.slug,
      );
      this.destinationCampusFull = resp.data;
      this.loading.destinationCampusFull = false;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'kampusu' },
        ).toString(),
      );
      this.loading.destinationCampusFull = false;
    }
  }

  private moveBackToStep1() {
    this.destinationCampusFull = null;
    this.currentStep--;
  }

  private get campusesLoaded() {
    // no loading is taking place and both have actual campus data instead of being null
    // casting (!!) to boolean because otherwise you get result of the last one
    const res = !!(
      !this.loadingCampuses &&
      this.campusFull &&
      this.destinationCampusFull
    );
    return res;
  }

  private get loadingCampuses() {
    // casting (!!) to boolean because otherwise you get result of the last one
    const res = !!(
      this.loading.campusFull || this.loading.destinationCampusFull
    );
    return res;
  }

  private get campusItems() {
    if (!this.campusFull) {
      return [];
    }
    return this.getCampusItems(this.campusFull, 'l').filter(
      (building) =>
        !this.moved.buildings.map((b) => b.id).includes(building.id),
    );
  }

  private getCampusItems(
    campus: CampusFull,
    prefix: string,
  ): BuildingTreeItem[] {
    const campusItems: BuildingTreeItem[] = [];
    for (const building of campus.locations!) {
      const rooms =
        building.children?.map((room: Location) => ({
          name: room.name,
          children:
            room.devices?.map((device: Device) => ({
              name: device.name,
              id: device.id,
              key: `${prefix}-device-${device.id}`,
              type: 'device',
            })) || [],
          type: 'room',
          key: `${prefix}-room-${room.id}`,
          unknown: room.name === 'neznan prostor',
        })) || [];
      const devicesDirectlyOnBuilding =
        building?.devices?.map((device: Device) => ({
          name: device.name,
          id: device.id,
          children: [],
          key: `${prefix}-device-${device.id}`,
          type: 'device',
          unknown: false,
        })) || [];

      rooms.push(...devicesDirectlyOnBuilding);

      const buildingItem: BuildingTreeItem = {
        name: building.name,
        id: building.id,
        key: `${prefix}-building-${building.id}`,
        children: rooms,
        type: 'building',
      };

      const buildingDevices = building
        .children!.map((room: Location) =>
          room.devices!.map((device: Device) => device.name),
        )
        .concat(building.devices?.map((device: Device) => device.name) || [])
        .reduce((acc, val) => acc.concat(val), []);

      let devicesWithCircuits = [];
      for (const circuit of campus.circuits) {
        if (
          circuit?.termination_z?.interface?.device?.name &&
          buildingDevices.includes(circuit.termination_z.interface.device.name)
        ) {
          devicesWithCircuits.push(circuit.termination_z.interface.device.name);
        }
      }

      if (devicesWithCircuits.length > 0) {
        buildingItem['warning'] = {
          title: 'Premik ni mogoč',
          description:
            'V zgradbi so naprave, ki se uporabljajo za povezavo. Uredite oz. odstranite povezavo iz naprav: ' +
            devicesWithCircuits.join(', '),
        };
      }

      campusItems.push(buildingItem);
    }
    return campusItems;
  }

  private get destinationCampusItems() {
    if (!this.destinationCampusFull) {
      return [];
    }
    const res = this.getCampusItems(this.destinationCampusFull, 'r');
    for (const building of this.moved.buildings) {
      res.push({ ...building, removable: true });
    }
    return res;
  }

  private get campusNetworkItems() {
    if (!this.campusFull) {
      return [];
    }
    const res = this.getNetworkItems(this.campusFull, 'l');
    return res.filter(
      (network: NetworkItem) =>
        !this.moved.networks.map((n) => n.id).includes(network.id),
    );
  }

  private getNetworkItems(campus: CampusFull, prefix: string): NetworkItem[] {
    const networks = [];
    for (const network of campus.networks) {
      // you can only move user networks
      if (network.type.startsWith('user-')) {
        let networkId: number;
        if (network.vlan !== null && typeof network.vlan === 'object') {
          networkId = network.vlan.id;
        } else if (network.vlan !== null && typeof network.vlan === 'number') {
          networkId = network.vlan;
        } else {
          throw new Error('Network has no vlan');
        }

        networks.push({
          ...network,
          zones: campus.zones.filter((zone: Zone) =>
            zone.vlans!.includes(networkId),
          ),
          key: `${prefix}-network-${network.id}`,
        });
      }
    }
    return networks;
  }

  private get destinationCampusNetworkItems() {
    if (!this.destinationCampusFull) {
      return [];
    }
    const res = this.getNetworkItems(this.destinationCampusFull, 'r');
    for (const network of this.moved.networks) {
      res.push({ ...network, removable: true });
    }
    return res;
  }

  private get campusCircuitItems() {
    if (!this.campusFull) {
      return [];
    }
    return this.getCircuitItems(this.campusFull);
  }

  private get destinationCampusCircuitItems() {
    if (!this.destinationCampusFull) {
      return [];
    }
    return this.getCircuitItems(this.destinationCampusFull);
  }

  private getCircuitItems(campus: CampusFull) {
    const circuits = [];
    for (const circuit of campus.circuits) {
      circuits.push(circuit);
    }
    return circuits;
  }

  /*
   * Don't show 'campus' among the destination campus search results because
   * you never want to move stuff to the same campus
   */
  private filteredSearchCampusesApi(value: string) {
    return this.searchCampusesApi(value).then((res) => {
      res.data.results = res.data.results.filter(
        (el) => el.id !== this.campus!.id,
      );
      return res;
    });
  }

  private moveBuilding(building: BuildingTreeItem) {
    const circuits = this.getCircuitsInBuilding(building).map(
      (circuit: Circuit) => circuit.id,
    );
    if (circuits.length > 0) {
      this.$toasted.error('Premik ni mogoč, ker v zgrabi obstaja povezava');
      return;
    }
    this.moved.buildings.push(building);
  }

  private moveNetwork(network: NetworkItem) {
    const promise: Promise<Zone[]> = this.$modals.open('app-pick-zones-modal', {
      component: {
        props: {
          network,
          zones: this.destinationCampusFull!.zones,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    }) as Promise<Zone[]>;
    promise.then((zones: Zone[]) => {
      this.moved.networks.push({
        ...network,
        zones,
      });
    });
  }

  private getMovedDevicesNames(building: BuildingTreeItem) {
    const movedDevicesNames = [];
    for (const room of building.children) {
      for (const device of room.children) {
        movedDevicesNames.push(device.name);
      }
    }
    return movedDevicesNames;
  }

  private getCircuitsInBuilding(building: BuildingTreeItem) {
    const movedDevicesNames = this.getMovedDevicesNames(building);
    const movedCircuits = [];
    for (const circuit of this.campusFull?.circuits || []) {
      if (
        circuit?.termination_z?.interface?.device?.name &&
        movedDevicesNames.includes(circuit.termination_z.interface.device.name)
      ) {
        movedCircuits.push(circuit);
      }
    }
    return movedCircuits;
  }

  private removeBuilding(building: BuildingTreeItem) {
    const idx = this.moved.buildings.findIndex((b) => b.id === building.id);
    this.moved.buildings.splice(idx, 1);
  }

  private removeNetwork(network: Network) {
    const idx = this.moved.networks.findIndex(
      (n: Network) => n.id === network.id,
    );
    this.moved.networks.splice(idx, 1);
  }

  private async submit() {
    this.loading.submit = true;
    try {
      await repositories.provision.campus.moveCampusStuff(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.campusFull!.slug,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.destinationCampusFull!.slug,
        this.moved.buildings.map((building: BuildingTreeItem) => building.id),
        this.moved.networks.map((network: NetworkItem) => ({
          network: network.id as number,
          zones: network.zones.map((zone: Zone) => zone.id),
        })),
      );

      this.loading.submit = false;
      // fetch tasks state so that it immediatelly updates the badges
      this.fetchTasksStateAction();
      this.initialCampusLoad();
      this.fetchDestinationCampusFull();
      // reset things marked as moved
      this.moved.buildings.splice(0, this.moved.buildings.length);
      this.moved.networks.splice(0, this.moved.networks.length);
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
      this.loading.submit = false;
    }
  }
}
