import { PaginatedResponse, Technology } from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'connectivity/technologies',
};
type technologyCreateData = {
  name: string;
  slug: string;
  doc_url: string | null;
};

interface TechnologyApiInterface {
  getTechnologies: (
    query?: Query,
  ) => AxiosPromise<PaginatedResponse<Technology>>;
  create: (data: technologyCreateData) => AxiosPromise<Technology>;
  update: (
    slug: string,
    data: technologyCreateData,
  ) => AxiosPromise<Technology>;
  delete: (slug: string) => AxiosPromise<void>;
}

const technologyApi: TechnologyApiInterface = {
  getTechnologies(query = new Query()) {
    return axios.get(`${resources.default}/${query}`);
  },
  create(data: technologyCreateData) {
    return axios.post(`${resources.default}/`, data);
  },
  update(slug, data: technologyCreateData) {
    return axios.patch(`${resources.default}/${slug}/`, data);
  },
  delete(slug) {
    return axios.delete(`${resources.default}/${slug}/`);
  },
};

export default technologyApi;

export { TechnologyApiInterface, technologyCreateData };
