
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { USER_NAMESPACE_PATH } from '@/store/namespaces.type';
import { IS_ADMIN, IS_SUPPORT, IS_ADMIN_OR_ROID } from '@/store/getters.type';
import { FETCH_TASKS_STATE } from '@/store/actions.type';
import { repositories } from '@/api/ApiFactory';
import {
  Campus,
  CircuitExtended,
  Contact,
  Location,
  LocationType,
  Organization,
  RichTextDoc,
} from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import translateWord from '@/components/shared/translations';
import CampusContact from '@/components/shared/Contact.vue';
import CampusLocationMap from './CampusLocationMap.vue';
import CampusLocations from './CampusLocations.vue';
import CampusInfoExpansionPanelHeader from './CampusInfoExpansionPanelHeader.vue';

const userModule = namespace(USER_NAMESPACE_PATH);

@Component({
  components: {
    CampusLocations,
    CampusLocationMap,
    CampusContact,
    CampusInfoExpansionPanelHeader,
  },
})
export default class CampusInfo extends Vue {
  @Prop() private campus!: Campus;
  @Prop({ default: true }) private loading!: boolean;
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_SUPPORT) private isSupport!: boolean;
  @userModule.Getter(IS_ADMIN_OR_ROID) private isAdminOrRoid!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  private translateWord = translateWord;

  private loadingDocs = true;
  private loadingContacts = false;
  private documents: RichTextDoc[] = [];
  private contacts: Contact[] = [];
  private panel: any[] = [];
  private circuitIds: number[] = [];

  private headers = [
    {
      text: 'Organizacija',
      value: 'organizations',
      align: 'left',
      sortable: false,
    },
    { text: 'Viri', value: 'sources', align: 'left', sortable: false },
    { text: 'Ime', value: 'name', align: 'left', sortable: true },
    { text: 'Vrsta', value: 'type', align: 'left', sortable: true },
    { text: 'Kontakt', value: 'value', align: 'left', sortable: false },
    {
      text: 'Zadnja posodobitev',
      value: 'updated',
      align: 'left',
      sortable: true,
    },
  ];

  private get initialLoading() {
    return this.loading || this.loadingDocs;
  }

  private get sortedDocuments() {
    return [...this.documents].sort(
      (a, b) =>
        new Date(b.modified as string).getTime() -
        new Date(a.modified as string).getTime(),
    );
  }

  private created() {
    this.fetchCircuits();
    this.fetchDocuments();
    this.fetchCampusContacts();
  }

  private async fetchCircuits(): Promise<void> {
    try {
      const { data } = await repositories.connectivity.circuit.getCircuitsExtended(this.campus!.slug);
      this.circuitIds = data.results.map((circuit: CircuitExtended) => circuit.id);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavah' },
        ).toString(),
      );
    }
  }

  private async fetchDocuments() {
    this.loadingDocs = true;
    let docs = [];
    try {
      const response =
        await repositories.documentation.richtextdoc.getRichTextDocs(
          'campus',
          this.campus.slug,
        );
      docs = response.data.results;
      for (const circuitId of this.circuitIds) {
        const response =
          await repositories.documentation.richtextdoc.getRichTextDocs(
            'circuit',
            circuitId + '',
          );
        docs = docs.concat(response.data.results);
      }
      this.documents = docs;
    } catch (error: unknown) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loadingDocs = false;
  }

  private async fetchCampusContacts() {
    this.loadingContacts = true;
    try {
      const response = await repositories.tenants.campus.getContacts(
        this.campus.slug,
      );
      this.contacts = response.data.contacts.sort((a: Contact, b: Contact) =>  {
        if (a.sources.includes('portal_member')) {
          return -1;
        }
        if (b.sources.includes('portal_member')) {
          return 1;
        }
        if (a.sources.includes('organization_procurator')) {
          return -1;
        }
        if (b.sources.includes('organization_procurator')) {
          return 1;
        }
        return a.name > b.name ? -1 : 1;
      });
      for (const error of response.data.errors) {
        this.$toasted.error(error);
      }
    } catch (error: unknown) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loadingContacts = false;
  }

  private showMoveCampusStuffModal() {
    this.$modals.open('app-move-campus-stuff-modal', {
      component: {
        props: {
          campus: this.campus,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  get buildings(): Location[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.campus.locations!.filter(
      (location) => (location.type as LocationType).name === 'building',
    );
  }

  get portalUrl(): string {
    return `${process.env.VUE_APP_PORTAL_URL}portal/organization?id=`;
  }

  private redirectToCampusMoveStuff() {
    this.$router.push({
      name: 'moveStuff',
      params: { campusSlug: this.campus.slug },
    });
  }

  private onTasksClick(): void {
    this.$router.push({
      name: 'tasks',
      params: { campusSlug: this.campus.slug },
    });
  }

  private redirectToCampusRunTaskAction() {
    this.$router.push({
      name: 'runTaskAction',
      params: { campusSlug: this.campus.slug },
    });
  }

  private redirectToCampusLocations() {
    this.$router.push({
      name: 'locations',
      params: { campusSlug: this.campus.slug },
    });
  }

  private async openAddOrganizationToCampusModal() {
    const organization: Organization = await this.$modals.open(
      'app-add-organization-to-campus-modal',
      {
        component: {
          props: {
            campus: this.campus,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    );
    this.campus.organizations!.push(organization);
    this.fetchCampusContacts();
  }

  private openDeleteOrganizationModal(organization: Organization) {
    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `organizacijo ${organization.name} (${organization.portal_id}) iz kampusa ${this.campus.name}`,
            item: organization,
          },
          deleteFn: this.deleteOrganization,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteOrganization(modal: any, organization: Organization) {
    repositories.tenants.campus
      .removeOrganization(this.campus.slug, organization.id)
      .then(() => {
        this.campus.organizations = this.campus.organizations!.filter(
          (org) => org.id !== organization.id,
        );
        this.fetchCampusContacts();
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler({ error, status: true }).toString(),
        );
        modal.deleting = false;
      });
  }

  private async runCampusPrepare() {
    try {
      const response = await repositories.orchestrator.campus.runTaskAction(
        this.campus.slug,
        'prepare_campus',
      );
      this.fetchTasksStateAction();
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
  }

  private canCreateDoc() {
    return this.isAdminOrRoid;
  }

  private showRichTextDocCreateModal() {
    const promise: Promise<RichTextDoc> = this.$modals.open(
      'app-add-richtextdoc-modal',
      {
        component: {
          props: {
            objectId: this.campus.slug,
            modelName: 'campus',
            adminOnly: false,
          },
        },
        dialog: {
          props: {
            'max-width': '600px',
          },
        },
      },
    ) as Promise<RichTextDoc>;
    promise.then((doc: RichTextDoc) => {
      this.$router.push({ name: 'docs', params: { docSlug: doc.slug } });
    });
  }

  private showDocumentationHelpModal() {
    this.$modals.open('app-documentation-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private showOrganizationHelpModal() {
    this.$modals.open('app-organization-help-modal', {
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private onBuildingAdd(building: Location) {
    this.campus!.locations!.push(building);
  }

  private onBuildingChanged(building: Location) {
    const buildingIdx = this.campus!.locations!.findIndex(
      (el) => el.id === building.id,
    );
    this.campus!.locations!.splice(buildingIdx, 1, building);
  }
}
