
import { Component, Vue, Watch } from 'vue-property-decorator';
import { repositories } from '@/api/ApiFactory';
import { Campus, CircuitExtended, CircuitSnapshot, FrontPort, RichTextDoc } from '@/api/interfaces';
import ErrorHandler from '@/components/shared/errorHandler';
import { getProtocolText } from '@/components/shared/helpers';
import translateWord from '@/components/shared/translations';
import { namespace } from 'vuex-class';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  DELETE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { IS_ADMIN, IS_ADMIN_OR_SUPPORT } from '@/store/getters.type';
import { getArrayDiff } from '@/helpers';
import _isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';

const userModule = namespace(USER_NAMESPACE_PATH);
const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);

@Component
export default class CircuitDetail extends Vue {
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @userModule.Getter(IS_ADMIN_OR_SUPPORT) public isAdminOrSupport!: boolean;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;
  @circuitsModule.Action(DELETE_CIRCUIT) private deleteCircuitAction!: (
    circuit: CircuitExtended,
  ) => Promise<any>;

  private loading = {
    circuit: true,
    circuits: true,
    snapshots: true,
    creatingDoc: false,
    documents: true,
  };
  private circuit: CircuitExtended | null = null;
  private snapshots: CircuitSnapshot[] = [];
  private getProtocolTextFn = getProtocolText;
  private translateWordFn = translateWord;
  private showPassword = false;
  private snapshotDiffs: any[] | null = null;
  private panel: any[] = [];
  private isEmptyLodash = _isEmpty;
  private documents: RichTextDoc[] = [];
  private circuitIds: number[] = [];

  private localTranslations = {
    tunnel: 'Tunel',
    provider: 'Ponudnik',
    status: 'Status',
    speed_up: 'Hitrost gor',
    speed_down: 'Hitrost dol',
    commercial: 'Komercialna',
    install_date: 'Datum priklopa',
    termination_date: 'Datum odklopa',
    technology: 'Tehnologija',
    protocol: 'Protokol',
    username: 'Uporabniško ime',
    password: 'Geslo',
    service_id: 'ID Storitve',
    type: 'Tip priklopa',
    organization: 'Organizacija',
    device: 'Naprava',
    patch_panel: 'Optični delilnik',
    address: 'Address',
    gateway: 'Gateway',
    id: 'ID',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    connection: 'Povezava v omrežje',
  };

  private async created() {
    const circuitId = Number(this.$route.params.circuitId);
    await this.fetchCircuit(circuitId);
    if (this.circuit!.campus !== null) {
      await this.fetchCircuits();
      await this.fetchDocuments();
    } else {
      this.documents = [];
      this.loading.circuits = false;
      this.loading.documents = false;
    }
    if (this.isAdminOrSupport) {
      await this.fetchCircuitSnapshots(circuitId);
    } else {
      this.loading.snapshots = false;
    }
  }

  get loadingInitial() {
    if (this.isAdminOrSupport) {
      return this.loading.circuit || this.loading.snapshots || this.loading.circuits;
    }
    return this.loading.circuit || this.loading.circuits;
  }

  private async fetchCircuits(): Promise<void> {
    this.loading.circuits = true;
    try {
      const { data } = await repositories.connectivity.circuit.getCircuitsExtended(this.circuit!.campus.slug);
      this.circuitIds = data.results.map((circuit: CircuitExtended) => circuit.id);
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavah' },
        ).toString(),
      );
    }
    this.loading.circuits = false;
  }

  private async fetchDocuments() {
    this.loading.documents = true;
    let docs = [];
    try {
      const { data } =
        await repositories.documentation.richtextdoc.getRichTextDocs(
          'campus',
          this.circuit!.campus.slug,
        );
      docs = data.results.filter((doc: RichTextDoc) => !doc.policy.includes('roid'));
      for (const circuitId of this.circuitIds) {
        const response =
          await repositories.documentation.richtextdoc.getRichTextDocs(
            'circuit',
            circuitId + '',
          );
        docs = docs.concat(response.data.results);
      }
      this.documents = docs;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'dokumentih' },
        ).toString(),
      );
    }
    this.loading.documents = false;
  }

  private async fetchCircuit(id: number) {
    this.loading.circuit = true;
    try {
      const { data } =
        await repositories.connectivity.circuit.getCircuitExtended(id);
      this.circuit = data;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavi' },
        ).toString(),
      );
    }
    this.loading.circuit = false;
  }

  private getSnapshotDiff(oldState: any, newState: any) {
    // calculate added/removed data
    const newKeys = Object.keys(newState);
    const oldKeys = Object.keys(oldState);
    const removedKeys = getArrayDiff(oldKeys, newKeys);
    const addedKeys = getArrayDiff(newKeys, oldKeys);
    // calculate "osnovne informacije" diffs
    const basicInfoDiffs = [];
    if (oldState.status !== newState.status) {
      basicInfoDiffs.push({
        attr: 'status',
        oldValue: oldState.status,
        newValue: newState.status,
      });
    }
    if (oldState.type !== newState.type) {
      basicInfoDiffs.push({
        attr: 'type',
        oldValue: oldState.type,
        newValue: newState.type,
      });
    }
    if (
      (`${oldState.organization?.name} (${oldState.organization?.portal_id})` ||
        '/') !==
      (`${newState.organization?.name} (${newState.organization?.portal_id})` ||
        '/')
    ) {
      basicInfoDiffs.push({
        attr: 'organization',
        oldValue: oldState.organization
          ? `${oldState.organization.name} (${oldState.organization.portal_id})`
          : '/',
        newValue: newState.organization
          ? `${newState.organization.name} (${newState.organization.portal_id})`
          : '/',
      });
    }
    // calculate "vozliscna stran" diffs
    const terminationADiffs = [];
    if (
      `${oldState.termination_a?.interface?.device?.name} - ${oldState.termination_a?.interface?.name}` !==
      `${newState.termination_a?.interface?.device?.name} - ${newState.termination_a?.interface?.name}`
    ) {
      terminationADiffs.push({
        attr: 'device',
        oldValue:
          `${oldState.termination_a?.interface?.device?.name} - ${oldState.termination_a?.interface?.name}` ===
          'undefined - undefined'
            ? '/'
            : `${oldState.termination_a?.interface?.device?.name} - ${oldState.termination_a?.interface?.name}`,
        newValue:
          `${newState.termination_a?.interface?.device?.name} - ${newState.termination_a?.interface?.name}` ===
          'undefined - undefined'
            ? '/'
            : `${newState.termination_a?.interface?.device?.name} - ${newState.termination_a?.interface?.name}`,
      });
    }
    if (oldState.termination_a?.speed_up !== newState.termination_a?.speed_up) {
      terminationADiffs.push({
        attr: 'speed_up',
        oldValue: oldState.termination_a?.speed_up || '?',
        newValue: newState.termination_a?.speed_up || '?',
      });
    }
    if (
      oldState.termination_a?.speed_down !== newState.termination_a?.speed_down
    ) {
      terminationADiffs.push({
        attr: 'speed_down',
        oldValue: oldState.termination_a?.speed_down || '?',
        newValue: newState.termination_a?.speed_down || '?',
      });
    }
    if (
      (oldState.termination_a.front_ports.length > 0
        ? `${
            oldState.termination_a.front_ports[0]?.patch_panel_name
          } (${oldState.termination_a.front_ports
            .map((el: FrontPort) => el.name)
            .join(', ')})`
        : oldState.termination_a.patch_panel_repr || '/') !==
      (newState.termination_a.front_ports.length > 0
        ? `${
            newState.termination_a.front_ports[0]?.patch_panel_name
          } (${newState.termination_a.front_ports
            .map((el: FrontPort) => el.name)
            .join(', ')})`
        : newState.termination_a.patch_panel_repr || '/')
    ) {
      terminationADiffs.push({
        attr: 'patch_panel',
        oldValue:
          oldState.termination_a.front_ports.length > 0
            ? `${
                oldState.termination_a.front_ports[0]?.patch_panel_name
              } (${oldState.termination_a.front_ports
                .map((el: FrontPort) => el.name)
                .join(', ')})`
            : oldState.termination_a.patch_panel_repr || '/',
        newValue:
          newState.termination_a.front_ports.length > 0
            ? `${
                newState.termination_a.front_ports[0]?.patch_panel_name
              } (${newState.termination_a.front_ports
                .map((el: FrontPort) => el.name)
                .join(', ')})`
            : newState.termination_a.patch_panel_repr || '/',
      });
    }
    // calculate "kampus stran" diffs
    const terminationZDiffs = [];
    if (
      `${oldState.termination_z?.interface?.device?.name} - ${oldState.termination_z?.interface?.name}` !==
      `${newState.termination_z?.interface?.device?.name} - ${newState.termination_z?.interface?.name}`
    ) {
      terminationZDiffs.push({
        attr: 'device',
        oldValue:
          `${oldState.termination_z?.interface?.device?.name} - ${oldState.termination_z?.interface?.name}` ===
          'undefined - undefined'
            ? '/'
            : `${oldState.termination_z?.interface?.device?.name} - ${oldState.termination_z?.interface?.name}`,
        newValue:
          `${newState.termination_z?.interface?.device?.name} - ${newState.termination_z?.interface?.name}` ===
          'undefined - undefined'
            ? '/'
            : `${newState.termination_z?.interface?.device?.name} - ${newState.termination_z?.interface?.name}`,
      });
    }
    if (oldState.termination_z?.speed_up !== newState.termination_z?.speed_up) {
      terminationZDiffs.push({
        attr: 'speed_up',
        oldValue: oldState.termination_z?.speed_up || '?',
        newValue: newState.termination_z?.speed_up || '?',
      });
    }
    if (
      oldState.termination_z?.speed_down !== newState.termination_z?.speed_down
    ) {
      terminationZDiffs.push({
        attr: 'speed_down',
        oldValue: oldState.termination_z?.speed_down || '?',
        newValue: newState.termination_z?.speed_down || '?',
      });
    }
    if (
      (oldState.termination_z.front_ports.length > 0
        ? `${
            oldState.termination_z.front_ports[0]?.patch_panel_name
          } (${oldState.termination_z.front_ports
            .map((el: FrontPort) => el.name)
            .join(', ')})`
        : oldState.termination_z.patch_panel_repr || '/') !==
      (newState.termination_z.front_ports.length > 0
        ? `${
            newState.termination_z.front_ports[0]?.patch_panel_name
          } (${newState.termination_z.front_ports
            .map((el: FrontPort) => el.name)
            .join(', ')})`
        : newState.termination_z.patch_panel_repr || '/')
    ) {
      terminationZDiffs.push({
        attr: 'patch_panel',
        oldValue:
          oldState.termination_z.front_ports.length > 0
            ? `${
                oldState.termination_z.front_ports[0]?.patch_panel_name
              } (${oldState.termination_z.front_ports
                .map((el: FrontPort) => el.name)
                .join(', ')})`
            : oldState.termination_z.patch_panel_repr || '/',
        newValue:
          newState.termination_z.front_ports.length > 0
            ? `${
                newState.termination_z.front_ports[0]?.patch_panel_name
              } (${newState.termination_z.front_ports
                .map((el: FrontPort) => el.name)
                .join(', ')})`
            : newState.termination_z.patch_panel_repr || '/',
      });
    }
    // calculate "narocniski podatki" diffs
    const providerInfoDiff = [];
    if (
      oldState.connection_info.commercial !==
      newState.connection_info.commercial
    ) {
      providerInfoDiff.push({
        attr: 'commercial',
        oldValue: oldState.connection_info.commercial,
        newValue: newState.connection_info.commercial,
      });
    }
    if (oldState.install_date !== newState.install_date) {
      providerInfoDiff.push({
        attr: 'install_date',
        oldValue: oldState.install_date
          ? new Date(oldState.install_date).toLocaleDateString('sl-SI')
          : '/',
        newValue: newState.install_date
          ? new Date(newState.install_date).toLocaleDateString('sl-SI')
          : '/',
      });
    }
    if (oldState.termination_date !== newState.termination_date) {
      providerInfoDiff.push({
        attr: 'termination_date',
        oldValue: oldState.termination_date
          ? new Date(oldState.termination_date).toLocaleDateString('sl-SI')
          : '/',
        newValue: newState.termination_date
          ? new Date(newState.termination_date).toLocaleDateString('sl-SI')
          : '/',
      });
    }
    if (
      oldState.provider?.pk !== newState.provider?.pk ||
      oldState.provider_identifier !== newState.provider_identifier
    ) {
      providerInfoDiff.push({
        attr: 'provider',
        oldValue: oldState.provider
          ? `${oldState.provider.name} (ID povezave: ` +
            (oldState.provider_identifier
              ? `${oldState.provider_identifier})`
              : '/)')
          : '/',
        newValue: newState.provider
          ? `${newState.provider.name} (ID povezave: ` +
            (newState.provider_identifier
              ? `${newState.provider_identifier})`
              : '/)')
          : '/',
      });
    }
    if (oldState.technology?.pk !== newState.technology?.pk) {
      providerInfoDiff.push({
        attr: 'technology',
        oldValue: oldState.technology?.name || '/',
        newValue: newState.technology?.name || '/',
      });
    }
    const connectionDiffs = [];
    // calculate "povezava v omrezje" diffs
    if (
      oldState.connection_info.connection.protocol !==
      newState.connection_info.connection.protocol
    ) {
      connectionDiffs.push({
        attr: 'protocol',
        oldValue: oldState.connection_info.connection.protocol,
        newValue: newState.connection_info.connection.protocol,
      });
    }
    if (
      oldState.connection_info.connection.username !==
      newState.connection_info.connection.username
    ) {
      connectionDiffs.push({
        attr: 'username',
        oldValue: oldState.connection_info.connection.username || '/',
        newValue: newState.connection_info.connection.username || '/',
      });
    }
    if (
      oldState.connection_info.connection.password !==
      newState.connection_info.connection.password
    ) {
      connectionDiffs.push({
        attr: 'password',
        oldValue: oldState.connection_info.connection.password || '/',
        newValue: newState.connection_info.connection.password || '/',
      });
    }
    if (
      oldState.connection_info.connection.address !==
      newState.connection_info.connection.address
    ) {
      connectionDiffs.push({
        attr: 'address',
        oldValue: oldState.connection_info.connection.address || '/',
        newValue: newState.connection_info.connection.address || '/',
      });
    }
    if (
      oldState.connection_info.connection.gateway !==
      newState.connection_info.connection.gateway
    ) {
      connectionDiffs.push({
        attr: 'gateway',
        oldValue: oldState.connection_info.connection.gateway || '/',
        newValue: newState.connection_info.connection.gateway || '/',
      });
    }
    // calculate "tunel" diffs
    const tunnelDiffs = [];
    if (
      oldState.connection_info.tunnel.id !== newState.connection_info.tunnel.id
    ) {
      tunnelDiffs.push({
        attr: 'id',
        oldValue: oldState.connection_info.tunnel.id || '/',
        newValue: newState.connection_info.tunnel.id || '/',
      });
    }
    if (
      oldState.connection_info.tunnel.ipv4 !==
      newState.connection_info.tunnel.ipv4
    ) {
      tunnelDiffs.push({
        attr: 'ipv4',
        oldValue: oldState.connection_info.tunnel.ipv4 || '/',
        newValue: newState.connection_info.tunnel.ipv4 || '/',
      });
    }
    if (
      oldState.connection_info.tunnel.ipv6 !==
      newState.connection_info.tunnel.ipv6
    ) {
      tunnelDiffs.push({
        attr: 'ipv6',
        oldValue: oldState.connection_info.tunnel.ipv6 || '/',
        newValue: newState.connection_info.tunnel.ipv6 || '/',
      });
    }
    const ignoreFields = [
      'status',
      'type',
      'technology',
      'provider',
      'provider_identifier',
      'organization',
      'campus',
      'install_date',
      'termination_date',
      'termination_a',
      'termination_z',
      'connection_info.commercial',
      'connection_info.connection.protocol',
      'connection_info.connection.username',
      'connection_info.connection.password',
      'connection_info.connection.address',
      'connection_info.connection.gateway',
      'connection_info.tunnel.id',
      'connection_info.tunnel.ipv4',
      'connection_info.tunnel.ipv6',
      'last_edited_by',
      'document',
    ];
    const otherDiff = this.generateDiffHTML(
      this.getObjDiff(oldState, newState, ignoreFields),
    );

    return {
      diffs: {
        basic: basicInfoDiffs,
        terminationA: terminationADiffs,
        terminationZ: terminationZDiffs,
        provider: providerInfoDiff,
        connection: connectionDiffs,
        tunnel: tunnelDiffs,
      },
      otherDiffHTML: otherDiff,
    };
  }

  private generateDiffHTML(diff: [number, string][]) {
    return diff
      .map((el: [number, string]) => {
        const [color, value] = el;
        const colorMapping: { [key: number]: string } = {
          0: '',
          1: 'green--text',
          2: 'red--text',
        };
        return `<pre class="${colorMapping[color]}">${value}</pre>`;
      })
      .join('');
  }

  private getLines(propName: string, obj: any, level = 0): string[] {
    let res: string[] = [];
    let indent = '\t'.repeat(level);
    let val = `${indent}${propName}: `;
    if (!propName) {
      val = `${indent}`;
    }
    let innerLines: string[] = [];
    if (Array.isArray(obj)) {
      if (obj.length === 0) {
        val += '[],';
      } else {
        val += ' [';
        res.push(val);
        for (const el of obj) {
          innerLines = this.getLines('', el, level + 1);
          res = res.concat(innerLines);
        }
        res.push(`${indent}],`);
      }
    } else if (obj != null && typeof obj === 'object') {
      val += ' {';
      res.push(val);
      // has to be an actual object
      for (const prop of Object.keys(obj)) {
        innerLines = this.getLines(prop, obj[prop], level + 1);
        res = res.concat(innerLines);
      }
      res.push(`${indent}},`);
    } else {
      // literal value, eg: null, undefined, number, string, boolean
      val += `${obj},`;
      res.push(val);
    }
    return res;
  }

  private getObjDiff(
    old: { [key: string]: any },
    _new: { [key: string]: any },
    ignoreFields: string[] | null = null,
    curPath: string | null = null,
    level = 0,
  ) {
    if (curPath === null) {
      // NOTE: curPath has . at the end, if it's not empty, eg: "connection_info."
      curPath = '';
    }
    if (ignoreFields === null) {
      ignoreFields = [];
    }
    if (typeof _new !== 'object') {
      return [];
    }
    const newKeys = Object.keys(_new).filter(
      (key) => !ignoreFields!.includes(`${curPath}${key}`),
    );
    const oldKeys = Object.keys(old).filter(
      (key) => !ignoreFields!.includes(`${curPath}${key}`),
    );
    const removedKeys = getArrayDiff(oldKeys, newKeys);
    const addedKeys = getArrayDiff(newKeys, oldKeys);
    const intersectingKeys = newKeys.filter((x) => oldKeys.includes(x));
    let res: [number, string][] = [];

    let indent = '\t'.repeat(level);
    let innerLines = [];
    for (const key of removedKeys) {
      innerLines = this.getLines(key, old[key], level);
      res = res.concat(innerLines.map((line) => [2, line]));
    }
    for (const key of addedKeys) {
      innerLines = this.getLines(key, _new[key], level);
      res = res.concat(innerLines.map((line) => [1, line]));
    }
    for (const key of intersectingKeys) {
      if (ignoreFields.includes(`${curPath}${key}`)) {
        continue;
      }
      if (isEqual(old[key], _new[key])) {
        // note that this also handles case where both are null
        continue;
      }
      // old value is null, new value is an object (or vice-versa or both objects)
      if (typeof old[key] === 'object') {
        // both old and new must be object here (one can be null since typeof null also evaluates to 'object')
        if (old[key] === null || _new[key] === null) {
          innerLines = this.getLines(key, old[key], level + 1);
          res = res.concat(innerLines.map((line) => [2, line]));
          innerLines = this.getLines(key, _new[key], level + 1);
          res = res.concat(innerLines.map((line) => [1, line]));
        } else {
          if (Array.isArray(old[key])) {
            // NOTE: here we keep the same level since we pass "propName" as first arg
            innerLines = this.getLines(key, old[key], level);
            res = res.concat(innerLines.map((line) => [2, line]));
            innerLines = this.getLines(key, _new[key], level);
            res = res.concat(innerLines.map((line) => [1, line]));
          } else {
            // both are objects
            const innerRes = this.getObjDiff(
              old[key],
              _new[key],
              ignoreFields,
              curPath === '' ? `${key}.` : `${curPath}${key}.`,
              level + 1,
            );
            if (!_isEmpty(innerRes)) {
              res.push([0, `${indent}${key}: {`]);
              res = res.concat(innerRes);
              res.push([0, `${indent}},`]);
            }
          }
        }
      } else {
        // regular (non-object) changes from value A to B
        innerLines = this.getLines(key, old[key], level);
        res = res.concat(innerLines.map((line) => [2, line]));
        innerLines = this.getLines(key, _new[key], level);
        res = res.concat(innerLines.map((line) => [1, line]));
      }
    }
    return res;
  }

  private getSnapshotDiffs() {
    const diffs = [];
    for (let i = 0; i < this.snapshots.length - 1; i++) {
      diffs.push({
        created: this.snapshots[i + 1].created,
        last_edited_by: this.snapshots[i + 1].data.last_edited_by,
        diff: this.getSnapshotDiff(
          this.snapshots[i].data,
          this.snapshots[i + 1].data,
        ),
      });
    }
    return diffs;
  }

  private async fetchCircuitSnapshots(id: number) {
    this.loading.snapshots = true;
    try {
      const { data } =
        await repositories.connectivity.circuit.getCircuitSnapshots(id);
      this.snapshots = data;
    } catch (error: unknown) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'zgodovini povezave' },
        ).toString(),
      );
    }
    this.loading.snapshots = false;
  }

  private getSpeedRepr(kbps: number) {
    if (kbps >= 1000000000) {
      return `${kbps / 1000000000} Tbps`;
    }
    if (kbps >= 1000000) {
      return `${kbps / 1000000} Gbps`;
    }
    if (kbps >= 1000) {
      return `${kbps / 1000} Mbps`;
    }
    return `${kbps} Kbps`;
  }

  @Watch('snapshots')
  private updateDiffs() {
    this.snapshotDiffs = this.getSnapshotDiffs();
  }

  private openEditCircuit() {
    if (this.circuit!.campus) {
      this.$router.push({
        name: 'editCircuit',
        params: { campusSlug: (this.circuit!.campus as Campus).slug, circuitId: this.circuit!.id + '' },
      });
    } else {
      this.$router.push({
        name: 'edit-global-circuit',
        params: { circuitId: this.circuit!.id + '' },
      });
    }
  }

  private async createEmptyCircuitDocument() {
    let docName = 'Povezava';
    if (this.documents.filter((doc) => doc.name === docName).length > 0) {
      // open modal to ask for name
      docName = await this.$modals.open(
        'app-text-input-modal',
        {
          component: {
            props: {
              title: "Ime dokumenta povezave",
              text: "Dokument z imenom 'Povezava' že obstaja v kampusu, prosim izberite drugo ime.",
              rules: "required",
              label: "Ime dokumenta",
              invalidValues: ['Povezava'],
            },
          },
          dialog: {
            props: {
              'max-width': '600px',
            },
          },
        },
      );
      if (Boolean(docName) === false) {
        return;
      }
    }
    this.createRichTextDoc(docName);
  }

  private async createRichTextDoc(name: string) {
    this.loading.creatingDoc = true;
    try {
      const { data } =
        await repositories.documentation.richtextdoc.createRichTextDoc(
          name,
          this.circuit!.id,
          'circuit',
          'admin|support',
          true,
        );
      this.$toasted.success('Dokument za povezavo je bil uspešno kreiran.');
      this.documents.push(data);
      this.circuit!.document = data;
    } catch (error) {
      this.$toasted.error(new ErrorHandler({ error, status: true }).toString());
    }
    this.loading.creatingDoc = false;
  }

  private openDeleteCircuitModal() {
    const circuit = this.circuit;
    let upstreamInterfaceRepr = '[neznan upstream]';
    if (circuit?.termination_a?.interface) {
      upstreamInterfaceRepr = circuit.termination_a.interface.name;
    }
    let downstreamInterfaceRepr = '[neznan downstream]';
    if (circuit?.termination_z?.interface) {
      downstreamInterfaceRepr = circuit.termination_z.interface.name;
    }

    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `povezavo med ${upstreamInterfaceRepr} in ${downstreamInterfaceRepr}`,
            item: circuit,
          },
          deleteFn: this.deleteCircuit,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteCircuit(modal: any, circuit: CircuitExtended) {
    this.deleteCircuitAction(circuit)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        // this.circuits = this.circuits.filter((c) => c.id !== circuit.id);
        if (circuit!.campus) {
          this.$router.push({
            name: 'circuits',
            params: { campusSlug: (circuit!.campus as Campus).slug },
          });
        } else {
          this.$router.push({
            name: 'circuits-list',
            params: {},
          });
        }
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem povezave je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }
}
