import { DataOptions } from '@/api/interfaces';
import { Query } from '@/api/query';

export function transformData(
  data: Record<string, unknown>,
  ignoreProps: string[],
  fks: string[],
) {
  const transformedData = Object.assign({}, data);
  fks.forEach((prop) => {
    if (typeof (transformedData as any)[prop] === 'object') {
      (transformedData as any)[prop] = (transformedData as any)[prop].id;
    }
  });
  ignoreProps.forEach((prop) => {
    delete (transformedData as any)[prop];
  });
  return transformedData;
}

/*
 * This function adds the data options for pagination and sorting to the query.
 *
 * @param query - The query object to which the data options will be added.
 * @param options - The data options object containing the pagination and sorting options.
 * @param sortKey - The key to use for sorting. For custom sort use 'sort_by' and for default django ordering use 'ordering'.
 * @returns void
 */
export function addDataOptionsToQuery(
  query: Query,
  options: DataOptions,
  sortKey = 'sort_by',
) {
  if (options.itemsPerPage > 0) {
    query.addParam('size', options.itemsPerPage);
  }
  query.addParam('page', options.page);
  let sortBy = options.sortBy[0];
  if (sortBy) {
    if (options.sortDesc[0]) {
      sortBy = `-${sortBy}`;
    }
    query.addParam(sortKey, sortBy);
  }
}
