
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Campus } from '@/api/interfaces';
import {
  CIRCUITS_NAMESPACE_PATH,
  USER_NAMESPACE_PATH,
} from '@/store/namespaces.type';
import {
  DELETE_CIRCUIT,
  FETCH_TASKS_STATE,
} from '@/store/actions.type';
import { IS_ADMIN } from '@/store/getters.type';
import { DataOptions, CircuitExtended, RichTextDoc } from '@/api/interfaces';
import { repositories } from '@/api/ApiFactory';
import { sloveneStatusNames } from '@/utils/constants';
import translateWord from '@/components/shared/translations';
import ErrorHandler from '@/components/shared/errorHandler';
import debounce from 'lodash.debounce';

const circuitsModule = namespace(CIRCUITS_NAMESPACE_PATH);
const userModule = namespace(USER_NAMESPACE_PATH);

@Component
export default class Circuits extends Vue {
  public search = '';
  @userModule.Getter(IS_ADMIN) private isAdmin!: boolean;
  @circuitsModule.Action(DELETE_CIRCUIT) private deleteCircuitAction!: (
    circuit: CircuitExtended,
  ) => Promise<any>;
  @userModule.Action(FETCH_TASKS_STATE)
  public fetchTasksStateAction!: () => Promise<any>;

  private sloveneStatusNamesRef = sloveneStatusNames;
  private translateWord = translateWord;
  private debouncedUpdateDebouncedSearch = debounce(
    this.updateDebouncedSearch,
    300,
  );
  private itemsPerPageOptions = [50, 200, 500];
  private options: DataOptions = {
    groupBy: [],
    groupDesc: [],
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 50,
    multiSort: false,
    mustSort: false,
  };

  private circuits: CircuitExtended[] = [];
  private totalCircuits = 0;
  private loading = {
    initial: true,
  };

  private headers = [
    { text: 'Kampus', value: 'campus.slug', align: 'left', sortable: false },
    { text: 'Tip', value: 'type', align: 'left', sortable: false },
    { text: 'Vozlišče', value: 'site', align: 'left', sortable: false },
    {
      text: 'Vozliščna naprava',
      value: 'upstream',
      sortable: false,
    },
    { text: 'Kampus naprava', value: 'campus', sortable: false },
    { text: 'Status', value: 'status', align: 'left', sortable: false },
    { text: 'Kontrola', value: 'control', align: 'left', sortable: false },
  ];

  private async fetchCircuits(): Promise<void> {
    this.loading.initial = true;
    try {
      const { data } = await repositories.connectivity.circuit.getCircuitsExtended(
        '',
        { pagination: this.options },
        this.search,
      );
      this.circuits = data.results;
      this.totalCircuits = data.count;
    } catch (error) {
      this.$toasted.error(
        new ErrorHandler(
          { error, status: true },
          { itemMessageText: 'povezavah' },
        ).toString(),
      );
    }
    this.loading.initial = false;
  }

  private openEditCircuit(circuit: CircuitExtended) {
    if (circuit.campus) {
      this.$router.push({
        name: 'editCircuit',
        params: { campusSlug: (circuit.campus as Campus).slug, circuitId: circuit.id + '' },
      });
    } else {
      this.$router.push({
        name: 'edit-global-circuit',
        params: { circuitId: circuit.id + '' },
      });
    }
  }

  private openDeleteCircuitModal(circuit: CircuitExtended) {
    let upstreamInterfaceRepr = '[neznan upstream]';
    if (circuit?.termination_a?.interface) {
      upstreamInterfaceRepr = circuit.termination_a.interface.name;
    }
    let downstreamInterfaceRepr = '[neznan downstream]';
    if (circuit?.termination_z?.interface) {
      downstreamInterfaceRepr = circuit.termination_z.interface.name;
    }

    this.$modals.open('app-confirm-delete', {
      component: {
        props: {
          data: {
            repr: `povezavo med ${upstreamInterfaceRepr} in ${downstreamInterfaceRepr}`,
            item: circuit,
          },
          deleteFn: this.deleteCircuit,
        },
      },
      dialog: {
        props: {
          'max-width': '600px',
        },
      },
    });
  }

  private deleteCircuit(modal: any, circuit: CircuitExtended) {
    this.deleteCircuitAction(circuit)
      .then(() => {
        // fetch tasks state so that it immediatelly updates the badges
        this.fetchTasksStateAction();
        this.circuits = this.circuits.filter((c) => c.id !== circuit.id);
        this.$modals.close();
      })
      .catch((error) => {
        this.$toasted.error(
          new ErrorHandler(
            { error, status: true },
            { message: 'Med brisanjem povezave je prišlo do napake.' },
          ).toString(),
        );
        modal.deleting = false;
      });
  }

  private openCreateCircuit() {
    this.$router.push({
      name: 'create-global-circuit',
      params: { campusSlug: '', redirectTo: 'circuits-list' },
    });
  }

  @Watch('options', { deep: true })
  private handleOptions() {
    this.fetchCircuits();
  }

  @Watch('search')
  /**
   * @description debounce of search is implemented with 2 variables, where
   * the debouncedSearch is debounced for X time from the search change
   */
  private onSearchChange() {
    this.debouncedUpdateDebouncedSearch();
  }
  private updateDebouncedSearch() {
    // after a new search you want to be looking at the first page
    // manually change pagination object so that @update.pagination on data table gets triggered,
    // otherwise it doesn't update number of pages/items
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.options!.page = 1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.fetchCircuits();
  }
}
