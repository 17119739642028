
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, Task } from '@/api/interfaces';

@Component
export default class DeviceData extends Vue {
  @Prop(Object) private device!: Device;
  @Prop(Object) private deviceFacts!: {
    data: Task | null;
    taskId: string | null;
    updatedAt: string;
  };
  private headers = [
    {
      text: 'Ime',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    {
      text: 'IP naslov',
      sortable: false,
      value: 'primary_address',
    },
    {
      text: 'Proizvajalec',
      sortable: false,
      value: 'asset.product.manufacturer.name',
    },
    {
      text: 'Model',
      sortable: false,
      value: 'asset.product.name',
    },
    {
      text: 'Serijska številka',
      sortable: false,
      value: 'asset.serial_number',
    },
    {
      text: 'Verzija OS',
      sortable: false,
      value: 'os_version',
    },
  ];
  private tooltipText = `Verzija programske opreme se šteje za preverjeno,
  ko uspešno opravi vse faze testiranja, ki zagotavljajo njeno stabilnost,
  varnost in združljivost. Preverjena verzija je uradno odobrena za uporabo in 
  je priporočljiva za najboljše delovanje sistema. Če prikazana verzija ni enaka
  preverjeni, ni razloga za skrb, saj je lahko še vedno varna in funkcionalna.`;
}
