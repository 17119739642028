import * as rules from 'vee-validate/dist/rules';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ipaddr, { IPv4, IPv6, isValid, parse, parseCIDR } from 'ipaddr.js';

import Vue from 'vue';
import { extend } from 'vee-validate';
import { localize } from 'vee-validate';
import sl from 'vee-validate/dist/locale/sl.json';

// loop over all rules and import them
for (const rule of Object.keys(rules)) {
  const data: { [key: string]: any; message: any } = {
    ...rules[rule as keyof typeof rules], // add the rule
    message: sl.messages[rule as keyof typeof sl.messages], // add its message
  };
  extend(rule, data);
}

// custom validation rules
function isValidIP(value: string) {
  return IPv4.isValidFourPartDecimal(value) || IPv6.isValid(value);
}

extend('readableAsciiNoQuestionMark', {
  validate: (value) => {
    const re = /^(?!.*[?])[\x20-\x7E]+$/;
    return re.test(value);
  },
  message: 'Šumniki, tabulator, nova vrstica ter vprašaj niso dovoljeni',
});

extend('ip', {
  validate: (value) => {
    if (!isValidIP(value)) {
      return `Vnesena vrednost ni veljaven IPv4 oziroma IPv6 naslov.`;
    }
    return true;
  },
});

extend('ipv4', {
  validate: (value) => {
    if (!IPv4.isValidFourPartDecimal(value)) {
      return `Vnesena vrednost ni veljaven IPv4 naslov.`;
    }
    return true;
  },
});

extend('ipv6', {
  validate: (value) => {
    if (!IPv6.isValid(value)) {
      return `Vnesena vrednost ni veljaven IPv6 naslov.`;
    }
    return true;
  },
});

extend('cidr', {
  validate: (value) => {
    try {
      parseCIDR(value);
    } catch {
      return false;
    }
    return true;
  },
  message: 'Vnesena vrednost ni v CIDR formatu.',
});

extend('subnetOf', {
  params: ['subnet'],
  // here i set to type 'Record<string, any>' because 'params' typings are:
  // params: any[] | Record<string, any> so compiler complains that 'subnet'
  // does not exist any[]
  validate: (value, { subnet }: Record<string, any>) => {
    try {
      if (!isValidIP(value)) {
        return `Vnesena vrednost ni veljaven IPv4 oziroma IPv6 naslov.`;
      }
      try {
        parseCIDR(subnet);
      } catch {
        return `'${subnet}' ni veljavno IPv4 oziroma IPv4 podomrežje.`;
      }
      // here i set the type to 'any' because otherwise it would be of type 'IPv4 | IPv6'
      // and compiler would complain
      const ip: any = parse(value);
      if (
        ipaddr.subnetMatch(ip, { gateway: parseCIDR(subnet) as any }) ===
        'unicast'
      ) {
        throw new Error();
      }
    } catch {
      return `IP ${value} ni v podomrežju ${subnet}`;
    }
    return true;
  },
});

extend('notIncluded', {
  params: ['elements'],
  // here i set to type 'Record<string, any>' because 'params' typings are:
  // params: any[] | Record<string, any> so compiler complains that 'subnet'
  // does not exist any[]
  validate: (value, { elements }: Record<string, any>) => {
    if (elements.includes(value)) {
      return `Vnesena vrednost ni dovoljena.`;
    }
    return true;
  },
});

/*
 * This rule is used to check if the value is a valid SSID for the selected WiFi type.
 * If the WiFi type is eduroam, the SSID must be eduroam or libroam.
 * If the WiFi type is not eduroam or WPA2-Enterprise, the SSID must not be eduroam or libroam.
 */
extend('reservedSSID', {
  params: ['wifiType'],
  validate: (value, { wifiType }: Record<string, any>) => {
    if (wifiType === 'eduroam' && !['eduroam', 'libroam'].includes(value)) {
      return `Vnesena vrednost ni veljaven SSID za tip WiFi omrežja eduroam. Ime brezžičnega omrežja mora biti eduroam ali libroam.`;
    } else if (
      wifiType !== 'eduroam' &&
      wifiType !== 'wpa2-enterprise' &&
      ['eduroam', 'libroam'].includes(value)
    ) {
      return `SSID ${value} je rezerviran za WiFi omrežja tipa eduroam in WPA2-Enterprise.`;
    }
    return true;
  },
});

extend('maxBytes', {
  validate: (value, { length }) => {
    const bSize = new Blob([value]).size;
    return bSize <= length;
  },
  params: ['length'],
  message: 'Polje {_field_} je predolgo.',
});

extend('ASCII', {
  validate: (value) => {
    const asciiReg = new RegExp('^[ -~]+$');
    return asciiReg.test(value);
  },
  message: 'Polje {_field_} ne sme vsebovati šumnikov in posebnih znakov.',
});

extend('slug', {
  validate: (value) => {
    const slugReg = new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$');
    return slugReg.test(value);
  },
  message:
    'Polje {_field_} lahko vsebuje le male črke in številke, brez šumnikov, presledkov ali posebnih znakov. Namesto presledkov med besedami uporabite znak za vezaj -.',
});

/**
 * This rule is used to validate MAC addresses.
 *
 *  Valid formats:
 *   - aabbccddeeff
 *   - aa.bb.cc.dd.ee.ff
 *   - aa-bb-cc-dd-ee-ff
 *   - aa:bb:cc:dd:ee:ff
 *   - aabb.ccdd.eeff
 *   - aabbcc.ddeeff
 */
extend('macAddress', {
  validate: (value) => {
    const re =
      /^((([a-fA-F0-9][a-fA-F0-9]){5}|([a-fA-F0-9][a-fA-F0-9][.]){5}|([a-fA-F0-9][a-fA-F0-9][-]){5}|([a-fA-F0-9][a-fA-F0-9][:]){5})([a-fA-F0-9][a-fA-F0-9])$)|(^([a-fA-F0-9]{4}[.]){2}([a-fA-F0-9]{4}))|(^([a-fA-F0-9]{6}[.][a-fA-F0-9]{6}))$/;
    return re.test(value);
  },
  message: 'MAC naslov ni v ustreznem formatu.',
});

extend('url', {
  // TODO: remove when updating to vee-validate v4
  validate: (value) => {
    try {
      new URL(value as string);
      return true;
    } catch {
      return 'Vnesena vrednost ni veljaven URL naslov.';
    }
  },
});

// Not all rules have translated error messages.
const extendedSl = Object.assign({}, sl, {
  messages: {
    ...sl.messages,
    is: 'Polje {_field_} ne vsebuje vrednosti {other}',
  },
});
// Install and activate Slovene locale
localize('sl', extendedSl);

// Register globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
