import { render, staticRenderFns } from "./NetboxStatusChip.vue?vue&type=template&id=43901fbd&scoped=true&"
import script from "./NetboxStatusChip.vue?vue&type=script&lang=ts&"
export * from "./NetboxStatusChip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43901fbd",
  null
  
)

export default component.exports