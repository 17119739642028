import {
  Circuit,
  CircuitExtended,
  CircuitSnapshot,
  DataOptions,
  PaginatedResponse,
  NetboxPatchPanel,
  NetboxFrontPort,
} from '@/api/interfaces';
import axios, { AxiosPromise } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'connectivity/circuit',
  ce: 'connectivity/circuit-extended',
};

interface CircuitApiInterface {
  getCircuits: (query?: Query) => AxiosPromise<PaginatedResponse<Circuit>>;
  getCircuit: (id: string | number) => AxiosPromise<Circuit>;
  getCircuitExtended: (id: string | number) => AxiosPromise<CircuitExtended>;
  getCircuitsExtended: (
    campusSlug: string,
    options?: {
      pagination?: DataOptions | null;
      _status?: string | null; // 'status' is reserved word
      provider_identifier?: string | null;
    },
    search?: string,
  ) => AxiosPromise<PaginatedResponse<CircuitExtended>>;
  createCircuit: (form: {
    campus_slug: string;
    // step 1
    site_slug: string | null;
    status: string;
    service_id: string;
    type: string;
    campus_interface_id: number | null;
    campus_interface_description: string | null;
    node_interface_id: number | null;
    node_interface_description: string | null;
    // step2
    campus_ct_front_ports: number[];
    campus_ct_patch_panel_repr: string | null;
    campus_ct_speed_up: number | null;
    campus_ct_speed_down: number | null;
    node_ct_front_ports: number[];
    node_ct_patch_panel_repr: string | null;
    node_ct_speed_up: number | null;
    node_ct_speed_down: number | null;
    // step3
    provider: string | null;
    technology: string | null;
    commercial: boolean;
    connection: {
      protocol: string;
      username?: string | null;
      password?: string | null;
      address: string | null;
      gateway: string | null;
    };
    tunnel: {
      id: string | null;
      ipv4: string | null;
      ipv6: string | null;
    };
  }) => AxiosPromise<CircuitExtended>;
  updateCircuit: (
    id: number,
    form: {
      campus_slug: string;
      // step 1
      site_slug: string | null;
      status: string;
      service_id: string;
      type: string;
      campus_interface_id: number | null;
      campus_interface_description: string | null;
      node_interface_id: number | null;
      node_interface_description: string | null;
      // step2
      campus_ct_front_ports: number[];
      campus_ct_patch_panel_repr: string | null;
      campus_ct_speed_up: number | null;
      campus_ct_speed_down: number | null;
      node_ct_front_ports: number[];
      node_ct_patch_panel_repr: string | null;
      node_ct_speed_up: number | null;
      node_ct_speed_down: number | null;
      // step3
      provider: string | null;
      technology: string | null;
      commercial: boolean;
      connection: {
        protocol: string;
        username?: string | null;
        password?: string | null;
        address: string | null;
        gateway: string | null;
      };
      tunnel: {
        id: string | null;
        ipv4: string | null;
        ipv6: string | null;
      };
    },
  ) => AxiosPromise<CircuitExtended>;
  // TODO: fix any
  deleteCircuit: (id: string | number) => AxiosPromise<any>;
  getPatchPanels: () => AxiosPromise<PaginatedResponse<NetboxPatchPanel>>;
  getFrontPorts: (
    patchPanelId: number,
  ) => AxiosPromise<PaginatedResponse<NetboxFrontPort>>;
  getCircuitSnapshots: (
    circuitId: number,
  ) => AxiosPromise<CircuitSnapshot[]>;
}

const circuitApi: CircuitApiInterface = {
  getCircuits(query = new Query()) {
    return axios.get(`${resources.default}/${query}`);
  },

  getCircuit(id) {
    return axios.get(`${resources.default}/${id}`);
  },

  getCircuitExtended(id) {
    return axios.get(`${resources.ce}/${id}`);
  },

  getCircuitsExtended(
    campusSlug = '',
    options = {
      pagination: null,
      _status: null,
    },
    search = '',
  ) {
    const query = new Query({});
    if (search) {
      query.addParam('search', search);
    }
    if (campusSlug) {
      query.addParam('campus__slug', campusSlug);
    }
    if (options._status != null) {
      query.addParam('status', options._status);
    }
    if (options.pagination) {
      query.addParam('size', options.pagination.itemsPerPage);
      query.addParam('page', options.pagination.page);
      let sortBy = options.pagination.sortBy[0];
      if (sortBy) {
        if (options.pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    return axios.get(`${resources.ce}/${query}`);
  },

  /**
   * Creates a new circuit based on the given data.
   *
   */
  createCircuit(form) {
    return axios.post(`${resources.default}/`, form);
  },

  /**
   * Updates existing circuit. Data can be partial.
   *
   */
  updateCircuit(id, form) {
    return axios.patch(`${resources.default}/${id}/`, form);
  },

  /**
   * Deletes a circuit with the given id.
   *
   * @param {number} id
   * @returns {AxiosPromise<any>}
   */
  deleteCircuit(id) {
    return axios.delete(`${resources.default}/${id}`);
  },
  getPatchPanels() {
    return axios.get(`${resources.default}/inv/`);
  },
  getFrontPorts(patchPanelId: number) {
    const query = new Query({ patch_panel_id: patchPanelId });
    return axios.get(`${resources.default}/inv/front-ports/${query}`);
  },
  getCircuitSnapshots(circuitId) {
    return axios.get(`${resources.default}/${circuitId}/snapshots/`);
  },
};

export default circuitApi;

export { CircuitApiInterface };
