import {
  Campus,
  CampusDevicesStatusesByFunctionSummit,
  CampusFull,
  CampusManagement,
  CampusSimple,
  Contact,
  Contacts,
  DataOptions,
  PaginatedResponse,
} from '@/api/interfaces';
import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { Query } from '../query';

const resources = {
  default: 'tenants/campuses',
  campusesData: 'tenants/campuses-data',
  cds: 'tenants/campus-devices-summit',
};

interface CampusApiInterface {
  searchCampuses(
    search: string,
    searchFields?: string[] | null,
  ): AxiosPromise<PaginatedResponse<Campus>>;
  getCampuses(
    pagination?: DataOptions | null,
    search?: string | null,
    searchFields?: string[] | null,
    query?: Query | null,
  ): AxiosPromise<PaginatedResponse<Campus>>;
  getCampus(slug: string): AxiosPromise<CampusSimple>;
  getCampusFull(slug: string): AxiosPromise<CampusFull>;
  getCampusExtended(slug: string): AxiosPromise<Campus>;
  getCampusDevicesStatusByFunctionSummit(
    campusSlug: string,
  ): AxiosPromise<CampusDevicesStatusesByFunctionSummit>;
  getContacts(slug: string): AxiosPromise<Contacts>;
  createCampus(
    name: string | null,
    slug: string | null,
    locationId: number | null,
    organizationId: number,
    assignWLC: boolean,
  ): AxiosPromise<Campus>;
  editCampus(
    slug: string, // current slug
    data: {
      name: string; // new name
      slug: string; // new slug
    },
  ): AxiosPromise<Campus>;
  addOrganization(
    slug: string,
    organizationId: number | string,
  ): AxiosPromise<any>;
  removeOrganization(
    slug: string,
    organizationId: number | string,
  ): AxiosPromise<any>;
  assignWLC(slug: string): AxiosPromise<any>;
  assignZoneGroups(
    campusSlug: string,
    zoneGroupSlugs: string[],
    autoRenumberVlans: boolean,
  ): AxiosPromise<any>;
  assignCampusManagementNetwork(
    campusSlug: string,
    managementNetworkId: number | null,
  ): AxiosPromise<{ detail: string; campus_management: CampusManagement }>;
}

const CampusApi: CampusApiInterface = {
  searchCampuses(search, searchFields = null) {
    if (searchFields == null) {
      searchFields = ['name', 'slug'];
    }
    const query = new Query({ search, search_fields: searchFields.join(',') });
    return axios.get(`${resources.default}/${query}`);
  },

  getCampuses(
    pagination = null,
    search = null,
    searchFields = null,
    query = null,
  ) {
    if (!query) {
      query = new Query({});
    }
    if (searchFields == null) {
      searchFields = ['name', 'slug'];
    }
    if (pagination !== null) {
      query.addParam('size', pagination.itemsPerPage);
      query.addParam('page', pagination.page);
      let sortBy = pagination.sortBy[0];
      if (sortBy) {
        if (pagination.sortDesc[0]) {
          sortBy = `-${sortBy}`;
        }
        query.addParam('sort_by', sortBy);
      }
    }
    if (search !== null) {
      query.addParam('search', search);
    }
    query.addParam('search_fields', searchFields.join(','));
    return axios.get(`${resources.default}/${query}`);
  },

  getCampus(slug) {
    return axios.get(`${resources.default}/${slug}/`);
  },

  /*
   * Note that this getCampusFull is not the same as a single object of getCampusesFull,
   * this one is way more extended one, usually used by ansible
   */
  getCampusFull(slug) {
    return axios.get(`${resources.default}/${slug}/full/`);
  },

  getCampusExtended(slug) {
    return axios.get(`${resources.campusesData}/${slug}/`);
  },

  getCampusDevicesStatusByFunctionSummit(campusSlug) {
    return axios.get(`${resources.cds}/${campusSlug}/`);
  },

  getContacts(slug) {
    const resp = axios.get(`${resources.default}/${slug}/contacts/`);
    return resp.then(
      (
        // data
        data: AxiosResponse<
          {
            organization: { portal_id: number; name: string };
            ripe: {
              person_id: number;
              first_name: string;
              last_name: string;
              contacts: {
                type: string;
                value: string;
                updated: number;
                created: number;
              }[];
            }[];
            procurators: {
              person_id: number;
              first_name: string;
              last_name: string;
              net_id: string;
              contacts: {
                type: string;
                value: string;
                updated: number;
                created: number;
              }[];
            }[];
            organization_procurators: {
              person_id: number;
              first_name: string;
              last_name: string;
              net_id: string;
              contacts: {
                type: string;
                value: string;
                updated: number;
                created: number;
              }[];
            }[];
            org_contacts: {
              type: string;
              value: string;
            }[];
            errors: string[];
          }[]
        >,
      ) => {
        // transform data to the following form:
        // {
        //   <personID1>: {
        //     name: <name>,
        //     organizations: {
        //       <portalID>: <orgName>,
        //       ...
        //     }
        //     sources: string[], // 'ripe' and 'procurator'
        //     contacts: [<type>, <value>, <updated>][],
        //   },
        //   <personID2>: {
        //     name: <name>,
        //     organizations: {
        //       <portalID>: <orgName>,
        //       ...
        //     }
        //     sources: string[], // 'ripe' and 'procurator'
        //     contacts: [<type>, <value>, <updated>][],
        //   },
        //   ...
        //   org_contacts: {
        //     <portalID>: {
        //       name: <orgName>,
        //       contacts: { type: <type>, value: <value> }[],
        //     }
        //   },
        // }

        const res: any = { org_contacts: {} };
        let errors: string[] = [];
        for (const contact of data.data) {
          errors = errors.concat(contact.errors);
          // get ripe contacts
          for (const person of contact.ripe) {
            const name = `${person.first_name} ${person.last_name}`;
            const personId = person.person_id;
            if (!Object.keys(res).includes(personId.toString())) {
              res[personId.toString()] = {
                name,
                contacts: [],
                organizations: [],
                sources: [],
              };
            }
            if (
              !res[personId].organizations
                .map((el: any) => el.portal_id)
                .includes(contact.organization.portal_id)
            ) {
              res[personId].organizations.push({
                portal_id: contact.organization.portal_id,
                name: contact.organization.name,
              });
            }
            if (
              person.contacts.length > 0 &&
              !res[personId].sources.includes('ripe')
            ) {
              res[personId].sources.push('ripe');
            }
            for (const c of person.contacts) {
              if (
                !res[personId].contacts.find(
                  (el: any) =>
                    el.type === c.type &&
                    el.value === c.value &&
                    el.updated === c.updated,
                )
              ) {
                res[personId].contacts.push({
                  type: c.type,
                  value: c.value,
                  updated: c.updated,
                });
              }
            }
          }
          // get procurator contacts
          for (const person of contact.procurators) {
            const name = `${person.first_name} ${person.last_name}`;
            const personId = person.person_id;
            if (!Object.keys(res).includes(personId.toString())) {
              res[personId] = {
                name,
                contacts: [],
                organizations: [],
                sources: [],
              };
            }
            if (
              !res[personId].organizations
                .map((el: any) => el.portal_id)
                .includes(contact.organization.portal_id)
            ) {
              res[personId].organizations.push({
                portal_id: contact.organization.portal_id,
                name: contact.organization.name,
              });
            }
            if (
              person.contacts.length > 0 &&
              !res[personId].sources.includes('procurator')
            ) {
              res[personId].sources.push('procurator');
            }
            for (const c of person.contacts) {
              if (
                !res[personId].contacts.find(
                  (el: any) =>
                    el.type === c.type &&
                    el.value === c.value &&
                    el.updated === c.updated,
                )
              ) {
                res[personId].contacts.push({
                  type: c.type,
                  value: c.value,
                  updated: c.updated,
                });
              }
            }
          }
          let portalId = contact.organization.portal_id;
          let orgName = contact.organization.name;
          res.org_contacts[portalId] = {
            name: orgName,
            contacts: contact.org_contacts,
          };
          // get organization procurator contacts
          for (const person of contact.organization_procurators) {
            const name = `${person.first_name} ${person.last_name}`;
            const personId = person.person_id;
            if (!Object.keys(res).includes(personId.toString())) {
              res[personId] = {
                name,
                contacts: [],
                organizations: [],
                sources: [],
              };
            }
            if (
              !res[personId].organizations
                .map((el: any) => el.portal_id)
                .includes(contact.organization.portal_id)
            ) {
              res[personId].organizations.push({
                portal_id: contact.organization.portal_id,
                name: contact.organization.name,
              });
            }
            if (
              person.contacts.length > 0 &&
              !res[personId].sources.includes('organization_procurator')
            ) {
              res[personId].sources.push('organization_procurator');
            }
            for (const c of person.contacts) {
              if (
                !res[personId].contacts.find(
                  (el: any) =>
                    el.type === c.type &&
                    el.value === c.value &&
                    el.updated === c.updated,
                )
              ) {
                res[personId].contacts.push({
                  type: c.type,
                  value: c.value,
                  updated: c.updated,
                });
              }
            }
          }
          portalId = contact.organization.portal_id;
          orgName = contact.organization.name;
          res.org_contacts[portalId] = {
            name: orgName,
            contacts: contact.org_contacts,
          };
        }
        // add organization data to Contact array
        const contacts: Contact[] = [];
        for (const portalId of Object.keys(res.org_contacts)) {
          const orgData: any = {
            organizations: [
              {
                name: res.org_contacts[portalId].name,
                portal_id: Number(portalId),
              },
            ],
            sources: ['portal_member'],
            name: '',
            contacts: [],
          };
          for (const contact of res.org_contacts[portalId].contacts) {
            orgData.contacts.push({
              type: contact.type,
              value: contact.value,
              updated: null,
            });
          }
          contacts.push(orgData);
        }
        // we've parsed org_contacts, remove it so that only personId properties are left
        delete res.org_contacts;
        // add person data to Contact array
        for (const personId of Object.keys(res)) {
          contacts.push(res[personId]);
        }
        (data as unknown as AxiosResponse<Contacts>).data = {
          contacts: contacts,
          errors: errors,
        } as Contacts;
        return data as unknown as AxiosResponse<Contacts>;
      },
    );
  },

  createCampus(name, slug, locationId, organizationId, assignWLC) {
    // take care of empty strings
    const transformedName = name ? name : null;
    const transformedSlug = slug ? slug : null;
    return axios.post(`${resources.default}/`, {
      name: transformedName,
      slug: transformedSlug,
      organization: organizationId,
      location: locationId,
      assign_wlc: assignWLC,
    });
  },

  editCampus(slug, data) {
    return axios.patch(`${resources.default}/${slug}/`, data);
  },

  addOrganization(slug, organizationId) {
    return axios.post(`${resources.default}/${slug}/add-organization/`, {
      organization_id: organizationId,
    });
  },

  removeOrganization(slug, organizationId) {
    return axios.delete(
      `${resources.default}/${slug}/remove-organization/${organizationId}/`,
    );
  },

  assignWLC(slug) {
    return axios.post(`${resources.default}/${slug}/assign-wlc/`, {});
  },

  assignZoneGroups(campusSlug, zoneGroupSlugs, autoRenumberVlans) {
    return axios.post(`${resources.default}/${campusSlug}/assign-zonegroups/`, {
      zonegroups: zoneGroupSlugs,
      auto_renumber_vlans: autoRenumberVlans,
    });
  },

  assignCampusManagementNetwork(campusSlug, managementNetworkId) {
    return axios.post(
      `${resources.default}/${campusSlug}/assign-campus-management-network/`,
      {
        management_network_id: managementNetworkId,
      },
    );
  },
};

export default CampusApi;
export { CampusApiInterface };
